import { Constants } from 'src/constants';
import { environment } from 'src/environments/environment';

export class LocaleSwitcher {
	private static locale: string = environment.language;

	public static setLocale(locale: string) {
		this.locale = locale;
	}

	public static getLocale() {
		return this.locale;
	}
}
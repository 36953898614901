import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'snakeToPascal'
})
export class SnakeToPascalPipe implements PipeTransform {
	transform(value: string): string {
		if (value) {
			// Lógica para converter snake_case para PascalCase
			return value
				.split('_')
				.map(
					(word) =>
						word.charAt(0).toUpperCase() + word.slice(1).toLocaleLowerCase()
				)
				.join(' ');
		}
		return value;
	}
}

import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, ViewChild } from '@angular/core';
import { CoreUtil } from '../../core-util';
import { ToastService } from '../../service/toast.service';
import { Constants } from 'src/constants';
import { Labels } from 'src/internationalization/labels/labels';
import { Icons } from 'src/icons';

@Component({
	selector: 'app-picklist',
	templateUrl: './picklist.component.html',
	styleUrls: ['./picklist.component.scss']
})
export class PicklistComponent implements OnInit {
	@ViewChild('hiddenInput', { static: false }) hiddenInput;

	@Input() title: string;
	@Input() idPropertyName: string;
	@Input() displayProperties: string[];
	@Input() arrayList: any[];
	@Input() activedList: any[];
	@Input() widths: number[];
	@Input() readonly = false;
	@Input() allowEdit = false;
	@Input() pipe: string;
	@Input() singleColumn = true;
	@Input() noSelectionMessage: string;
	@Input() validateCurrentDateMessage: string;
	@Input() set activedTitle(value: string | string[]) {
		if (Array.isArray(value)) {
			this._activedTitle = value;
		} else {
			this._activedTitle = [value];
		}
	}

	@Output() activedListChange = new EventEmitter();
	@Output() change = new EventEmitter();

	public _labels = Labels.getLabels();
	public _icons = Icons;
	public _arrayList;
	public _activedList;
	public _editMode: boolean[] = [];
	public _activedTitle: string[];

	private _constants = Constants.constant;

	private _inputEvent = new Event('input', {
		bubbles: true,
		cancelable: true,
	});

	constructor(private toastService: ToastService) { }

	ngOnInit() { }

	ngOnChanges(changes: SimpleChanges) {
		if (changes.arrayList && changes.arrayList.currentValue) {
			this.changeArrayList();
		}

		if (changes.activedList && changes.activedList.currentValue) {
			this.changeActivedList();
		}
	}

	changeArrayList() {
		this._arrayList = [];

		for (const item of this.arrayList) {
			this._arrayList.push({
				data: item, checked: false
			});
		}
	}

	changeActivedList() {
		setTimeout(() => {
			this._activedList = [];
			for (const item of this.activedList) {
				this._activedList.push({
					data: item, checked: false
				});
			}
		});
	}

	add() {
		if (!this.readonly) {
			let changed = false;

			for (const item of this._arrayList) {
				if (item.checked) {
					const aux = this.activedList.find(x => x[this.idPropertyName] == item.data[this.idPropertyName]);
					if (!aux) {
						if (item.data.validUntil) {
							item.data.validUntil = undefined;
						}

						this.activedList.push(item.data);

						changed = true;
						item.checked = false;
					}
				}
			}

			if (changed) {
				this.changeActivedList();
				this.activedListChange.emit(this.activedList);
				this.change.emit();
				this.triggerHiddenInput();
			} else if (this.noSelectionMessage) {
				this.toastService.show(this._constants.messageType.warning, this.noSelectionMessage);
			}
		}
	}

	remove(item) {
		if (!this.readonly) {
			if (item && item.data.validUntil) {
				item.data.validUntil = undefined;
			}

			const index = this.activedList.map(x => x[this.idPropertyName]).indexOf(item.data[this.idPropertyName]);
			if (index >= 0) {
				this.activedList.splice(index, 1);
				this.changeActivedList();
				this.activedListChange.emit(this.activedList);
				this.triggerHiddenInput();
			}

			this.change.emit();
		}
	}

	triggerHiddenInput() {
		this.hiddenInput.nativeElement.dispatchEvent(this._inputEvent);
	}

	splitProperty(obj: any, property: string) {
		return CoreUtil.getSplittedProperty(obj, property);
	}
}
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'stringLimit'
})
export class StringLimitPipe implements PipeTransform {
	transform(value: string, limit: number, dots = false): string {
		if (value && limit) {
			return value.toString().slice(0, limit) + (dots && value.length > limit ? '...' : '');
		}

		return null;
	}
}
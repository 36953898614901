import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'extense_and_negative_pipe'
})
export class extense_and_negative_number_pipe implements PipeTransform {
	transform(value: any): any {
		value = value.toString();
		if (value.substring(0,1) == '-' ) {
			const array = value.split('-');
			value =  '(' + array[1] + ')';
		}
		if (value.length > 12 && !value.includes('(') || value.includes('(') && value.length > 14) {
			value = '######';
		}
		return value;
	}
}
import { Pipe, PipeTransform } from '@angular/core';
import { Constants } from 'src/constants';
import { Labels } from 'src/internationalization/labels/labels';

@Pipe({
	name: 'domainStatus'
})
export class DomainStatusPipe implements PipeTransform {
	public _constants = Constants.constant;
	public _labels = Labels.getLabels();

	transform(value: string): any {
		return (value === this._constants.status.active || value === this._constants.status.active_long) ?
			this._labels.active :
			(value === this._constants.status.inactive || value === this._constants.status.inactive_long) ?
				this._labels.inactive :
				value;
	}
}
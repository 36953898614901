import {Component, Input } from '@angular/core';
import {Icons} from '../../../../icons';

@Component({
  selector: 'app-page-card',
  templateUrl: './page-card.component.html',
  styleUrls: ['./page-card.component.scss']
})
export class PageCardComponent {
	@Input() icon: string;
	@Input() title: string;
	@Input() breadcrumb: string;
	@Input() currentPage: string;
	public _icons = Icons;
}

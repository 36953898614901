export class Colors {
	public static readonly color = {
		light_gray: '#EBEBEB',
		red_error_background: '#FFDEDC',
		white: '#FFFFFF',
		colorGreen: '#0DD86F', // 'A' or Status: 'Active'
		colorGrey: '#BBBBBB', // 'I'
		colorYellow: '#F9CF45', // Globe or Status: 'Pending'
		colorBlue: '#94B6E8', // Status: 'Inactive'
		colorPurple: '#AF5BB5', // Status: 'Not Applied'
		colorBlack: '#000000',
		colorOrange: '#FB9D19',
		success: '#30AB22',
		warning: '#FFC320',
		info: '#57BCFF',
		error: '#FF4136',
		colorRed: '#FF0000',
	};
}

import {Component} from '@angular/core';
import {AbstractTable} from "../../abstract/abstract-table";

@Component({
	selector: 'app-card-title',
	templateUrl: './card-title.component.html',
	styleUrls: ['./card-title.component.scss']
})
export class CardTitleComponent extends AbstractTable {


	ngOnInit(): void {
	}

}

import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from 'src/app/shared/shared.module';
import {MainFormComponent} from './main-form/main-form.component';
import {CardInformationComponent} from './card-information/card-information.component';
import {DefaultInputComponent} from './default-input/default-input.component';
import {GridListComponent} from './grid-list/grid-list.component';
import {PipeModule} from '../pipe/pipe.module';
import {MultiselectComponent} from './multiselect/multiselect.component';
import {DirectiveModule} from '../directive/directive.module';
import {PicklistComponent} from './picklist/picklist.component';
import {CheckboxComponent} from './checkbox/checkbox.component';
import {DropdownListComponent} from './dropdown-list/dropdown-list.component';
import {PipeDisplayComponent} from './pipe-display/pipe-display.component';
import {MultiplePagesSelectionComponent} from './multiple-pages-selection/multiple-pages-selection.component';
import {MaskedInputComponent} from './masked-input/masked-input.component';
import {SelectFilterComponent} from './select-filter/select-filter.component';
import {PeriodComponent} from './period/period.component';
import {ChekboxListComponent} from './chekbox-list/chekbox-list.component';
import {ExpansionCardComponent} from './expansion-card/expansion-card.component';
import {RadioButtonListComponent} from './radio-button-list/radio-button-list.component';
import {
	RadioButtonListContentComponent
} from './radio-button-list/radio-button-list-content/radio-button-list-content.component';
import {DatepickerComponent} from './datepicker/datepicker.component';
import {TextListInputComponent} from './text-list-input/text-list-input.component';
import {TextListDisplayComponent} from './text-list-display/text-list-display.component';
import {DropdownBoxListComponent} from './dropdown-box-list/dropdown-box-list.component';
import {AppointmentBrandComponent} from './appointment-brand/appointment-brand.component';
import {AppointmentBrandListComponent} from './appointment-brand-list/appointment-brand-list.component';
import {AppointmentStatusComponent} from './appointment-status/appointment-status.component';
import {AppointmentStatusListComponent} from './appointment-status-list/appointment-status-list.component';
import {CircleTextComponent} from './circle-text/circle-text.component';
import {BookingListComponent} from './booking-list/booking-list.component';
import {CKEditorModule} from 'ckeditor4-angular';
import {FilterListComponent} from './filter-list/filter-list.component';
import {PopoverContainersStatusComponent} from './popover-containers-status/popover-containers-status.component';
import {DragAndDropComponent} from './drag-and-drop/drag-and-drop.component';
import {StatusBadgeComponent} from './status-badge/status-badge.component';
import {DropdownGroupListComponent} from './dropdown-group-list/dropdown-group-list.component';
import {ButtonLoadComponent} from './button-load/button-load.component';
import {ExpansionCardFilterComponent} from './expansion-card-filter/expansion-card-filter.component';
import {PaginationComponent} from './pagination/pagination.component';
import {HistoryComponent} from './history/history.component';
import {StatusWithLabelComponent} from './status-with-label/status-with-label.component';
import {LanguageSelectorComponent} from './language-selector/language-selector.component';
import {CheckToolsFormComponent} from './check-tools/check-tools-form.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {InputCountComponent} from './input-count/input-count.component';
import {
	PortTerminalsDropdownListComponent
} from './port-terminals-dropdown-list/port-terminals-dropdown-list.component';
import {ReportViewComponent} from './report-view/report-view.component';
import {CounterComponent} from './counter/counter.component';
import {NgxMaskModule} from 'ngx-mask';
import {PageCardComponent} from './page-card/page-card.component';
import {InputMultipleFilesComponent} from './input-multiple-files/input-multiple-files.component';
import {DataTableComponent} from './data-table/data-table.component';
import {SearchInputComponent} from './search-input/search-input.component';
import {DropdownItensComponent} from './dropdown-itens/dropdown-itens.component';
import {DropdownListItensComponent} from './dropdown-list-itens/dropdown-list-itens.component';
import {DropdownSimplerListComponent} from './dropdown-simpler-list/dropdown-simpler-list.component';
import {LoaderComponent} from './loader/loader.component';
import {ForcedLoadingComponent} from './forced-loading/forced-loading.component';
import {ComplexSelectComponent} from './complex-select/complex-select.component';
import {SimpleSelectComponent} from './simple-select/simple-select.component';
import {
	StatusWithLabelForSchedulePortListComponent
} from './status-with-label-for-schedule-port-list/status-with-label-for-schedule-port-list.component';
import {
	MulticolumnDropdownComponent
} from 'src/app/resource/page/agreement/components/multicolumn-dropdown/multicolumn-dropdown.component';
import {ListItemsDropdownModule} from '../form-components/list-itens-dropdown/list-items-dropdown.module';
import {DownloadFileComponent} from './download-file/download-file.component';
import {StatusBulletComponent} from './status-bullet/status-bullet.component';
import {StatusIconComponent} from './status-icon/status-icon.component';
import {TableDropdownNumberComponent} from './table-dropdown-number/table-dropdown-number.component';
import {BlockItemsComponent} from './block-items/block-items.component';
import {VesselUtilizationComponent} from './report-view/vessel-utilization/vessel-utilization.component';
import {
	BookingXAllocationPerTeamComponent
} from './report-view/booking-x-allocation-per-team/booking-x-allocation-per-team.component';
import {CollapsableSectionComponent} from './collapsable-section/collapsable-section.component';
import {LoadingXDischargeComponent} from './report-view/loading-x-discharge/loading-x-discharge.component';
import {NgxPrintModule} from 'ngx-print';
import {DropdownListReactiveComponent} from './dropdown-list-reactive/dropdown-list-reactive.component';
import {CheckboxReactiveComponent} from './checkbox-reactive/checkbox-reactive.component';
import {DownloadUploadCsvComponent} from './download-upload-csv/download-upload-csv.component';
import {UploadFileReactiveModule} from '../form-components/upload-file-reactive/upload-file-reactive.module';
import {OperationTypeComponent} from './operation-type/operation-type.component';
import {CardTitleComponent} from './card-title/card-title.component';
import {MatProgressBarModule} from "@angular/material/progress-bar";
import { ButtonIconModule } from './button-icon/button-icon.module';
import { GridFilterTabButtonModule } from './grid-filter-tab-button/grid-filter-tab-button.module';
import { CustomModalModule } from './custom-modal/custom-modal.module';


@NgModule({
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	declarations: [
		MainFormComponent,
		CardInformationComponent,
		DefaultInputComponent,
		GridListComponent,
		MultiselectComponent,
		PicklistComponent,
		CheckboxComponent,
		DropdownListComponent,
		PortTerminalsDropdownListComponent,
		PipeDisplayComponent,
		MultiplePagesSelectionComponent,
		MaskedInputComponent,
		SelectFilterComponent,
		PeriodComponent,
		ChekboxListComponent,
		ExpansionCardComponent,
		RadioButtonListComponent,
		RadioButtonListContentComponent,
		DatepickerComponent,
		TextListInputComponent,
		TextListDisplayComponent,
		DropdownBoxListComponent,
		AppointmentBrandComponent,
		AppointmentBrandListComponent,
		AppointmentStatusComponent,
		AppointmentStatusListComponent,
		CircleTextComponent,
		BookingListComponent,
		DownloadFileComponent,
		FilterListComponent,
		PopoverContainersStatusComponent,
		DragAndDropComponent,
		StatusBadgeComponent,
		DropdownGroupListComponent,
		ButtonLoadComponent,
		ExpansionCardFilterComponent,
		PaginationComponent,
		HistoryComponent,
		StatusWithLabelComponent,
		LanguageSelectorComponent,
		CheckToolsFormComponent,
		ReportViewComponent,
		InputCountComponent,
		CounterComponent,
		PageCardComponent,
		InputMultipleFilesComponent,
		DataTableComponent,
		SearchInputComponent,
		DropdownItensComponent,
		LoaderComponent,
		ForcedLoadingComponent,
		ComplexSelectComponent,
		SimpleSelectComponent,
		DropdownListItensComponent,
		DropdownSimplerListComponent,
		ComplexSelectComponent,
		StatusWithLabelForSchedulePortListComponent,
		MulticolumnDropdownComponent,
		StatusBulletComponent,
		StatusIconComponent,
		TableDropdownNumberComponent,
		BlockItemsComponent,
		VesselUtilizationComponent,
		BookingXAllocationPerTeamComponent,
		CollapsableSectionComponent,
		LoadingXDischargeComponent,
		DropdownListReactiveComponent,
		CheckboxReactiveComponent,
		DownloadUploadCsvComponent,
		OperationTypeComponent,
		CardTitleComponent,
	],
	imports: [
		ReactiveFormsModule,
		FormsModule,
		CKEditorModule,
		CommonModule,
		SharedModule,
		PipeModule,
		DirectiveModule,
		NgxMaskModule.forRoot(),
		ListItemsDropdownModule,
		NgxPrintModule,
		UploadFileReactiveModule,
		MatProgressBarModule,
		ButtonIconModule,
		GridFilterTabButtonModule,
		CustomModalModule,
	],

	exports: [
		LoadingXDischargeComponent,
		MainFormComponent,
		CardInformationComponent,
		StatusIconComponent,
		DataTableComponent,
		DefaultInputComponent,
		GridListComponent,
		StatusBulletComponent,
		MultiselectComponent,
		PicklistComponent,
		CheckboxComponent,
		DropdownListComponent,
		PortTerminalsDropdownListComponent,
		PipeDisplayComponent,
		MultiplePagesSelectionComponent,
		MaskedInputComponent,
		DropdownItensComponent,
		SelectFilterComponent,
		PeriodComponent,
		ChekboxListComponent,
		ExpansionCardComponent,
		RadioButtonListComponent,
		DatepickerComponent,
		TextListInputComponent,
		TextListDisplayComponent,
		DropdownBoxListComponent,
		AppointmentBrandComponent,
		AppointmentBrandListComponent,
		AppointmentStatusComponent,
		DownloadFileComponent,
		AppointmentStatusListComponent,
		CircleTextComponent,
		BookingListComponent,
		FilterListComponent,
		PopoverContainersStatusComponent,
		DragAndDropComponent,
		StatusBadgeComponent,
		DropdownGroupListComponent,
		ExpansionCardFilterComponent,
		ButtonLoadComponent,
		ExpansionCardFilterComponent,
		HistoryComponent,
		StatusWithLabelComponent,
		LanguageSelectorComponent,
		CheckToolsFormComponent,
		InputCountComponent,
		ReportViewComponent,
		CounterComponent,
		PageCardComponent,
		SearchInputComponent,
		InputMultipleFilesComponent,
		LoaderComponent,
		ForcedLoadingComponent,
		ComplexSelectComponent,
		PaginationComponent,
		DropdownListItensComponent,
		DropdownSimplerListComponent,
		SimpleSelectComponent,
		ListItemsDropdownModule,
		StatusWithLabelForSchedulePortListComponent,
		MulticolumnDropdownComponent,
		TableDropdownNumberComponent,
		BlockItemsComponent,
		CollapsableSectionComponent,
		VesselUtilizationComponent,
		BookingXAllocationPerTeamComponent,
		CollapsableSectionComponent,
		LoadingXDischargeComponent,
		DownloadUploadCsvComponent,
		DropdownListReactiveComponent,
		OperationTypeComponent,
		CardTitleComponent,
	],
	entryComponents: [
		RadioButtonListContentComponent,
		CheckToolsFormComponent,
	]
})
export class ComponentModule {
}
import { ObjectFilter } from './../../dto/object-filter.dto';
import { AppointmentStatusEnum, AppointmentService } from './../../../resource/page/appointment/appointment.sevice';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'app-filter-list',
	templateUrl: './filter-list.component.html',
	styleUrls: ['./filter-list.component.scss']
})
export class FilterListComponent implements OnInit, ILanguageConfig {
	@Input() filterName: string;
	@Input() filterArray: { key: string, data: ObjectFilter[] }[] = [];
	@Input() color: string;
	@Input() appointmentStatusEnum: AppointmentStatusEnum;
	@Output() applyFilterEvent = new EventEmitter();
	@Output() onRemoveFilterItem = new EventEmitter();

	_languageData;

	constructor() { }

	ngOnInit() {
		this.getLanguageFile();
	}

	getLanguageFile() {
		this._languageData = require('./language.json').en;
	}

	getFilterName() {
		if (this.filterName) {
			return this.filterName;
		}

		return this.appointmentStatusEnum;
	}

	getStatusColor() {
		if (this.color) {
			return this.color;
		}

		return AppointmentService.getAppointmentStatusColor(this.appointmentStatusEnum);
	}

	canApplyFilter() {
		return this.filterArray.length;
	}

	applyFilter() {
		this.applyFilterEvent.emit(this.filterArray);
	}

	clearFilter() {
		if (this.filterArray && this.filterArray.length) {
			while (this.filterArray.length) {
				this.filterArray.pop();
			}
		} else {
			this.filterArray = [];
		}
	}

	public insertUpdateFilter(key: string, data: ObjectFilter[]) {
		if (!key) {
			return;
		}

		if (Array.isArray(data)) {
			data = data.filter(el => (el && el.id != -1));
		}

		let found = false;
		// for (let i = 0; i < this.filterArray.length; i++) {
		// 	if (this.filterArray[i].key === key) {
		// 		this.filterArray[i].data = data;
		for (const filter of this.filterArray) {
			if (filter.key === key) {
				filter.data = data;
				found = true;
			}
		}

		if (!found && Array.isArray(data)) {
			this.filterArray.push({key, data});
		}

		this.removeEmptyData();
	}

	removeFilterItem(key: string, data: ObjectFilter) {
		if (!this.filterArray || !key || !data) {
			return;
		}

		// for (let i = 0; i < this.filterArray.length; i++) {
		// 	if (this.filterArray[i].key === key) {
		// 		if (Array.isArray(this.filterArray[i].data)) {
		// 			this.filterArray[i].data = this.filterArray[i].data.filter(el => (el && el.id != data.id));
		for (const filter of this.filterArray) {
			if (filter.key === key) {
				if (Array.isArray(filter.data)) {
					filter.data = filter.data.filter(el => (el && el.id != data.id));
					this.onRemoveFilterItem.emit({key, removed: data, array: filter.data});
					this.removeEmptyData();
					break;
				}
			}
		}
	}

	removeEmptyData() {
		if (Array.isArray(this.filterArray)) {
			this.filterArray = this.filterArray.filter(el => (Array.isArray(el.data) && el.data.length > 0));
		}
	}
}
import { environment } from "src/environments/environment"

const getApiKeyByEnvironment = (ambient) => {
	switch (ambient) {
		case 'cdt':
			return {
				name: 'anl-intercab-IntercabWebApp-cdt',
				key: "BvEBy9eoiPgHF1nAWwgyc18Xcg4DY87+IXDaKiZqWYQVK8+zDTeKZu4QeIlh5g=="}
		case 'qa':
			return {
				name: 'anl-intercab-IntercabWebApp-qa',
			 	key: "BvEBy9eoiP0HF16dWw9gJQxcyMQpSaZ5n1ZuNIDKvNoSO6kRbQn8YbtzfWHJUg=="}
		case 'preprod':
		case 'uat':
		case 'eyt':
			return {
				name: 'anl-intercab-IntercabWebApp-preprod',
				key: "BvEBy9eoiP0JGg/DWFhuIuXJZ45FRbQvgNy71TlNBQPdlNCjx/Si/dDIyTEAkw=="}
		case 'prod':
			return {
				name: 'anl-intercab-IntercabWebApp-prod',
				key: "BvEBy9eoiPwCGV6RCVxhJDJZAnEJa2+UV9Ap3edEBS2IfQ5l3EB3rm3gLVrWxw=="}
	}
}

const RUM = window['RUM'];
		export const executeRum = () => {
            return RUM.RumInit({
				app: {
					name: getApiKeyByEnvironment(environment.ambient).name,
					version: "1.0.0",
				},
				traceLog: true,
				debug: true,
				useSendBeacon: true,
				useAsync: true,
				apiKey: getApiKeyByEnvironment(environment.ambient).key,
			});
        }
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { LocaleUtils } from '../../model/enums/LocaleUtils';

@Component({
  selector: 'app-input-currency',
  templateUrl: './input-currency.component.html',
  styleUrls: ['./input-currency.component.scss']
})
export class InputCurrencyComponent implements OnInit {
  @Input()
  formController: FormControl;
  
  @Input()
  value: string = '';

  @Input()
  decimalPlaces: number = 2;

  @Input()
  maxLength: number = null;

  @Input()
  disabled: boolean = false;

  @Input()
  prefix: string = "";

  @Input()
  sufix: string = "";

  @Input()
  locale: LocaleUtils = LocaleUtils.EN_US;

  @Output()
  valueChange = new EventEmitter<string>();

  constructor() { }

  ngOnInit() {
    if(this.formController){
      this.changeValue(this.formController.value);
      this.disabled = this.formController.disabled;
    }else{
      this.changeValue(this.value);
    }
  }

  changeValue(value: string): void {
    if (this.existValue(value)) {
      this.value = this.convertValue(value);
    }
  }

  keyUp(value: string): void {
    if (this.existValue(value)) {
      value = this.convertValue(value);
      this.value = value;
    }
  }

  convertValue(value: string): string {
    value = this.validateMaxLength(value);
    value = this.formatByLocale(value);
    this.valueChange.emit(value);
    if(this.formController){
      this.formController.setValue(value);
    }
    
    return value;
  }

  formatByLocale(value: string): string {
    let decimalPlace = new RegExp(`([0-9]{${this.decimalPlaces}})$`, 'g');
    let firstPlace = new RegExp(`(.{${(this.decimalPlaces + 4)}})$`, 'g');
    let secPlace = new RegExp(`(.{${(this.decimalPlaces + 8)}})$`, 'g');
    let thirdPlace = new RegExp(`(.{${(this.decimalPlaces + 12)}})$`, 'g');
    let fthPlace = new RegExp(`(.{${(this.decimalPlaces + 16)}})$`, 'g');
    let fithPlace = new RegExp(`(.{${(this.decimalPlaces + 20)}})$`, 'g');
    let nonDecimalSymbol = "";
    let decimalSymbol = "";

    switch (this.locale) {
      case LocaleUtils.PT_BR:
        decimalSymbol = ",";
        nonDecimalSymbol = ".";
      default:
        decimalSymbol = ".";
        nonDecimalSymbol = ",";
    }

    value = value.toString()
      .replace(/\D/g, "")
      .replace(/\.\,/g, "")
      .replace(decimalPlace, `${decimalSymbol}$1`)
      .replace(firstPlace, `${nonDecimalSymbol}$1`)
      .replace(secPlace, `${nonDecimalSymbol}$1`)
      .replace(thirdPlace, `${nonDecimalSymbol}$1`)
      .replace(fthPlace, `${nonDecimalSymbol}$1`)
      .replace(fithPlace, `${nonDecimalSymbol}$1`);

    if(this.maxLength && value.length >= this.maxLength){
      if(value[0] == "," || value[0] == "."){
        value = value.substring(1, value.length);
      }
    }

    value = this.addSufixAndPrefix(value);
    return value;
  }

  addSufixAndPrefix(value: string): string{
    return `${this.sufix} ${value} ${this.prefix}`.trim();
  }

  validateMaxLength(value: string): string {
    if (this.maxLength) {
      value = value.substring(0, this.maxLength);
    }

    return value;
  }

  existValue(value: string): boolean {
    if (value) {
      return true;
    }

    return false;
  }
}

import { DragDropModule } from '@angular/cdk/drag-drop';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatBadgeModule, MatDatepickerModule, MatDividerModule, MatExpansionModule, MatMenuModule, MatProgressSpinnerModule, MatSnackBarModule, MatTableModule, MatTooltipModule } from '@angular/material';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { InputCurrencyComponent } from './components/input-currency/input-currency.component';
import { InputMaskComponent } from './components/input-mask/input-mask.component';
import { TeusAndTonsComponent } from './components/teus-and-tons/teus-and-tons.component';
import { OnlyNumberDirective } from './directives/only-number';

//primeNG
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { SidebarModule } from 'primeng/sidebar';
import { BallComponent } from './components/ball/ball.component';
import { TruncateTooltipDirective } from './directives/TruncateTooltip/truncate-tooltip.directive';
import {TruncateService} from "./directives/TruncateTooltip/truncate-tooltip.service";
import { RemoveSpaceDirective } from './directives/remove-space';


@NgModule({
	declarations: [
		InputCurrencyComponent,
		InputMaskComponent,
		TeusAndTonsComponent,
		BallComponent,

		//Directive
		OnlyNumberDirective,
   		TruncateTooltipDirective,
		RemoveSpaceDirective
	],
	imports: [
		CommonModule,
		RouterModule,
		FormsModule,
		MatInputModule,
		MatFormFieldModule,
		NgMultiSelectDropDownModule,
		MatTabsModule,
		MatSelectModule,
		MatCheckboxModule,
		MatIconModule,
		MatDialogModule,
		MatSlideToggleModule,
		MatRadioModule,
		MatTableModule,
		MatSnackBarModule,
		MatBadgeModule,
		MatExpansionModule,
		OverlayModule,
		MatDatepickerModule,
		MatTooltipModule,
		MatMenuModule,
		MatDividerModule,
		DragDropModule,
		NgbModule,
		MatProgressSpinnerModule,

		//PrimeNG
		MultiSelectModule,
		DropdownModule,
		SidebarModule,
		DialogModule,
	],
	exports: [
		RouterModule,
		MatInputModule,
		MatFormFieldModule,
		NgMultiSelectDropDownModule,
		MatProgressSpinnerModule,
		FormsModule,
		MatTabsModule,
		MatSelectModule,
		MatCheckboxModule,
		MatIconModule,
		MatDialogModule,
		MatSlideToggleModule,
		MatRadioModule,
		MatTableModule,
		MatSnackBarModule,
		MatExpansionModule,
		OverlayModule,
		MatDatepickerModule,
		MatTooltipModule,
		MatMenuModule,
		MatDividerModule,
		DragDropModule,
		NgbModule,

		//Components
		InputCurrencyComponent,
		InputMaskComponent,
		TeusAndTonsComponent,
		BallComponent,

		//primeNG
		MultiSelectModule,
		DropdownModule,
		SidebarModule,
		DialogModule,

		//Directive
		OnlyNumberDirective,
		TruncateTooltipDirective,
		RemoveSpaceDirective
	],
	providers: [TruncateService]
})
export class SharedModule { }
import { Directive, forwardRef, ElementRef, HostListener, Input } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';
import { Colors } from 'src/colors';
import { CoreUtil } from '../core-util';

@Directive({
	selector: '[appEmailValidator]',
	providers: [
			{ provide: NG_VALIDATORS, useExisting: forwardRef(() => EmailValidatorDirective), multi: true }
	]
})
export class EmailValidatorDirective implements Validator {
	private control: AbstractControl;

	@Input() autosetBackgroundOnError = true;

	constructor(private element: ElementRef) { }

	validate(c: AbstractControl): { [key: string]: any } {
		this.control = c;
		this.element.nativeElement.parentElement.style.backgroundColor = '';

		return null;
	}

	@HostListener( 'blur', ['$event'] )blur( $event: any ) {
		if (this.control && this.control.value) {
			let emailValid = false;
			const value = this.control.value.toString();
			const emailSplit = value.split('@');

			if (emailSplit.length > 1) {
				emailValid = emailSplit[1].split('.').filter(x => CoreUtil.isNotEmpty(x)).length > 1;
			}

			if (!emailValid) {
				this.element.nativeElement.parentElement.style.backgroundColor = this.autosetBackgroundOnError
					? Colors.color.red_error_background
					: '';
				this.control.setErrors({email: false});

				return;
			}
		}

		this.element.nativeElement.parentElement.style.backgroundColor = '';
		this.control.setErrors(null);

		return;
	}
}
import { Component, OnInit, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { MatSlideToggle } from '@angular/material/slide-toggle';

@Component({
	selector: 'app-appointment-status',
	templateUrl: './appointment-status.component.html',
	styleUrls: ['./appointment-status.component.scss']
})
export class AppointmentStatusComponent implements OnInit {
	@ViewChild(MatSlideToggle, {static: false}) toggle;

	@Input() index: number;
	@Input() color: string;
	@Input() status: string;
	@Input() count: number;
	@Input() disabled: boolean;
	@Input() checked: boolean;

	@Output() switchChange = new EventEmitter();

	constructor() { }

	ngOnInit() { }

	onSwitchChange() {
		this.switchChange.emit({index: this.index, checked: this.toggle.checked});
	}
}
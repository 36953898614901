export const getTooltipUtil = ((disabled: boolean, value: any, displayProperty: string): string => {
	if (!disabled) {
		return '';
	}
	if (!value) {
		return '';
	}
	if (value.length < 2) {
		return '';
	}
	if (Array.isArray(value)) {
		const descriptionList: string[] = value.map(values => values[displayProperty]);
		if (descriptionList[0] === 'Select All') {
			descriptionList.shift();
		}
		return descriptionList.join(', ');
	}
	return '';
});

export const getTooltipInLongText = (disabled: boolean, value: any, displayProperty: string) => {
	if(disabled) {
		return '';
	}

	if(!value) {
		return '';
	}

	if(Array.isArray(value)) {
		if(value.length && value[0][displayProperty] && value[0][displayProperty].length > 20) {
			return value[0][displayProperty];
		}
	}else {
		if(value[displayProperty].length > 20) {
			return value[displayProperty];
		}
	}
}

export function getTooltipByLengthLimit(rotationName: string, length: number): string {
	return rotationName && rotationName.length > length ? rotationName : '';
}

export function splitByLengthLimit(value: string, length: number): string {
	return value && value.length > length ? value.slice(0, length) + '...' : value;
}







import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GridFilterTabButtonComponent } from './grid-filter-tab-button.component';

@NgModule({
  declarations: [
    GridFilterTabButtonComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    GridFilterTabButtonComponent
  ],
})
export class GridFilterTabButtonModule { }
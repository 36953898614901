import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material';
import { Constants } from 'src/constants';
import { Labels } from 'src/internationalization/labels/labels';
import { Icons } from 'src/icons';

@Component({
	selector: 'app-toast',
	templateUrl: './toast.component.html',
	styleUrls: ['./toast.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class ToastComponent implements OnInit {
	public _labels = Labels.getLabels();
	public _constants = Constants.constant;
	public _icons = Icons;

	constructor(@Inject(MAT_SNACK_BAR_DATA) public data, private _snackRef: MatSnackBarRef<ToastComponent>) { }

	ngOnInit() { }

	close() {
		this._snackRef.dismiss();
	}
}
import { Component, OnInit, Inject, ViewChild, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { AbstractForm } from 'src/app/core/abstract/abstract-form';
import { DeactivateService } from 'src/app/core/service/deactivate.service';
import { EButtonDirective } from 'src/app/resource/enums/e-button-directive.enum';
import { Icons } from 'src/icons';
import { CheckTools } from '../../checkTools';
import * as XLSX from 'xlsx';

@Component({
	selector: 'app-check-tools-form',
	templateUrl: './check-tools-form.component.html',
	styleUrls: ['./check-tools-form.component.scss']
})
export class CheckToolsFormComponent extends AbstractForm {
	public _imgSrcSpinner = Icons.getIconPath(this._icons.loading_blue);
	public _isLoading = false;
	public fileNameLabels = 'Select file';
	public fileNameMessages = 'Select file';
	public _eButtonDirective = EButtonDirective;
	public loadingFinished: boolean = false;
	public checkLabels: boolean = false;
	public checkMessages: boolean = false;
	public exportLabels: boolean = false;
	public exportMessages: boolean = false;
	public importLabels: boolean = false;
	public importMessages: boolean = false;
	public checkDateUtil: boolean = false;

	private xlsDataArrayLabels: any[];
	private xlsDataArrayMessages: any[];

	constructor(@Inject(MAT_DIALOG_DATA) public data: any,
			public dialogRef: MatDialogRef<any>,
			public deactivateService: DeactivateService) {
		super();
	}

	ngAfterViewInit() {
		this.loadingFinished = true;
	}

	execute() {
		this._isLoading = true;

		if (this.checkLabels) CheckTools.checkLabels();
		if (this.checkMessages) CheckTools.checkMessages();
		if (this.exportLabels) CheckTools.exportLabels();
		if (this.exportMessages) CheckTools.exportMessages();
		if (this.importLabels) CheckTools.importLabels(this.xlsDataArrayLabels);
		if (this.importMessages) CheckTools.importMessages(this.xlsDataArrayMessages);
		if (this.checkDateUtil) CheckTools.checkDateUtil();

		this._isLoading = false;
	}

	xlsInputChangeLabels(fileInputEvent: any) {
		this.fileNameLabels = fileInputEvent.target.files[0].name;

		this.xlsInputChange(fileInputEvent, 'labels');
	}

	xlsInputChangeMessages(fileInputEvent: any) {
		this.fileNameMessages = fileInputEvent.target.files[0].name;

		this.xlsInputChange(fileInputEvent, 'messages');
	}

	private xlsInputChange(fileInputEvent: any, type) {
		let arrayBuffer;
		const file = fileInputEvent.target.files[0];
		const fileReader = new FileReader();

		fileReader.onload = (e) => {
			arrayBuffer = fileReader.result;
			const data = new Uint8Array(arrayBuffer);
			const arr = new Array();

			for (let i = 0; i < data.length; ++i) arr[i] = String.fromCharCode(data[i]);

			const bstr = arr.join('');
			const workbook = XLSX.read(bstr, {type: 'binary'});
			const worksheet = workbook.Sheets[workbook.SheetNames[0]];

			if (type == 'labels') {
				this.xlsDataArrayLabels = XLSX.utils.sheet_to_json(worksheet,{raw: true});
			} else
			if (type == 'messages') {
				this.xlsDataArrayMessages = XLSX.utils.sheet_to_json(worksheet,{raw: true});
			}
		};

		fileReader.readAsArrayBuffer(file);
	}

	close() {
		this.dialogRef.close({ update: true });
	}
}
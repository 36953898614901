import { Injectable, OnDestroy } from '@angular/core';
import { ToastService } from 'src/app/core/service/toast.service';
import { MsalService } from '@azure/msal-angular';
import { Logger, CryptoUtils } from 'msal';
import { Subscription } from 'rxjs';
import { Constants } from 'src/constants';
import { Messages } from 'src/internationalization/messages/messages';
import { AccessService } from './access.service';

@Injectable({
	providedIn: 'root'
})
export class LoginService implements OnDestroy {
	private _constants = Constants.constant;
	private _messages = Messages.getMessages();

	private _subscriptionList: Subscription[] = [];

	constructor(
		private authService: MsalService,
		private toastService: ToastService,
		private accessService: AccessService
	) {
		this.authService.setLogger(
			new Logger((logLevel, message, piiEnabled) => {}, {
				correlationId: CryptoUtils.createNewGuid(),
				piiLoggingEnabled: false
			})
		);
	}

	public login = () => this.accessService.loginPopup(true);

	public logout = () => this.accessService.logout();

	public get user() {
		return this.accessService.user;
	}

	public async checkChangeOfProfile() {
		const token = this.accessService.token;
		console.log(`ls:58 token `, token);
		const previousProfile = this.accessService.profileName;
		console.log(`ls:58 previousProfile `, previousProfile);
		try {
			const newUser = (await this.accessService.authenticate(token, false)) as unknown as { profileName: string } | undefined;
			console.log(`ls:66 newUser `, newUser);
			if (newUser.profileName !== previousProfile) {
				const message = this._messages.profile_changed;
				this.toastService.show(this._constants.messageType.error, message);
			}
		} catch (e) {
			console.warn(e);
		}
	}

	ngOnDestroy() {
		this._subscriptionList.forEach(subscription => subscription.unsubscribe());
	}
}

import {Component, EventEmitter, Input, Output, SimpleChanges, ViewChild} from '@angular/core';
import { FormControl } from '@angular/forms';
import {Icons} from '../../../../icons';
import {DropdownListComponent} from '../dropdown-list/dropdown-list.component';


@Component({
  selector: 'app-dropdown-list-itens',
  templateUrl: './dropdown-list-itens.component.html',
  styleUrls: ['./dropdown-list-itens.component.scss']
})
export class DropdownListItensComponent  {
	@Input() value: any;
	@Input() idProperty = 'id';
	@Input() displayProperty = 'description';
	@Input() auxDisplayProperty: string;
	@Input() placeholder: string;
	@Input() pipe: string;
	@Input() arrayData: any[] = [];
	@Input() microService: string;
	@Input() endPoint: string;
	@Input() showPlaceholderAsDisplay = false;
	@Input() queryParams: string;
	@Input() disabled = false;
	@Input() allSelectionMode = false;
	@Input() allSelected = false;
	@Input() showAllSelected = true;
	@Input() overlayAbsolute = false;
	@Input() multiple: boolean = true;
	@Input() customSearchText: string = '';
	@Input() dataTransformer: any[];
	@Input() hasSearch = true;
	@Input() callSearchOnClose = true;
	@Input() inputSearchLength: string;

	@Output() valueChange = new EventEmitter();
	@Output() arrayDataChange = new EventEmitter();
	@Output() allSelectedChange = new EventEmitter();
	@Output() searchTextChange = new EventEmitter();
	@Output() isDropdownOpen = new EventEmitter();

	@ViewChild('dropdownListComponent', { static: false }) dropdownListComponent: DropdownListComponent;
	icons = Icons.icon;

	valueChangeMethod(event) {
  	this.valueChange.emit(event);
		this.value = (event);
	}

	arrayDataChangeMethod(event) {
		this.arrayDataChange.emit(event);
	}

	allSelectedChangeMethod(event) {
		this.allSelectedChange.emit(event);
	}

	searchTextChangeMethod(event) {
		this.searchTextChange.emit(event);
	}

	isDropdownOpenMethod(event) {
		this.isDropdownOpen.emit(event);
	}

	deleteItem(event) {
		const { i: index } = event;
  	this.value.splice(index, 1);
  	this.dropdownListComponent._selectAll = false;
  	this.dropdownListComponent.change(this.value);
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes) {

			if (changes.value && changes.value.previousValue != changes.value.currentValue) {
				this.value = changes.value.currentValue;
			}
		}
	}



}

import { Component, Input, OnInit } from '@angular/core';
import { Icons } from 'src/icons';
import { Labels } from 'src/internationalization/labels/labels';

@Component({
	selector: 'app-expansion-card-filter',
	templateUrl: './expansion-card-filter.component.html',
	styleUrls: ['./expansion-card-filter.component.scss']
})
export class ExpansionCardFilterComponent implements OnInit {
	public _labels = Labels.getLabels();
	public _icons = Icons;

	@Input() title: string;
	@Input() filterName: string;
	@Input() icon: string;
	@Input() disabled = false;

	constructor() { }

	ngOnInit() { }
}

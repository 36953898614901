import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ThemePalette, ProgressSpinnerMode } from '@angular/material';
import { Subject } from 'rxjs';
import { LoadService } from '../../service/load.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  @Input()
  public color: string = 'blue';

  @Input()
  public mode: ProgressSpinnerMode = 'indeterminate';
  
  @Input()
  public size = 100;
  
  @Input()
  public showLocal: boolean = false; 

  public loading$: Subject<boolean>;
  public loading: Boolean;

  constructor(private _loaderService: LoadService) {}

  ngOnInit(): void {
    this.loading$ = this._loaderService._loading$;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.showLocal){
      if(changes.showLocal.currentValue != changes.showLocal.previousValue){
        this._loaderService.setLocalLoader(this.showLocal);
      }
    }
  }
}

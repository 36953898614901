import { Component, OnInit, Input, Output, EventEmitter, ViewChild, SimpleChanges, OnDestroy, forwardRef } from '@angular/core';
import { CoreUtil } from '../../core-util';
import { ObjectFilterBaseService } from '../../service/object-filter-base.service';
import { Labels } from '../../../../internationalization/labels/labels';
import { Subscription, timer } from 'rxjs';
import { Icons } from 'src/icons';
import { Colors } from 'src/colors';
import { FloatLabelType, MatOption, MatSelect } from '@angular/material';
import { Response } from 'src/app/resource/dto/response';
import {getTooltipUtil} from "../../utils/tooltip/tooltip.util";
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
	selector: 'app-dropdown-list',
	templateUrl: './dropdown-list.component.html',
	styleUrls: ['./dropdown-list.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => DropdownListComponent),
			multi: true
		}
	]
})
export class DropdownListComponent implements ControlValueAccessor, OnInit, OnDestroy {
	@ViewChild('selectInput', { static: false }) selectInput: MatSelect;
	@ViewChild('optionAll', { static: false }) optionAll: MatOption;
	@ViewChild('hiddenInput', { static: false }) hiddenInput;
	@ViewChild('searchInput', { static: false }) searchInput;

	@Input() idProperty = 'id';
	@Input() displayProperty = 'description';
	@Input() auxDisplayProperty: string;
	@Input() placeholderInside: string;
	@Input() placeholder: string;
	@Input() pipe: string;
	@Input() arrayData: any[];
	@Input() microService: string;
	@Input() endPoint: string;
	@Input() showPlaceholderAsDisplay = false;
	@Input() queryParams: string;
	@Input() disabled = false;
	@Input() allSelectionMode = false;
	@Input() allSelected = false;
	@Input() showAllSelected = true;
	@Input() overlayAbsolute = false;
	@Input() multiple: boolean = true;
	@Input() customSearchText: string = '';
	@Input() dataTransformer: any[];
	@Input() hasSearch = true; // show up search bar
	@Input() callSearchOnClose = true;
	@Input() inputSearchLength: string;
	@Input() error: boolean;
	@Input() showLabelSelectAll = false;
	@Input() selectAllLabel = Labels.getLabels().select_all;
	@Input() returnSelectAllValue = false;
	@Input() floatLabel: FloatLabelType = 'auto';
	@Input() resetSingleSearch: boolean = false;
	@Input() hasEmptyValue = false;
	@Input() valueInEmptyValue = null;
	@Input() tooltipFormatted: boolean = false;
	@Input() tooltipPosition = "above";
	@Input() hideSelectAllOption = false;
	@Input() addButtonFlag = false;
	valueDeleted = new FormControl()
	@Input() afunilate: boolean;
	@Input() lastRequest: any
	@Input() abstractFilter: boolean;
	@Input() hasPagination:boolean = true;
	@Input() showAlertInfo: boolean = false;
	@Input() alertInfoMessage: string = "";

	@Input()
	set value(value: any | any[]) {
		if (value) {
			if (Array.isArray(value)) {
				this._value = [];

				for (const v of value) {
					if (v && v.id != -1) {
						if (this.arrayData && this.auxDisplayProperty) {
							const find = this.arrayData.find(a => a.id == v.id);
							if (find) {
								v[this.auxDisplayProperty] = find[this.auxDisplayProperty];
							}
						}

						this._value.push(v);
					}
				}
			} else {
				this._value = value;
			}
		}
	}

	@Output() valueChange = new EventEmitter();
	@Output() arrayDataChange = new EventEmitter();
	@Output() allSelectedChange = new EventEmitter();
	@Output() searchTextChange = new EventEmitter();
	@Output() isDropdownOpen = new EventEmitter();
	@Output() isDropdownClose = new EventEmitter();

	public _labels = Labels.getLabels();
	public _icons = Icons;
	public _colors = Colors.color;
	public _value: any | any[] = [];
	public _search = '';
	public _selectAll = false;
	public _showSelectAll = true;
	public _triggerError: boolean = false;
	public loadingOptionsList: boolean = false;
	public _selectAllValue = {
		id: -1,
		description: this.selectAllLabel
	};
	public _error = false;
	private _unselectAll = false;
	private _selectAllClicked = false;
	private _dispatcher: Subscription;
	private _subscription: Subscription;
	private _inputEvent = new Event('input', {
		bubbles: true,
		cancelable: true,
	});

	constructor(
		private service: ObjectFilterBaseService,
	) {}

	writeValue(value: any): void {
		this._value = value;
	}

	registerOnChange(fn: any): void {
		this.propagateChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.propagateTouched = fn;
	}

	propagateChange = (_: any) => {};
	propagateTouched = () => {};

	onValueChange(value: any) {
		this._value = value;
		this.propagateChange(this._value);
	}

	onBlur() {
		this.propagateTouched();
	}

	ngOnInit() {
		this._triggerError = this.error;
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes) {
			if (changes.selectAllLabel) {
				this._selectAllValue[this.displayProperty] = this.selectAllLabel;
			}

			if (changes.endPoint && changes.endPoint.currentValue && changes.endPoint.currentValue !== '/product/instance/filter') {
				this.callGetList(this.queryParams);
			}

			if (changes.displayProperty && changes.displayProperty.currentValue) {
				this._selectAllValue[this.displayProperty] = this.selectAllLabel;
			}

			if (changes.auxDisplayProperty && changes.auxDisplayProperty.currentValue) {
				this._selectAllValue[this.auxDisplayProperty] = this.selectAllLabel;
			}

			if (changes.value) {
				this.setErrorColor(false);
			}

			if (changes.error) {
				this._triggerError = this.error;
			}

			if (changes.customSearchText && changes.customSearchText.currentValue && !changes.customSearchText.isFirstChange) {
				this._search = changes.customSearchText.currentValue;
				this.callGetList();
			}

			if (changes.queryParams && changes.queryParams.currentValue && !changes.queryParams.isFirstChange) {
				this.callGetList();
			}

			if (changes.allSelected) {
				if (!changes.allSelected.currentValue && this.selectInput) {
					this.change([]);
					this.selectClick(this._selectAllValue);
				}
			}
		}
	}

	getList(queryParams: string) {
		if (!this.endPoint) return;

		if (this._subscription) {
			// cancel last request to get aways last response data
			this._subscription.unsubscribe();
		}

		this._subscription = this.service
			.getObjectFilterList(this.microService, this.endPoint, this._search, queryParams, this.hasPagination)
			.subscribe((resp: Response<any>) => {
					if (resp && resp.data) {
						this.arrayData = resp.data;
						if (!Array.isArray(resp.data) && resp.data != null) {
							this.arrayData = resp.data.arrayData;
						}

						if (this.dataTransformer) {
							this.arrayData.forEach(item => {
								if (this.dataTransformer.length > 1) {
									item = this.dataTransformer[0](item, ...this.dataTransformer.slice(1));
								} else {
									item = this.dataTransformer[0](item);
								}
							});
						}

						this.arrayDataChange.emit(resp.data);

						if (this.arrayData && this.auxDisplayProperty) {
							this.arrayData.forEach((value) => value[this.auxDisplayProperty] = value[this.displayProperty]);
						}

						if (!this.multiple && this._value) {
							this._value = this.arrayData.find(a => a.id == this._value.id);
						}
					} else if(Array.isArray(resp)) {
						this.arrayData = resp[0].arrayData;
					}
					this.loadingOptionsList = false;
				}, err => {
					this.loadingOptionsList = false;
				}
			);
	}

	callGetList(queryParams? ) {
		if (this._dispatcher) {
			this._dispatcher.unsubscribe();
		}
		this._dispatcher = timer(150).subscribe(() => {
			this.getList(this.queryParams ? this.queryParams : queryParams);
		});
	}

	emitSearchChange() {
		this.searchTextChange.emit(this._search);
	}

	cleanSelectAll() {
		if (this.multiple) {
			this.unselectAll(this._value);
		}
	}

	splitProperty(obj: any) {
		return CoreUtil.getSplittedProperty(obj, this.auxDisplayProperty ? this.auxDisplayProperty : this.displayProperty);
	}

	unselectAll(event) {
		const all = event.find(v => v.id == -1);
		if (all) {
			event.splice(event.indexOf(all), 1);

			this._unselectAll = false;
			this._selectAll = false;
		}
	}

	triggerHiddenInput() {
		this.hiddenInput.nativeElement.dispatchEvent(this._inputEvent);
	}

	change(event) {
		if (!this.multiple) {
			this._value = event;
			this.valueChange.emit(this._value);
		} else {
			this.triggerHiddenInput();
			this.showSelectAll();
			this.changeAllSelected(false);

			setTimeout(() => {
				if (this._selectAll) {
					if (!this._unselectAll) {
						if (this._search || !this.allSelectionMode) {
							event = [{ id: -1, description: this.selectAllLabel }];
							for (const data of this.arrayData) {
								event.push(Object.assign({}, data));
							}
						} else if (event.filter(e => e.id != -1).length > 0) {
							if (this._value.filter(v => v.id != -1).length == 0) {
								this.unselectAll(event);
							} else {
								event = [];
							}
						}
					} else {
						this.unselectAll(event);
					}
				} else if (this._selectAllClicked) {
					this._selectAllClicked = false;
					event = [];
				}
				this._value = event.filter(e => e.id != -1);
				if (this.returnSelectAllValue && this._selectAll) {
					this.valueChange.emit([this._selectAllValue]);
				} else {
					this.valueChange.emit(this._value);
				}
			});
		}
		this.onValueChange(this._value);
	}

	selectClick(value) {
		if (value && value.id == -1) {
			this._selectAll = this.optionAll && this.optionAll.selected;

			if (!this._selectAll) {
				this._selectAll = false; // avoid undefined
				const selectAll = this._value.find(v => v.id == -1);
				if (selectAll) {
					this._selectAllClicked = true;
				}

				this.changeAllSelected(false);
			} else if (!this._search) {
				this.changeAllSelected(true);
			} else {
				this.changeAllSelected(true);
			}
		} else {
			this._unselectAll = this._selectAll;
		}
	}

	changeAllSelected(value) {
		this.allSelected = value;

		this.allSelectedChange.emit(this.allSelected);
	}

	hideSelectAll() {
		this._selectAll = false;
		this._showSelectAll = false;
	}

	showSelectAll() {
		if (!this._showSelectAll) {
			this._selectAll = true;
		}

		this._showSelectAll = true;
	}

	compareCategoryObjects(object1: any, object2: any) {
		return object1 && object2 && object1.id == object2.id;
	}

	open(isOpened) {
		if(!isOpened && this.arrayData.length === 0){
			this.isDropdownClose.emit(isOpened)
			this._search = ''
		}
		this.isDropdownOpen.emit(isOpened);
		if (isOpened && this.multiple && this.hasSearch) {
			setTimeout(() => {
				if (this.searchInput) {
					this.searchInput.nativeElement.focus();
				}
			}, 200);
		} else if (this.multiple) {
			this._search = '';
			this.searchTextChange.emit(this._search);
			if(this.afunilate) this.callGetList(this.queryParams);
			else this.callGetList();
		} else if (!isOpened && !this.multiple && this.callSearchOnClose) {
			this.searchTextChange.emit(this._search);
			if(this.resetSingleSearch) {
				this._search = "";
				if(!this._value)
					this.callGetList();
			}
		} else if (isOpened && !this.multiple) {
			setTimeout(() => {
				if (this.searchInput) {
					this.searchInput.nativeElement.focus();
				}
			});
			this.callGetList();
		}
		if (!isOpened) {
			this.onBlur();
		}
	}

	setErrorColor(isError: boolean) {
		this._triggerError = isError;
	}

	ngOnDestroy() {
		if (this._dispatcher) {
			this._dispatcher.unsubscribe();
		}

		if (this._subscription) {
			this._subscription.unsubscribe();
		}
	}

	getTooltip(): string {
		return getTooltipUtil(this.disabled, this._value, this.displayProperty);
	}

	pushToList() {
		console.log(this.arrayData);
		const formatObject = {
			id: 0,
			description: this._search,
			hasDeleted: true
		}
		this.arrayData.unshift(formatObject);
		console.log(this._search);
	}
	unshiftToList(e) {
		console.log(e);
		// e.preventDefault(); //<--prevent default
		// e.stopPropagation();  //stop propagation
		// this.arrayData = this.arrayData.filter(elem => elem!=value) //<--remove the element from data
		// if (this.value.value==value)
		// 	this.value.setValue(null) //<--if the value is the remove data, set null
	}
}
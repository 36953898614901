import {ComponentType} from '@angular/cdk/portal';
import {Injectable, OnDestroy, TemplateRef} from '@angular/core';
import {MatDialog} from '@angular/material';
import {Subscription} from 'rxjs';
import {CoreUtil} from '../core-util';
import {ConfirmComponent} from '../layout/confirm/confirm.component';

@Injectable({
	providedIn: 'root'
})
export class ConfirmService implements OnDestroy {
	private _subscriptionList: Subscription[];

	constructor(public dialog: MatDialog) {
	}

	open(icon: string, title: string, message: string, confirmFunction, cancelFunction, isHTML = false,
			 noAction = false, configs?: ModalConfig) {
		configs = configs ? configs : new ModalConfig();
		const messageWidth = 310;
		const leftPosition = ((CoreUtil.getMenuWidth() / 2) + (CoreUtil.getToolbarWidth() / 2) - (messageWidth / 2));
		const subscription = this.dialog.open(configs.componentOrTemplateRef, {
			disableClose: true,
			width: (configs.config.width ? configs.config.width : (messageWidth + 'px')),
			height: configs.config.height,
			maxHeight: configs.config.maxHeight,
			maxWidth: configs.config.maxWidth,
			position: {
				left: configs.config.position.left ? configs.config.position.left : leftPosition + 'px',
				top: configs.config.position.top ? configs.config.position.top : '15%',
				right: configs.config.position.rigth,
				bottom: configs.config.position.bottom
			},
			data: {
				noAction,
				icon,
				title,
				message,
				isHTML
			}
		}).afterClosed().subscribe((result) => {
			if (result && result.confirm) {
				confirmFunction();
			} else {
				cancelFunction();
			}
		});

		this._subscriptionList = CoreUtil.incrementSubscriptionList(this._subscriptionList, subscription);
	}

	openModal(confirmFunction, cancelFunction, configs?: ModalConfig) {
		configs = configs ? configs : new ModalConfig();
		const messageWidth = 310;
		const leftPosition = (CoreUtil.getMenuWidth() + (CoreUtil.getToolbarWidth() / 2) - (messageWidth / 2));
		const subscription = this.dialog.open(configs.componentOrTemplateRef, {
			disableClose: true,
			width: (configs.config.width ? configs.config.width : (messageWidth + 'px')),
			height: configs.config.height,
			maxHeight: configs.config.maxHeight,
			maxWidth: configs.config.maxWidth,
			position: {
				left: leftPosition + 'px',
				top: '5%'
			},
			data: {}
		}).afterClosed().subscribe((result) => {
			if (result && result.confirm) {
				confirmFunction();
			} else {
				cancelFunction();
			}
		});
		this._subscriptionList = CoreUtil.incrementSubscriptionList(this._subscriptionList, subscription);
	}

	ngOnDestroy() {
		CoreUtil.unsubscribeSubscriptionList(this._subscriptionList);
	}
}


export class ModalConfig {

	componentOrTemplateRef?: ComponentType<any> | TemplateRef<any> = ConfirmComponent;

	config: {
		height: string, width: string, maxHeight: string, maxWidth: string, enableScroll: boolean,
		position: { left: string, top: string, rigth: string, bottom: string }
	} = {
		height: '',
		width: '',
		maxHeight: '',
		maxWidth: '',
		enableScroll: false,
		position: {
			left: '',
			top: '',
			rigth: '',
			bottom: ''
		}
	};
}
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SecondaryButtonDirective } from './secondary-button.directive';
import { AddButtonDirective } from './add-button.directive';
import { SuccessButtonDirective } from './success-button.directive';
import { TimeDirective } from './time.directive';
import { DateDirective } from './date.directive';
import { TaxIdDirective } from './tax-id.directive';
import { PhoneDirective } from './phone.directive';
import { ZipCodeDirective } from './zip-code.directive';
import { OffsetWidthDirective } from './offset-width.directive';
import { OffsetHeightDirective } from './offset-height.directive';
import { NumberDirective } from './number.directive';
import { NumberDecimalDirective } from './number-decimal.directive';
import { CheckboxListDirective } from './checkbox-list.directive';
import { EmailValidatorDirective } from './email-validator.directive';
import { AlphanumericValidatorDirective } from './alphanumeric-validator.directive';
import { CpfDirective } from './cpf.directive';
import { PlateValidatorDirective } from './plate-validator.directive';
import { DirtyDirective } from './dirty.directive';
import { HourMinuteDirective } from './hour-minute.directive';
import { ResettableFormDirective } from './resettable-form.directive';
import { DragDropDirective } from './drag-drop.directive';
import { StreamingDirective } from './streaming,directive';
import { SteamingValueDirective } from './steaming-value.directive';
import { ThousandMaskDirective } from './thousand-mask.directive';
import {OnlyNumbersDirective} from './numbers.directive';
import {OnlyLettersDirective} from '../../shared/directives/only-chars';
import {OnlyCharactersAndNumbersDirective} from '../../shared/directives/only-charAndNumber';
import {CustomTimeFormatDirective} from './timeMask.directive';
import { restrictNegativeNumbers } from './restrict-negative-numbers.directive';
import { TerminalDirective } from 'src/app/shared/directives/TerminalRules';


@NgModule({
	declarations: [
		SecondaryButtonDirective,
		AddButtonDirective,
		SuccessButtonDirective,
		TimeDirective,
		DateDirective,
		TaxIdDirective,
		PhoneDirective,
		ZipCodeDirective,
		OffsetWidthDirective,
		OffsetHeightDirective,
		NumberDirective,
		NumberDecimalDirective,
		CheckboxListDirective,
		EmailValidatorDirective,
		AlphanumericValidatorDirective,
		CpfDirective,
		PlateValidatorDirective,
		DirtyDirective,
		HourMinuteDirective,
		ResettableFormDirective,
		DragDropDirective,
		StreamingDirective,
		ThousandMaskDirective,
		SteamingValueDirective,
		OnlyNumbersDirective,
		OnlyLettersDirective ,
		OnlyCharactersAndNumbersDirective,
		CustomTimeFormatDirective,
		restrictNegativeNumbers,
		TerminalDirective
	],
	imports: [
		CommonModule
	],
	exports: [
		SecondaryButtonDirective,
		AddButtonDirective,
		SuccessButtonDirective,
		TimeDirective,
		DateDirective,
		TaxIdDirective,
		PhoneDirective,
		ZipCodeDirective,
		OffsetWidthDirective,
		OffsetHeightDirective,
		NumberDirective,
		NumberDecimalDirective,
		CheckboxListDirective,
		EmailValidatorDirective,
		AlphanumericValidatorDirective,
		CpfDirective,
		PlateValidatorDirective,
		DirtyDirective,
		HourMinuteDirective,
		ResettableFormDirective,
		DragDropDirective,
		StreamingDirective,
		ThousandMaskDirective,
		SteamingValueDirective,
		OnlyNumbersDirective,
		OnlyLettersDirective ,
		OnlyCharactersAndNumbersDirective,
		CustomTimeFormatDirective,
		restrictNegativeNumbers,
		TerminalDirective
	]
})
export class DirectiveModule { }

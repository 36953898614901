import {isEyt, isProd, isUat} from 'src/environments/environment';
import {Injectable} from '@angular/core';
import * as Msal from 'msal';
import * as moment from 'moment';
import {environment} from 'src/environments/environment';
import {User} from 'src/app/resource/dto/user';
import {AuthorizerService} from 'src/app/core/service/authorizer.service';
import {BehaviorSubject} from 'rxjs';

export const cacheLocation: 'localStorage' | 'sessionStorage' = 'localStorage';

export const msalConfig = {
	auth: {
		clientId: environment.clientId,
		authority: `https://login.microsoftonline.com/${environment.tenant}`,
		redirectUri: environment.redirectUri
	},
	cache: {
		cacheLocation,
		storeAuthStateInCookie: false
	},
	system: {
		tokenRenewalOffsetSeconds: 45 * 45
	}
};

@Injectable({
	providedIn: 'root'
})
export class AccessService {
	public msalInstance = new Msal.UserAgentApplication(msalConfig);
	private userInformationCache = new BehaviorSubject<User | null>(null);

	constructor(private authorizerService: AuthorizerService) {
		this.init(false);
		this.msalInstance.setLogger(new Msal.Logger((logLevel, message) => {
		}));
	}

	public token = '';
	public profileName = '';
	public tokenTimestamps = '';
	public user: User;
	public checked = false;
	public loading = false;
	private defaultFormat = 'DD/MM/yyyy HH[h]mm';
	private tokenCheckThresholdInMinutes = 5;
	private ssoRequest = {
		scopes: ['user.read', 'openid', 'profile']
	};

	public async loginPopup(force: boolean) {
		localStorage.removeItem('log_out');
		if (this.currentToken.isValid && this.currentToken.value) {
			this.redirectToSafe();
		}

		try {
			const data = await this.trySilentToken();
			if (data.idToken) {
				await this.authenticate(await this.getValidTokenInData(data), force);

				return this.redirectToSafe();
			}
		} catch (e) {
			console.log(`as:67 e: `, e);
		}
		this.loading = true;
		try {
			const token = await this.msalInstance.loginPopup(this.parsedSsoRequest);
			console.log(`as:72 token `, token)
			if (this.verifyIfIsIdTokenOrAccessToken(token)) {
				return this.init(false);
			}
			await this.authenticate(await this.getValidTokenInData(token), force);
			this.redirectToSafe();
		} catch (e) {
			this.init(false);
			console.warn(e);
		} finally {
			this.loading = false;
		}
	}
	/**
	 * Verifies if the token is an idToken or an accessToken based on the environment.
	 *
	 * This validation was necessary because there was a demand that required
	 * it to be idToken for EYT and Production environments and accessToken for the other environments.
	 *
	 * @param {Object} token - The token object which contains idToken and accessToken properties.
	 * @returns {boolean} - Returns true if the token is not present or is falsy, otherwise returns false.
	 */
	private verifyIfIsIdTokenOrAccessToken(token) {
		if (isEyt || isProd || isUat) {
			return !token.idToken;
		}
		return !token.accessToken;
	}

	public async logout(): Promise<void> {
		localStorage.clear();
		localStorage.setItem('log_out', 'log_out');
		this.user = {} as User;
		this.profileName = '';
		this.token = '';
		this.tokenTimestamps = '';
		this.msalInstance.logout();
	}

	public async init(force: boolean) {
		if (this.currentToken.isValid && this.currentToken.value && !force) {
			this.redirectToSafe();
		}

		this.loading = true;
		await this.fetchTokenSilently(force);
	}

	public async trySilentToken() {
		return await this.msalInstance.acquireTokenSilent(
			this.cachedToken.loginHint
				? {
					scopes: this.parsedSsoRequest.scopes,
					loginHint: this.cachedToken.loginHint
				}
				: this.parsedSsoRequest
		);
	}

	public async fetchTokenSilently(force: boolean) {
		if (!window.location.href.includes('login') && !localStorage.getItem('ic_user')) {
			window.location.href = window.location.origin + window.location.pathname + '#/login';
		}
		if (localStorage.getItem('log_out')) return false;
		try {
			const dataUser = await this.msalInstance.acquireTokenSilent(this.parsedSsoRequest);
			await this.authenticate(await this.getValidTokenInData(dataUser), force);
			this.redirectToSafe();

			return true;
		} catch (e) {
			if (!force) {
				window.location.href = window.location.origin + window.location.pathname + '#/login';

				return false;
			} else {
				this.loginPopup(force);
			}
		}
	}

	public async authenticate(token, force) {
		new Promise(async (resolve, reject) => {
			try {
				this.authorizerService
					.authenticate('Bearer ' + token)
					.toPromise()
					.then((response) => {
						this.setLoginInfo(response.data);
						resolve(response.data);
					});
			} catch (e) {
				console.log(`as:156 catch e `, e)
				if (window.location.href.includes('/login')) return;
				if (force) return reject(e);
				window.location.href =
					window.location.origin + window.location.pathname + '#/login';
				console.warn(e);
				reject(e);
			}
		});
	}

	public useCachedUser() {
		this.setLoginInfo(this.cachedToken.user);
	}

	public setLoginInfo(user: User) {
		this.token = localStorage.getItem('msal.idtoken');
		this.profileName = user.profile.profileName;
		this.tokenTimestamps = moment().format(this.defaultFormat);
		this.user = user;
		localStorage.setItem('ic_token', user.accessToken);
		localStorage.setItem('ic_user', JSON.stringify(user));
		localStorage.setItem('login_hint', user.username);
		localStorage.setItem('ic_profile_name', user.profile.profileName);
		localStorage.setItem('ic_token_timestamps', moment().format(this.defaultFormat));
	}

	private get parsedSsoRequest() {
		return this.cachedToken.loginHint
			? {...this.ssoRequest, loginHint: this.cachedToken.loginHint}
			: this.ssoRequest;
	}

	private get cachedToken() {
		return {
			value: localStorage.getItem('ic_token'),
			profileName: localStorage.getItem('ic_profile_name'),
			isValid: localStorage.getItem('ic_token_timestamps')
				? this.isValidFn(localStorage.getItem('ic_token_timestamps'))
				: false,
			timestamps: localStorage.getItem('ic_token_timestamps'),
			loginHint: localStorage.getItem('login_hint'),
			user: JSON.parse(localStorage.getItem('ic_user') || '{}')
		};
	}

	public get currentToken() {
		return {
			value: undefined,
			isValid: this.tokenTimestamps ? this.isValidFn(this.tokenTimestamps) : false,
			user: this.user
		};
	}

	private redirectToSafe() {
		if (!window.location.href.includes('/login')) return;
		window.location.href = window.location.origin + window.location.pathname + '#/';
	}

	private isValidFn(timestamps: string) {
		const parsedMoment = moment(timestamps, this.defaultFormat);
		parsedMoment.add(this.tokenCheckThresholdInMinutes, 'minutes');

		return moment().isBefore(parsedMoment);
	}

	public async getValidTokenInData (data) {
		if ((data.idToken.rawIdToken && data.idToken.rawIdToken !== '')
			&& (data.idToken.rawIdToken === data.accessToken)) {
			const dataUser  = await this.trySilentToken();
			await this.getValidTokenInData(dataUser);
			return dataUser.idToken.rawIdToken;
		}
		return data.idToken.rawIdToken
	}

}

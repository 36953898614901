import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'phone'
})
export class PhonePipe implements PipeTransform {
	transform(value: any, isMobile: boolean): any {
		if (value) {
			if (value.length < 3) {
				value = value.replace(/^(\d{2})?/, '+$1');
			} else if (value.length < 5) {
				value = value.replace(/^(\d{2})?(\d{2})?/, '+$1 $2');
			} else if (!isMobile) {
				if (value.length < 9) {
					value = value.replace(/^(\d{2})?(\d{2})?(\d{4})?/, '+$1 $2 $3');
				} else {
					value = value.replace(/^(\d{2})?(\d{2})?(\d{4})?(\d{4})?/, '+$1 $2 $3-$4');
				}
			} else if (value.length < 10) {
				value = value.replace(/^(\d{2})?(\d{2})?(\d{5})?/, '+$1 $2 $3');
			} else if (value.length == 12) {
				value = value.replace(/^(\d{2})?(\d{2})?(\d{4})?(\d{4})?/, '+$1 $2 $3-$4');
			} else {
				value = value.replace(/^(\d{2})?(\d{2})?(\d{5})?(\d{4})?/, '+$1 $2 $3-$4');
			}
		}

		return value;
	}
}
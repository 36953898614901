import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'stringSplit'
})
export class StringSplitPipe implements PipeTransform {
	transform(value: string, params: any[]): string {
		if (value && params) {
			return value.split(params[0])[params[1]];
		}

		return undefined;
	}
}
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { auditTime, debounceTime, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { UsersService } from 'src/app/resource/page/management/settings/users/users.service';
import { AbstractGridList } from '../../abstract/abstract-grid-list';
import { AbstractService } from '../../abstract/abstract-service';
import { Pagination } from '../../dto/pagination';
import { HistoryService } from '../../service/history.service';
import { Response } from 'src/app/resource/dto/response';
import { CoreUtil } from '../../core-util';
import { Historic } from '../../dto/historic';
import { Location, TitleCasePipe } from '@angular/common';
import * as moment from 'moment';
import { User } from 'src/app/resource/dto/user';

@Component({
	selector: 'app-history',
	templateUrl: './history.component.html',
	styleUrls: ['./history.component.scss']
})
export class HistoryComponent extends AbstractGridList implements OnInit {
	@ViewChild('fromDateField', { static: false }) fromDateField;
	@ViewChild('toDateField', { static: false }) toDateField;

	@Input() service: AbstractService<any>;
	@Input() endPoint: string;
	@Input() idParams: string;
	@Input() mainObjectId: number;
	@Input() isExportXls: boolean = true;
	@Input() hasBackButton: boolean = true;

	@Output() changeDirty = new EventEmitter();

	public mainObject: any;
	public _arrayHeader: any[];
	public fromDate;
	public toDate;
	public _histories: Historic[];
	public _selectedUserId: any[] = [];
	public _pagination = new Pagination();
	public _doSearch = false;
	public isSearchFinished: boolean;
	public _usersList$: Observable<unknown>;
	public _isUserListLoading$ = new BehaviorSubject(true);
	public _params: string = '';

	private _pipeParams = [2, 'pt-BR', false, 'BRL'];
	private _subscriptionList: Subscription[];
	private _allUsersSelected$ = new BehaviorSubject(false);
	private _selectedUsers$ = new BehaviorSubject([]);
	private _componentDestroyed$ = new Subject();
	private _idQuery: string = '';

	constructor(public historyService: HistoryService,
			private userService: UsersService,
			private _location: Location) {
		super();
	}

	ngOnInit() {
		this.setArrayHeader();
		this.setUsersList();
		this.setEndpoint();
		this.setObservables();

		// TODO Implement future Historic API
		// Removal requested by thiago varjão
		// if ((this.mainObject && this.mainObject.id) || this.mainObjectId) {
		// 	combineLatest([this._allUsersSelected$, this._selectedUsers$]).pipe(
		// 		filter(() => this.mainObject),
		// 		auditTime(500),
		// 		debounceTime(500),
		// 		takeUntil(this._componentDestroyed$),
		// 		switchMap(([areAllUsersSelected, selectedUsers]) => {
		// 				return this.historyService.getListHistory(
		// 					this._idQuery,
		// 					this.fromDate,
		// 					this.toDate,
		// 					areAllUsersSelected ? [] : selectedUsers,
		// 					this._pagination
		// 				);
		// 		}),
		// 		).subscribe(this.historyResponseHandler.bind(this));
		// }

		this.updateDirty(false);
	}

	setObservables() {
		const subscription = this.service.observable.subscribe((obj: any) => {
			if (obj) {
				this.mainObject = obj;
				this.setParams();
			}
		});

		const historySubscription = this.service.historyObservable.subscribe((obj: any) => {
			if (obj) {
				this.mainObject = obj;
				this.setParams();
			}
		});

		this._subscriptionList = CoreUtil.incrementSubscriptionList(this._subscriptionList, subscription);
		this._subscriptionList = CoreUtil.incrementSubscriptionList(this._subscriptionList, historySubscription);
	}

	setArrayHeader() {
		this._arrayHeader = [
			{ key: 'name', value: this._labels.name },
			{ key: 'date', value: this._labels.date, pipe: 'date' },
			{ key: 'time', value: this._labels.time, pipe: 'date' },
			{ key: 'subject', value: this._labels.subject, maskFunction: this.getTitleCaseMask },
			{ key: 'event', value: this._labels.event, pipe: 'titlecase' },
			{ key: 'reference', value: this._labels.reference },
			{ key: 'field', value: this._labels.field },
			{ key: 'oldText', value: this._labels.old_text, maskFunction: this.getPipe },
			{ key: 'newText', value: this._labels.new_text, maskFunction: this.getPipe }
		];
	}

	setUsersList() {
		this._usersList$ = this.userService.getList('').pipe(
			map((userResponse: Response<unknown>) => {
				if (Array.isArray(userResponse.data) && (userResponse.data.length > 0)) {
					this._isUserListLoading$.next(false);

					return userResponse.data;
				}

				return userResponse.data;
			}
		));
	}

	setEndpoint() {
		this.historyService.setEndpoint(this.endPoint);
	}

	newSearch() {
		this._pagination.page = 0;

		this.getHistoryList();
	}

	onAllUsersSelected($event: boolean): void {
		this._allUsersSelected$.next($event);
	}

	onUsersValueChange($event: User[] | undefined): void {
		this._selectedUsers$.next($event);
	}

	getHistoryList() {
		this.isSearchFinished = false;

		this.updateDirty(false);

		if (this._idQuery) {
			this._histories = [];
			this.updateTableHeight();
			this.isSearchFinished = true;
			// const subscription = this.historyService.getListHistory(this._idQuery, this.fromDate, this.toDate, this._selectedUserId, this._pagination)
			// 	.subscribe(this.historyResponseHandler.bind(this));
			//
			// this._subscriptionList = CoreUtil.incrementSubscriptionList(this._subscriptionList, subscription);
		}
	}

	private historyResponseHandler(response: Response<Historic[]>) {
		this._histories = [];

		if (response) {
			this._pagination.totalData = response.totalData;
			this._pagination.totalPages = response.totalPages;
			if (response.data) {
				this._histories = response.data;

				for (let history of this._histories) {
					history = this.updateHistory(history);
				}
			}
		}

		this.isSearchFinished = true;

		this.updateTableHeight();
	}

	updateHistory(history: Historic): Historic {
		if (history.field === this._constants.general.business_unity_id) {
			history.field = this._labels.business_unity_id;
		} else {
			history.field = this._labels[history.field.toLowerCase()];
		}

		history.event = this._labels.historyEvent[history.event.toLowerCase()];
		history.subject = this._labels[history.subject.toLowerCase()];
		history.newText = this.addCustomValues(history.newText, history.mask);
		history.oldText = this.addCustomValues(history.oldText, history.mask);

		// TODO: Check if every History endpoint sends the 'mask' field
		if (history.field === this._labels.validity_from ||
			history.field === this._labels.validity_to) {
			history.mask = 'date';
		}

		if (history.field === this._labels.value_toll ||
			history.field === this._labels.value_freight_20_l ||
			history.field === this._labels.value_freight_20_ls ||
			history.field === this._labels.value_freight_40_l ||
			history.field === this._labels.value_freight_40_ls) {
			history.mask = 'numberDecimal';
		}

		if (history.mask == 'numberDecimal') {
			history.pipeParams = this._pipeParams;
		}

		return history;
	}

	addCustomValues(value: string, mask: string): string {
		switch (value) {
			case this._constants.general.true:
				if (!mask) {
					return this._labels.yes;
				}
				break;

			case this._constants.general.false:
				if (!mask) {
					return this._labels.no;
				}
				break;

			case this._constants.status.active:
				return this._labels.active;

			case this._constants.status.inactive:
				return this._labels.inactive;

			default:
				return value;
		}

		return value;
	}

	dataTransform(data: Historic, _labels, _constants) {
		const updateActiveVal = (obj: Historic, objVal: string) => {
			if (objVal === _constants.general.true) {
				return _labels.yes;
			} else if (objVal === _constants.general.false) {
				return _labels.no;
			} else if (objVal === _constants.status.active) {
				return _labels.active;
			} else if (objVal === _constants.status.inactive) {
				return _labels.inactive;
			} else if (objVal &&
				(obj.field === _constants.general.validity_from ||
					obj.field === _constants.general.validity_to ||
					obj.field === _labels.validity_from ||
					obj.field === _labels.validity_to)) {
				return moment(objVal).format(_constants.dateFormat.DD_MM_YYYY_slash);
			}

			return objVal;
		};

		data.subject = _labels[data.subject.toLowerCase()];
		data.event = new TitleCasePipe().transform(data.event);
		data.field = _labels[data.field.toLowerCase()] || data.field;
		data.newText = updateActiveVal(data, data.newText);
		data.oldText = updateActiveVal(data, data.oldText);

		const historyCreatedDate = moment(data.date);
		if (historyCreatedDate.isValid()) {
			data.date = moment(historyCreatedDate).format(_constants.dateFormat.DD_MM_YYYY_slash);
		}

		return data;
	}

	setParams() {
		this._idQuery = `${this.idParams}=${this.mainObject.id || this.mainObjectId}`;
		this._params = `&${this.idParams}=${this.mainObject.id || this.mainObjectId}&ascendingOrder=false`;
	}

	getPipe(history: Historic) {
		return history.mask;
	}

	getTitleCaseMask(history: Historic) {
		return (history.subject.toLowerCase() !== 'pgr'.toLowerCase() && history.subject.toLowerCase() !== 'ddr'.toLowerCase()) ? 'titlecase' : undefined;
	}

	datesChanged() {
		if (this.fromDate != '' && this.fromDate != undefined && this.toDate != '' && this.toDate != undefined && this.isSearchFinished) {
			this.newSearch();
		}

		this.updateDirty(false);
	}

	updateDirty(dirty: boolean) {
		this.changeDirty.emit(dirty);
	}

	isBooleanValue(value: string): boolean {
		return value === this._constants.general.true || value === this._constants.general.false;
	}

	addSearch() {
		if (this._doSearch) {
			this._doSearch = false;

			this.reset();
			this.getHistoryList();
		} else {
			this._doSearch = true;
		}
	}

	reset() {
		this.fromDate = '';
		this.toDate = '';
		this._selectedUserId = [];
	}

	back() {
		this._location.back();
	}

	ngOnDestroy() {
		this._componentDestroyed$.next();
		this._componentDestroyed$.complete();
		CoreUtil.unsubscribeSubscriptionList(this._subscriptionList);
	}
}
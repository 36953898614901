// Used to get roles of user. Data after '=' only a-z|0-9
export enum EModulesType {
	customer= 'customer',
	general_register = 'generalregister',
	insurance = 'insurance',
	locality = 'locality',
	management = 'management',
	vendor = 'vendor',
	vessel_capacity = 'vesselcapacity',
	product = 'product',
	commercial = 'commercial',
	pricing = 'pricing',
	cost = 'cost',
	agreement = 'agreement'
}
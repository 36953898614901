import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'n_a'
})
export class NaPipe implements PipeTransform {
	transform(value: any): any {
		if(!value) {
			return 'N/A'
		}
		if (value === -2) {
			return 'N/A'
		}
		return value;
	}
}

// Used to get roles of user. Data after '=' only a-z|0-9
export enum ESubModulesType {
	acr = 'acr',
	additional_fees = 'additionalfees',
	associate_plate = 'associateplate',
	capacity = 'capacity',
	certification = 'certification',
	cities_and_states = 'citiesandstates',
	checklist = 'checklist',
	commodity = 'commodity',
	corporate_group = 'corporategroup',
	customer = 'customer',
	agreement = 'agreement',
	ddr = 'ddr',
	insurer = 'insurer',
	operational_requirements = 'operationalrequirements',
	own_policy = 'ownpolicy',
	own_policy_group = 'ownpolicygroup',
	pgr = 'pgr',
	profile = 'profile',
	regions = 'regions',
	sub_group = 'subgroup',
	transports = 'transports',
	transports_costing = 'transportscosting',
	user_profile = 'userprofile',
	vendor = 'vendor',
	vessel = 'vessel',
	vessel_capacity = 'vesselcapacity',
	proforma = 'proforma',
	schedule = 'schedule',
	product = 'product',
	pricing = 'pricing',
	port_terminals = 'portterminals',
	cost = 'cost',
	general_register = 'generalregister',
	exchange_rate = 'exchangerate',
	disclaimer = 'disclaimers',
	terminals = 'terminals',
}
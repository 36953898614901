import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
	name: 'timeDoubledot'
})
export class TimeDoubledotPipe implements PipeTransform {

	transform(value: string): string {
		if(!value) return value;
		const formattedValue = moment(value, 'HH:mm').format('HH:mm:ss');
		const cleanedValue = formattedValue.replace(/\D/g, '');

		if (cleanedValue) {
			const paddedValue = cleanedValue.padStart(4, '0');

			const hours = paddedValue.substring(0, 2);
			const minutes = paddedValue.substring(2, 4);

			return hours + ':' + minutes;
		}

		return '';
	}


}
import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Messages } from 'src/internationalization/messages/messages';

@Component({
	selector: 'app-multiselect',
	templateUrl: './multiselect.component.html',
	styleUrls: ['./multiselect.component.scss']
})
export class MultiselectComponent implements OnInit, OnChanges {
	@Input() placeholder: string;
	@Input() data: any[];
	@Input() idField: string = 'id';
	@Input() displayField: string;
	@Input() arrayOfObject = false;
	@Input() field: any;
	@Output() fieldChange = new EventEmitter();

	public _messages = Messages.getMessages();
	public _arrayData = [];
	public _settings = {};
	public _field;

	constructor() { }

	ngOnInit() {
		this._settings = {
			singleSelection: false,
			idField: 'item_id',
			textField: 'item_text',
			itemsShowLimit: 2,
			allowSearchFilter: true,
			searchPlaceholderText: this._messages.search,
			noDataAvailablePlaceholderText: this._messages.no_data_available,
			enableCheckAll: false
		};
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.data && changes.data.currentValue &&
			changes.data.currentValue.length > 0 && this.arrayOfObject) {
			this._arrayData = [];
			for (const d of this.data) {
				this._arrayData.push({ item_id: d[this.idField], item_text: d.description });
			}
		}

		if (changes.field && changes.field.currentValue && !this._field) {
			if (!this.arrayOfObject) {
				this._field = changes.field.currentValue;
			} else {
					const values = [];
					for (const value of changes.field.currentValue) {
						values.push({item_id: value[this.idField], item_text: value[this.displayField]});
					}
					this._field = values;
				}
		}
	}

	change(event) {
		if (event) {
			const values = [];
			if (!this.arrayOfObject) {
				for (const value of event) {
					values.push(value.item_text);
				}
			} else {
				for (const value of event) {
					values.push(this.data.find(d => d[this.idField] == value.item_id));
				}
			}

			if (!this.field || this.field.length != values.length) {
				this.fieldChange.emit(values);
			}
		}
	}
}
import { Directive, ElementRef, HostListener, forwardRef } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';
import { ToastService } from '../service/toast.service';
import { Messages } from 'src/internationalization/messages/messages';
import { Constants } from 'src/constants';
import { Colors } from 'src/colors';

@Directive({
	selector: '[appPlateValidator]',
	providers: [{ provide: NG_VALIDATORS, useExisting: forwardRef(() => PlateValidatorDirective), multi: true }]
})
export class PlateValidatorDirective implements Validator {
	private _messages = Messages.getMessages();
	private _constants = Constants.constant;
	private control: AbstractControl;
	private regexPlate: any = /[a-zA-Z]{3}[0-9]{1}[a-zA-Z0-9]{1}[0-9]{2}/;
	private regexInput = /\w*/;
	private regexCleanValue = /\W/g;

	constructor(private element: ElementRef, private toastService: ToastService) { }

	validate(c: AbstractControl): { [key: string]: any; } {
		this.control = c;

		return null;
	}
	@HostListener('input', ['$event']) public onInput(event: KeyboardEvent): void {
		const value = (event.target as HTMLInputElement).value;
		const regexResult = value.match(this.regexInput);

		if (regexResult[0] !== regexResult.input) {
			this.showToast(this._constants.messageType.error, this._messages.special_characters_not_allowed_plate);

			this.control.setValue(value.replace(this.regexCleanValue, ''));

			return;
		}

		this.element.nativeElement.parentElement.style.backgroundColor = '';
		this.control.setValue(value);
		this.control.setErrors({ plateValid: null });
		this.control.updateValueAndValidity();
	}

	@HostListener('blur', ['$event']) blur($event: any) {
		if (this.control.value) {
			if (this.control.value > 7) {
				this.setControlError();

				return;
			}

			if (!this.regexPlate.test(this.control.value)) {
				this.setControlError();

				return;
			}
		}

		this.element.nativeElement.parentElement.style.backgroundColor = '';

		this.control.setErrors(null);

		return;
	}

	setControlError() {
		this.element.nativeElement.parentElement.style.backgroundColor = Colors.color.red_error_background;
		this.control.setErrors({ plateValid: false });
		// this.showInvalidVehiclePlate();
		this.showToast(this._constants.messageType.error, this._messages.invalid_plate_format);
	}

	private showToast(type: string, message: string): void {
		this.toastService.show(type, message);
	}
}
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-ball',
  templateUrl: './ball.component.html',
  styleUrls: ['./ball.component.scss']
})
export class BallComponent implements OnInit {
  
  @Input()
  width: string = '15px';

  @Input()
  height: string = '15px';

  @Input()
  color: string = 'rgb(196, 192, 192)';

  @Input()
  show: boolean = true;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['color'] && changes['color'].currentValue != changes['color'].previousValue){
      this.color = changes['color'].currentValue ? changes['color'].currentValue : 'rgb(196, 192, 192)';
    }
  }

}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Labels } from 'src/internationalization/labels/labels';

@Component({
	selector: 'app-period',
	templateUrl: './period.component.html',
	styleUrls: ['./period.component.scss']
})
export class PeriodComponent implements OnInit {
	@Input() fromDate: string;
	@Input() toDate: string;
	@Input() disabled = false;
	@Output() fromDateChange = new EventEmitter();
	@Output() toDateChange = new EventEmitter();
	@Output() onBlur = new EventEmitter();

	private _changed = false;
	public _labels = Labels.getLabels();

	constructor() { }

	ngOnInit() { }

	blur() {
		if (this.fromDate && this.toDate) {
			this.onBlur.emit();
		}
	}

	changeFrom(fromDateInput: string | undefined): void {
		if (this.isDateRangeValid(fromDateInput, this.toDate)) {
			this.emitChange(this.fromDateChange, fromDateInput);
		}
	}

	changeTo(toDateInput: string | undefined): void {
		if (this.isDateRangeValid(this.fromDate, toDateInput)) {
			this.emitChange(this.toDateChange, toDateInput);
		}
	}

	isDateRangeValid(from: string | undefined, to: string | undefined): boolean {
		const dateFrom = new Date(from);
		const dateTo = new Date(to);

		if (this.dateExist(dateFrom) && this.dateExist(dateTo)) {
			return this.isSameDay(dateFrom, dateTo) || dateFrom < dateTo;
		}

		return !!(this.dateExist(dateFrom) || this.dateExist(dateTo));
	}

	private dateExist(date: Date): boolean {
		return !Number.isNaN(date.getTime());
	}

	private isSameDay(refDate1: Date, refDate2: Date): boolean {
		return refDate1.getFullYear() === refDate2.getFullYear()
					&& refDate1.getMonth() === refDate2.getMonth()
					&& refDate1.getDate() === refDate2.getDate();
	}

	emitChange(emitter: EventEmitter<unknown>, event: any) {
		if (!this._changed) {
			this._changed = true;
			emitter.emit(event);
			setTimeout(() => {
				this._changed = false;
			});
		}
	}
}
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import {Icons} from '../../../../icons';
import {Labels} from '../../../../internationalization/labels/labels';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-download-file',
  templateUrl: './download-file.component.html',
  styleUrls: ['./download-file.component.scss']
})
export class DownloadFileComponent {
	@Input() fileName: string;
	@Input() typeFile: string;
	// File size follows blob pattern which is in bytes
	@Input() fileSize: number = 0;
	// Use this variable if it is blob
	@Input() file: Blob;
	// Use this variable if it is a download-file url
	@Input() uriFile: string;
	// Use this variable to set the file download status
	@Input() downloaded = false;
	// Visible icon despite of having file or not
	@Input() icon: boolean = false;
	// File size and downloaded file information visible
	@Input() info: boolean = true;
	// Use this variable as false to dont listen to click
	@Input() listenClick: boolean = true;
	// Use this variable to emit the file download status
	@Output() wasDownloaded = new EventEmitter<boolean>();

	_icons = Icons;
	_labels = Labels.getLabels();

	download() {
	if(this.listenClick) {
		this.downloaded = true;
		if (this.file) {
			this.caseFile();
		  } else {
			this.caseUri();
		  }
		  this.wasDownloaded.emit();
	}
	}

	caseFile() {
		saveAs(this.file, `${this.fileName}.${this.typeFile}`);
	}

	caseUri() {
  	window.open(this.uriFile, "_blank");
	}

	formatBytes(bytes, decimals = 2) {
		if (bytes === 0) return '0 Bytes';
		const k = 1024;
		const dm = decimals < 0 ? 0 : decimals;
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
		const i = Math.floor(Math.log(bytes) / Math.log(k));
		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
	}

}

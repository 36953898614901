import { Component, OnInit, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { MatSlideToggle } from '@angular/material/slide-toggle';

@Component({
	selector: 'app-appointment-brand',
	templateUrl: './appointment-brand.component.html',
	styleUrls: ['./appointment-brand.component.scss']
})
export class AppointmentBrandComponent implements OnInit {
	@ViewChild(MatSlideToggle, {static: false}) toggle;

	@Input() index: number;
	@Input() imageSrc: string;
	@Input() brand: string;
	@Input() disabled: boolean;
	@Input() checked: boolean;
	@Output() switchChange = new EventEmitter();

	constructor() { }

	ngOnInit() { }

	onSwitchChange() {
		this.switchChange.emit({index: this.index, checked: this.toggle.checked});
	}
}
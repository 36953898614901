import { Booking } from './../../dto/booking';
import { Injectable } from '@angular/core';
import { Appointment } from './../../dto/appointment';
import { AbstractService } from 'src/app/core/abstract/abstract-service';
import { HttpClientService } from 'src/app/core/service/http-client.service';
import { Constants } from 'src/constants';

export enum AppointmentBrandsEnum {
	Aliance = 'Aliance',
	HamburgSud = 'HamburgSud',
	Maersk = 'Maersk',
	WhiteFlagHamburgSudAliance = 'WhiteFlagHamburgSudAliance',
	WhiteFlagMaersk = 'WhiteFlagMaersk'
}

export enum AppointmentStatusEnum {
	CreatedExport = 'Created Export Appointments',
	CreatedImport = 'Created Import Appointments',
	PendingExport = 'Pending Export Appointments',
	PendingImport = 'Pending Import Appointments',
	ReservedExport = 'Reserved Export Appointments',
	ReservedImport = 'Reserved Import Appointments'
}

export enum ContainerStatusEnum {
	Confirmed = 'Confirmed',
	Pending = 'Pending',
	PendingCS = 'PendingCS',
	PendingIMD = 'PendingIMD',
	Sent = 'Sent'
}

@Injectable()
export class AppointmentService extends AbstractService<Appointment> {
	constructor(httpClientService: HttpClientService) {
		super(httpClientService);

		this._microService = Constants.constant.microServices.appointment;
		this._endPoint = Constants.constant.microServices.appointment;
	}

	static getAppointmentStatusColor(id: AppointmentStatusEnum) {
		switch (id) {
			case AppointmentStatusEnum.CreatedExport:
				return '#5ebcde';
			case AppointmentStatusEnum.CreatedImport:
				return '#4c9158';
			case AppointmentStatusEnum.PendingExport:
				return '#ffdd1c';
			case AppointmentStatusEnum.PendingImport:
				return '#96c59d';
			case AppointmentStatusEnum.ReservedExport:
				return '#90d69b';
			case AppointmentStatusEnum.ReservedImport:
				return '#ff970a';
			default:
				break;
		}

		return '#fff';
	}

	getRequestData(appointment: Appointment) {
		return appointment;
	}

	getBrands(): AppointmentBrandsEnum[] {
		return [AppointmentBrandsEnum.Aliance, AppointmentBrandsEnum.HamburgSud,
		AppointmentBrandsEnum.Maersk, AppointmentBrandsEnum.WhiteFlagHamburgSudAliance,
		AppointmentBrandsEnum.WhiteFlagMaersk];
	}

	getStatus(): AppointmentStatusEnum[] {
		return [AppointmentStatusEnum.PendingExport, AppointmentStatusEnum.CreatedExport,
		AppointmentStatusEnum.ReservedExport, AppointmentStatusEnum.PendingImport,
		AppointmentStatusEnum.CreatedImport, AppointmentStatusEnum.ReservedImport];
	}

	isExpo(id: AppointmentStatusEnum) {
		return (id == AppointmentStatusEnum.CreatedExport ||
			id == AppointmentStatusEnum.PendingExport ||
			id == AppointmentStatusEnum.ReservedExport);
	}

	fakeBooking(id: AppointmentStatusEnum) {
		const booking = new Booking();
		booking.bookingCreationDate = '10/04/2020 - 13:20h';
		booking.bookingNumber = '342324377';
		booking.type = id;
		booking.brand = 'Hamburg Sud';
		booking.customerTradingName = 'Honda do Brasil S.A.';
		booking.creationUser = 'Rodrigo Gusmão';
		booking.date = '10/04/2020';
		booking.exportation = true;
		booking.hour = '13:20';
		booking.imdChargeType = this.isExpo(id) ? 'IMD Inside BL' : 'IMD Outside BL';
		booking.insideBL = true;
		booking.numberOfConfirmed = 5;
		booking.numberOfContainers = 24;
		booking.numberOfPedings = 10;
		booking.numberOfSent = 4;
		booking.numberOfUnderCsAnalysis = 3;
		booking.numberOfUnderImdAnalysis = 2;
		booking.placeOfDelivery = this.isExpo(id) ? 'Berlim - DE' : 'Campinas SP';
		booking.placeOfReceipt = this.isExpo(id) ? 'Campinas SP' : 'Berlim - DE';
		booking.portOfDischarge = this.isExpo(id) ? 'Hamburgo - DE' : 'Santos - SP';
		booking.portOfLoading = this.isExpo(id) ? 'Santos - SP' : 'Hamburgo - DE';
		booking.status = id;

		return booking;
	}

	isValid(r: Appointment): boolean {
		return false;
	}

	updateState(object: Appointment) {
		if (object) { this._trigger.next(object); }
	}

	updateHistoryState(object: Appointment) {
		if (object) { this._historyTrigger.next(object); }
	}
}
import { Component, OnInit, Input } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';



registerLocaleData(localePt);

@Component({
	selector: 'app-pipe-display',
	templateUrl: './pipe-display.component.html',
	styleUrls: ['./pipe-display.component.scss']
})
export class PipeDisplayComponent implements OnInit {
	@Input() pipe: string;
	@Input() display: string;
	@Input() params: any[];
	@Input() mask;

	constructor() { }

	ngOnInit() { }
}

import { Directive, HostListener } from '@angular/core';

@Directive({
	selector: '[appOnlyCharactersAndNumbers]'
})
export class OnlyCharactersAndNumbersDirective {
	@HostListener('keypress', ['$event']) onKeyPress(event: KeyboardEvent) {
		const pattern = /[a-zA-Z0-9 ]/;
		let inputChar = String.fromCharCode(event.charCode);
		if (!pattern.test(inputChar)) {
			event.preventDefault();
		}
	}

	@HostListener('paste', ['$event']) onPaste(event: ClipboardEvent) {
		const clipboardData = event.clipboardData || (window as any).clipboardData;
		const pastedText = clipboardData.getData('text');
		const pattern =  /^[a-zA-Z0-9\s]*$/;
		if (!pattern.test(pastedText)) {
			event.preventDefault();
		}
	}
}
export class Constants {
	public static constant = {
		// [MES].[DIA].[HORA].[MINUTO]
		version: '04.08.12.00',

		actions: {
			create: 'create',
			delete: 'delete',
			display: 'display',
			edit: 'edit',
			list: 'list',
			renovate: 'renovate'
		},

		component: {
			date: 'date',
			day: 'day',
			month: 'month',
			year: 'year',
			FORMATS: {
				parse: {
					dateInput: 'LL',
				},
				display: {
					dateInput: 'DD/MM/YYYY',
					monthYearLabel: 'YYYY',
					dateA11yLabel: 'LL',
					monthYearA11yLabel: 'YYYY',
				},
			}
		},

		dateFormat: {
			DD_MM_YYYY_hyphen: 'DD-MM-YYYY',
			DD_MM_YYYY_hyphen_min: 'dd-MM-yyyy',
			DD_MM_YYYY_hyphen_HH_mm: 'DD-MM-YYYY HH:mm',
			DD_MM_YYYY_hyphen_HH_mm_min: 'dd-MM-yyyy HH:mm',
			DD_MM_YYYY_slash: 'DD/MM/YYYY',
			DD_MM_YYYY_slash_min: 'dd/MM/yyyy',
			DD_MM_YYYY_slash_HH_mm: 'DD/MM/YYYY HH:mm',
			DD_MM_YYYY_slash_HH_mm_min: 'dd/MM/yyyy HH:mm',
			DD_MM_YYYY_slash_HH_mm_ss: 'DD/MM/YYYY HH:mm:ss',
			DD_MM_YYYY_slash_HH_mm_ss_min: 'dd/MM/yyyy HH:mm:ss',
			HH_mm_ss: 'HH:mm:ss',
			HH_mm: 'HH:mm',
			MM_DD_YYYY_hyphen_HH_mm: 'MM-DD-YYYY HH:mm',
			YYYY: 'YYYY',
			YYYY_min: 'yyyy',
			YYYY_MM_DD_hyphen: 'YYYY-MM-DD',
			YYYY_MM_DD_hyphen_min: 'yyyy-MM-dd',
			YYYY_MM_DD_hyphen_HH_mm: 'YYYY-MM-DD HH:mm',
			YYYY_MM_DD_hyphen_HH_mm_min: 'yyyy-MM-dd HH:mm',
			YYYY_MM_DD_hyphen_HH_mm_SS_min: 'yyyy-MM-dd HH:mm:ss',
			YYYY_MM_DD_slash: 'YYYY/MM/DD',
			YYYY_MM_DD_slash_min: 'yyyy/MM/dd',
			YYYY_MM_DD_slash_HH_mm: 'YYYY/MM/DD HH:mm',
			YYYY_MM_DD_slash_HH_mm_min: 'yyyy/MM/dd HH:mm',
			YYYY_MM_DD_slash_HH_mm_ss: 'YYYY/MM/DD HH:mm:ss',
			YYYY_MM_DD_slash_HH_mm_ss_min: 'yyyy/MM/dd HH:mm:ss',
			YYYYMMDD_slash_hh_mm: 'YYYYMMDD_HH[h]mm',
			YYYY_MM_DDTHH_mm_ss_SSSZ: 'YYYY-MM-DDTHH:mm:ss.SSS[Z]',
		},

		error_input: {
			ddr_company: 'DDRNAME_COMPANY',
			email: 'email',
			name: 'NAME',
			own_policy_group_name: 'OWN_POLICY_GROUP_NAME',
			profile_name: 'PROFILE_NAME',
			quantity: 'QUANTITY',
			quantityOnHigh: 'quantityOnHigh',
			standardQuantity: 'standardQuantity',
			state_registration: 'stateRegistration',
			tax_id: 'TAX_ID',
			tax_id_camelcase: 'taxId',
			validity_from_and_to: 'VALIDITY_FROM_AND_TO',
			validity_from_and_to_camelcase: 'ValidityFromAndTo',
		},

		event: {
			checked: 'checked',
			tabChanged: 'tabChanged',
		},

		general: {
			approved: 'Approved',
			blur: 'blur',
			business_unity_id: 'BUSINESS_UNITY_ID',
			clientHeight: 'clientHeight',
			description: 'description',
			discharged: 'Discharged',
			elementRef: '_elementRef',
			equ_Type: 'Equ Type',
			false: 'false',
			fixed_weekdays: 'Fixed Weekdays',
			from: 'from',
			hours_after_cutoff: 'Hours After Cutoff',
			hours_before_cutoff: 'Hours Before Cutoff',
			hours_before_eta: 'Hours Before ETA',
			hours_before_etd: 'Hours Before ETD',
			input: 'input',
			loaded: 'Loaded',
			nativeElement: 'nativeElement',
			office: 'Office',
			omit: 'OMIT',
			scrollHeight: 'scrollHeight',
			send_pgr_ddr: 'SEND PGR/DDR',
			service: 'Service',
			spot: 'Spot',
			status: 'Status',
			suggested: 'Suggested',
			to: 'To',
			true: 'true',
			validity_from: 'VALIDITY_FROM',
			validity_to: 'VALIDITY_TO',
			version: 'version',
			vessel: 'Vessel',
			vessel_schedule:'VesselSchedule',
			voyage: 'Voyage',
		},

		domainTable: {
			domainValues: {
				interior_depot: 'Interior Depot',
				place_of_operation: 'Place of Operation',
				place_of_operation_po: 'Place of Operation (P.O.)',
				office: 'Office',
				yard: 'Yard',
			},

			service_type: {
				dedicated: 'Dedicated',
				dedicated_first: 'D',
				owned: 'Owned',
				owned_first: 'O',
				spot: 'Spot',
				spot_first: 'S',
			},

			modal: {
				barge: 'Barge',
				barge_road: 'Barge/Road',
				rail: 'Rail',
				rail_road: 'Rail/Road',
				road: 'Road',
			},

			business: {
				depot: 'Depot',
				port: 'Port',
				ppt: 'PPT',
				special_services_imd: 'Special Services-IMD',
				terminal: 'Terminal',
				transports_imd: 'Transports-IMD',
			},

			transportType: {
				truck: 'Truck',
				trailer: 'Trailer',
			},
		},

		http: {
			DELETE: 'DELETE',
			PATCH: 'PATCH',
			POST: 'POST',
			root: 'root',
		},

		input_type: {
			toggle: 'toggle',
		},

		messageType: {
			error: 'error',
			info: 'info',
			success: 'success',
			warning: 'warning',
			api_error: 'api_error',
			pricing_architecture_error: 'pricing_architecture_error'
		},

		extensions: {
			XLS: 'xls',
			dotXLS: '.xls',
			xlsx_type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
		},

		status: {
			active: 'A',
			active_long: 'Active',
			deactive: 'DeActive',
			deleted: 'Deleted',
			expired: 'Expired',
			inactive: 'I',
			inactive_long: 'Inactive',
			pending: 'P',
			not_applied: 'NA',
			approved: 'approved',
			cancelled: 'cancelled',
			opened: 'opened',
			rejected: 'rejected',
			waitlisted: 'waitlisted ',
			false: false,
			true: true,
			yes: 'Yes',
			no: 'No',
			outfleeted: 'Outfleeted'
		},

		microServices: {
			additional_fees: 'additional-fees',
			appointment: 'appointment',
			authorizer: 'authorizer',
			product_management: '/product_management',
			costing: 'costing',
			pricing: 'pricing',
			commercial: 'commercial',
			costingModule: 'costingModule',
			customer: 'customer',
			insurance: 'insurance',
			historic: 'historic',
			general_register: 'general-register',
			locality_mapper: 'locality-mapper',
			schedule: 'schedule',
			vendor: 'vendor',
			vessel: 'vessel',
			vessel_schedule: 'vessel-schedule',
			product: 'product',
			adhoc: 'adhoc',
			file: 'file',
			vesselV2: 'vesselV2',
			proforma: 'proforma',
			scheduleMaersk: 'schedules'
		},

		endPoints: {
			additional_fee: 'additional-fee',
			adhoc_routes: 'ad-hoc',
			ad_hoc: 'ad-hoc',
			acr_vessel_situation: 'acr/vessel-situation',
			acr_vessel_name: 'acr/vessel-name',
			acr_vessel_system: 'acr/vessel-system',
			vessel_system: 'vessel-system',
			acr_report_vessel_system: 'acr-report/vessel-system',
			acr_direction: 'acr/direction',
			approved_amount: 'approved-amount',
			approved_limit: 'approved-limit',
			acr_voyage: 'acr/voyage-filter',
			brand_description: 'brand-description',
			business_description: 'business-description',
			business_unity: 'business-unity',
			capacity_barge: 'capacity-barge',
			capacity_customer_road_dedicated: 'capacity-customer-road-dedicated',
			capacity_customer_road_owned: 'capacity-customer-road-owned',
			capacity_customer_road_spot: 'capacity-customer-road-spot',
			capacity_dashboard: 'capacity-dashboard',
			capacity_ddr_road_dedicated: 'capacity-ddr-road-dedicated',
			capacity_ddr_road_owned: 'capacity-ddr-road-owned',
			capacity_ddr_road_spot: 'capacity-ddr-road-spot',
			capacity_rail: 'capacity-rail',
			capacity_road_dedicated: 'capacity-road-dedicated',
			capacity_road_owned: 'capacity-road-owned',
			capacity_road_spot: 'capacity-road-spot',
			category: 'category',
			certification: 'certification',
			certification_description: 'certification-description',
			characteristic: 'product/characteristic',
			checklist: 'checklist',
			checklist_name: 'checklist-name',
			cities_and_states: 'cities-and-states',
			city: 'city',
			city_name: 'city-name',
			cmd_id: 'cmd-id',
			commercial: 'commercial',
			commodity: 'commodity',
			company: 'company',
			company_name: 'company-name',
			contact: 'contact',
			contract_cost: 'contract-cost',
			contract_cost_road_dedicated: 'contract-cost-road-dedicated',
			contract_cost_transport_costing: 'contract-cost-transport-costing',
			contract_status_description: 'contract-status-description',
			corporate_group: 'corporate-group',
			corporate_group_name: 'corporate-group-name',
			costing: 'costing',
			country: 'country',
			countryV2: 'port-terminals/country-list',
			country_name: 'country-name',
			current_date_time: 'current-date-time',
			customer: 'customer',
			customer_cmd_id: 'customer-cmd-id',
			customer_company_name: 'customer-company-name',
			customer_name: 'customer-name',
			customer_operational: 'customer-operational',
			customer_sub_group: 'customer-sub-group',
			customer_taxid: 'customer-taxid',
			day_week: 'day-week',
			ddr: 'ddr',
			ddr_associate_customer: 'ddr-associate-customer',
			ddr_associate_vendor_business_unity: 'ddr-associate-vendor-business-unity',
			ddr_broker: 'ddr-broker',
			ddr_checklist: 'ddr-checklist',
			ddr_name_company_name_validity: 'ddr-name-company-name-validity',
			ddr_operation: 'ddr-operation',
			ddr_validate_checklist: 'ddr-validate-checklist',
			department: 'department',
			department_description: 'department-description',
			department_name: 'department-name',
			edi_integration_description: 'edi-integration-description',
			exception: 'exception',
			exchange_rate: 'exchange',
			disclaimer: 'disclaimer',
			expired_ddr_for_vendor: 'expired-ddr-for-vendor',
			facility: 'facility',
			facility_type: 'facility-type',
			facility_type_name: 'facility-type-name',
			group_name: 'group-name',
			insurer: 'insurer',
			job_order_cost: 'job-order-cost',
			list_of_rules: 'preferredroute/list-of-rules',
			modal_name: 'modal-name',
			modal_service_type_name: 'modal-service-type-name',
			new_port: 'new-port',
			new_country: 'new-country',
			new_state: 'new-state',
			operation_type_description: 'operation-type-description',
			operational_requirement: 'operational-requirement',
			operational_requirement_name: 'operational-requirement-name',
			own_policy: 'own-policy',
			own_policy_broker: 'own-policy-broker',
			own_policy_group: 'own-policy-group',
			own_policy_group_associate_vendor: 'own-policy-group-associate-vendor',
			own_policy_group_checklist: 'own-policy-group-checklist',
			own_policy_group_vendor_checklist: 'own-policy-group-vendor-checklist',
			own_polity_associate_own_policy_group: 'own-polity-associate-own-policy-group',
			pgr: 'pgr',
			pgr_to_ddr: 'pgr-to-ddr',
			pgr_name: 'pgr-name',
			polity_disassociate_policy_group: 'polity-disassociate-policy-group',
			port_terminals: 'port-terminals',
			preferred_route: 'preferredroute',
			pricing: 'pricing',
			product_linked: 'product/linked',
			product: 'product',
			product_characteristic: 'product/characteristic',
			product_attachment: '/product/attachment',
			product_instance: 'product/instance',
			product_instance_bulk: 'product/instance-bulk',
			product_base: 'product/characteristic/base',
			product_services: 'product/special-requirement/products',
			product_check_duplicate: 'product/special-requirement/duplicate-check',
			product_save_services: 'product/special-requirement/create',
			profile: 'profile',
			profile_management: 'profile-management',
			profile_name: 'profile-name',
			region: 'region',
			region_role: 'region-role',
			special_requirement: 'product/special-requirement',
			special_conditions: 'special-conditions',
			state: 'state',
			state_country: 'state/country',
			state_name: 'state-name',
			status: 'status',
			subcategory: 'subcategory',
			transport: 'transport',
			transport_costing: 'transport-costing',
			transport_description: 'transport-description',
			transport_modal: 'transport-modal',
			transport_type_name: 'transport-type-name',
			user: 'user',
			userlanguage: 'userlanguage',
			vehicle_plate_associate_port_terminal: 'vehicle-plate-associate-port-terminal',
			vehicle_plate_provisory: 'vehicle-plate-provisory',
			vendor: 'vendor',
			vendor_company_name: 'vendor-company-name',
			vendor_customer_name: 'vendor-customer-name',
			vendor_facility: 'vendor-facility',
			vendor_operational: 'vendor-operational',
			vendor_taxid: 'vendor-taxid',
			week_day_description: 'week-day-description',
			port: 'port',
			proforma_list: 'proforma/list',
			proforma: 'proforma',
			schedule: 'schedule',
			list: 'list',
			vessel_list: 'vessel',
			vessel_group_name: 'group-vessel-name',
			vessel_group_short_name: 'group-vessel-short-name',
			vessel_group_system: 'group-vessel-system',
			working_time: 'working-time',
			payment_type: 'payment-type',
			condition_status: 'condition-status',
			validity_from: 'validity-from',
			validity_to: 'validity-to',

		},

		direction: {
			n: 'N',
			s: 'S',
			e: 'E',
			w: 'W',
			north: 'North',
			south: 'South',
			east: 'East',
			west: 'West'
		},

		ambient: {
			local: 'local',
			cdt: 'cdt',
			preprod: 'preprod',
			uat: 'uat',
			prod: 'prod',
			eyt: 'eyt',
			qa: 'qa'
		},

		locale: {
			en_us: 'en-us',
			pt_br: 'pt-br',
			es_es: 'es-es',
		}
	};
}
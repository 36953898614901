import { Component, Input } from '@angular/core';
import { LoadService } from '../../service/load.service';

@Component({
  selector: 'app-forced-loading',
  templateUrl: './forced-loading.component.html',
  styleUrls: ['./forced-loading.component.scss']
})
export class ForcedLoadingComponent {
  @Input() public isLoading: boolean = false;

  constructor(private _loaderService: LoadService) {}
}

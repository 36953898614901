import { Pipe, PipeTransform } from '@angular/core';
import { Labels } from 'src/internationalization/labels/labels';

@Pipe({
	name: 'direction'
})
export class DirectionPipe implements PipeTransform {
	transform(value: string): any {
		if (value) {
			const labels = Labels.getLabels();
			value = value.toUpperCase();
			if (value == "S") {
				value = labels.directions.south;
			} else if (value == "N") {
				value = labels.directions.north;
			} else if (value == "E") {
				value = labels.directions.east;
			} else if (value == "W") {
				value = labels.directions.west;
			}
		}

		return value;
	}
}
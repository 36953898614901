import { Component, OnInit, Input, ViewChild, Output, EventEmitter, SimpleChanges, OnDestroy } from '@angular/core';
import { Labels } from 'src/internationalization/labels/labels';
import { Subscription, timer } from 'rxjs';
import { ObjectFilterBaseService } from '../../service/object-filter-base.service';
import { CoreUtil } from '../../core-util';
import { Icons } from 'src/icons';
import { Constants } from 'src/constants';

@Component({
	selector: 'app-dropdown-box-list',
	templateUrl: './dropdown-box-list.component.html',
	styleUrls: ['./dropdown-box-list.component.scss']
})
export class DropdownBoxListComponent implements OnInit, OnDestroy {
	@ViewChild('hiddenInput', { static: false }) hiddenInput;
	@ViewChild('searchInput', { static: false }) searchInput;
	@ViewChild('optionAll', { static: false }) optionAll;

	@Input() category: string; // defines logo, name, target grid list screen
	@Input() disabled: boolean = false;
	@Input() displayProperty: string;
	@Input() arrayData: any[];
	@Input() placeholder: string;
	@Input() placeholderFixedTop: boolean = true;
	@Input() microService: string;
	@Input() endPoint: string;
	@Input() queryParams: string;
	@Input() icon: string;
	@Input() showAll = true;
	@Input() displayMode = false;
	@Input() idProperty = 'id';
	@Input() auxDisplayProperty: string;
	@Input() pipe: string;
	@Input() showPlaceholderAsDisplay = false;
	@Input() allSelectionMode = false;
	@Input() allSelected = false;
	@Input() overlayAbsolute = false;
	@Input() multiSelect = true;

	@Output() valueChange = new EventEmitter();
	@Output() allSelectedChange = new EventEmitter();

	public _labels = Labels.getLabels();
	public _icons = Icons;
	public _buildingIcon = this._icons.icon.building;
	public _vendorInformationIcon = this._icons.icon.vendor_information;
	public _microServices = Constants.constant.microServices;
	public _insurerIcon = this._icons.icon.insurer;
	public _value: any;
	public _search = '';
	public _selectAll = false;
	public _showSelectAll = true;
	public _selectAllValue = {
		id: -1
	};

	private _unselectAll = false;
	private _selectAllClicked = false;
	private _dispatcher: Subscription;
	private _subscriptionList: Subscription[];
	private _inputEvent = new Event('input', {
		bubbles: true,
		cancelable: true,
	});

	@Input()
	set value(value: any[]) {
		if (value && this.multiSelect) {
			this._value = [];
			for (const v of value) {
				if (v.id != -1) {
					if (this.arrayData && this.auxDisplayProperty) {
						const find = this.arrayData.find(a => a.id == v.id);
						if (find) {
							v[this.auxDisplayProperty] = find[this.auxDisplayProperty];
						}
					}
					this._value.push(v);
				}
			}
		} else {
			if (Array.isArray(value)) {
				this._value = value[0];
			} else {
				this._value = value;
			}
		}

	}

	constructor(private service: ObjectFilterBaseService) { }

	ngOnInit() {
		if (this.multiSelect) {
			this._value = [];
		}
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes && changes.endPoint && changes.endPoint.currentValue) {
			this.getList(this.queryParams);
		}

		if (changes && changes.displayProperty && changes.displayProperty.currentValue) {
			this._selectAllValue[this.displayProperty] = this._labels.component.select_all;
		}

		if (changes && changes.auxDisplayProvalperty && changes.auxDisplayProperty.currentValue) {
			this._selectAllValue[this.auxDisplayProperty] = this._labels.component.select_all;
		}
	}

	getList(queryParams: string) {
		const response = resp => {
			if (resp && resp.data) {
				this.arrayData = resp.data;

				if (this.arrayData && this.auxDisplayProperty) {
					this.arrayData.forEach((value) => value[this.auxDisplayProperty] = value[this.displayProperty]);
				}
			}
		};

		const subscription = this.service
			.getObjectFilterList(this.microService, this.endPoint, this._search, queryParams)
			.subscribe(response);
		this._subscriptionList = CoreUtil.incrementSubscriptionList(this._subscriptionList, subscription);
	}

	callGetList() {
		if (this._dispatcher) {
			this._dispatcher.unsubscribe();
		}

		this._dispatcher = timer(150).subscribe(() => {
			this.getList(this.queryParams);
		});
		this._subscriptionList = CoreUtil.incrementSubscriptionList(this._subscriptionList, this._dispatcher);
	}

	cleanSelectAll() {
		this.unselectAll(this._value);
	}

	splitProperty(obj: any) {

		return CoreUtil.getSplittedProperty(obj, this.auxDisplayProperty ? this.auxDisplayProperty : this.displayProperty);
	}

	unselectAll(event) {
		const all = event.find(v => v.id == -1);
		if (all) {
			event.splice(event.indexOf(all), 1);

			this._unselectAll = false;
			this._selectAll = false;
		}
	}

	triggerHiddenInput() {
		this.hiddenInput.nativeElement.dispatchEvent(this._inputEvent);
	}

	change(event) {
		if (!this.multiSelect) {
			this._value = event;
			this.valueChange.emit(this._value);
		}
		this.triggerHiddenInput();
		this.showSelectAll();
		this.changeAllSelected(false);

		setTimeout(() => {
			if (this._selectAll) {
				if (!this._unselectAll) {
					if (this._search || !this.allSelectionMode) {
						event = [{ id: -1, description: this._labels.component.select_all }];

						for (const data of this.arrayData) {
							event.push(Object.assign({}, data));
						}
					} else if (event.filter(e => e.id != -1).length > 0) {

						if (this._value.filter(v => v.id != -1).length == 0) {
							this.unselectAll(event);
						} else {
							event = [];
						}
					}
				} else {
					this.unselectAll(event);
				}
			} else if (this._selectAllClicked) {
				this._selectAllClicked = false;
				event = [];
			}
			if (this.multiSelect) {
				this._value = event.filter(e => e.id != -1);
			} else {
				this._value = event;
			}
			this.valueChange.emit(this._value);
		});
	}

	selectClick(value) {
		if (value && value.id == -1) {

			this._selectAll = this.optionAll && this.optionAll.selected;

			if (!this._selectAll) {
				const selectAll = this._value.find(v => v.id == -1);
				if (selectAll) {
					this._selectAllClicked = true;
				}
			} else if (!this._search) {
				this.changeAllSelected(true);
			}
		} else {
			this._unselectAll = this._selectAll;
		}
	}

	changeAllSelected(value) {
		this.allSelected = value;
		this.allSelectedChange.emit(this.allSelected);
	}

	hideSelectAll() {
		this._selectAll = false;
		this._showSelectAll = false;
	}

	showSelectAll() {
		if (!this._showSelectAll) {
			this._selectAll = true;
		}

		this._showSelectAll = true;
	}

	open(isOpened) {
		if (isOpened) {
			setTimeout(() => {
				this.searchInput.nativeElement.focus();
			}, 1);
		} else {
			this._search = '';
			this.callGetList();
		}
	}

	ngOnDestroy() {
		if (this._dispatcher) {
			this._dispatcher.unsubscribe();
		}

		CoreUtil.unsubscribeSubscriptionList(this._subscriptionList);
	}

	getIconDir() {
		if (this.icon != undefined && this.icon != '') {
			switch (this.category) {
				case 'customer':
					this.icon = this._buildingIcon;
					break;
				case 'vendor':
					this.icon = this._vendorInformationIcon;
					break;
				case 'insurance':
					this.icon = this._insurerIcon;
					break;
			}
		}

		return this._icons.getIconPath(this.icon);
	}

	showAllSelectOrVal(val, value, i: number) {
		if (i === 0 && !this.showAll && value && this.arrayData && value.length >= this.arrayData.length) {
			return this._labels.component.all_selected;
		} else if (val.id > 0) {
			return this.splitProperty(val);
		}
	}

	showSelectDrop(value) {
		return this.splitProperty(value);
	}

}
import { Component } from '@angular/core';
import {
	NavigationStart,
	Router,
	RoutesRecognized
} from '@angular/router';
import { UrlHistoryService } from './core/service/urlHistory.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent {
	constructor(private router: Router, private urlHistory: UrlHistoryService
	) {}

	title = 'aliance';

	ngOnInit() {
		this.router.events.subscribe((e: any) => {
			if (e instanceof NavigationStart) {
				this.urlHistory.previousUrl$.next(this.urlHistory.currentUrl$.value);
				this.urlHistory.currentUrl$.next(e.url);
			}

			if (
				e instanceof RoutesRecognized &&
				e.url === '/' &&
				this.urlHistory.currentUrl$.value === '/' &&
				this.urlHistory.previousUrl$.value !== '/' &&
				this.urlHistory.previousUrl$.value !== '/login'
			) {
				this.router.navigateByUrl(this.urlHistory.previousUrl$.value)
			}
		});
	}
}

//16:55

import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Labels } from 'src/internationalization/labels/labels';

@Component({
  selector: 'app-table-dropdown-number',
  templateUrl: './table-dropdown-number.component.html',
  styleUrls: ['./table-dropdown-number.component.scss']
})
export class TableDropdownNumberComponent implements OnInit, OnChanges {

  @Input() displayProperty: string = '';
  @Input() arrayHeader: string[] = [];
  @Input() itensList: any[] = [];
  @Input() dropdownItensNumber: number = 1;
  @Input() disabledDropdowns: boolean = false;
  @Output() dropdownChange = new EventEmitter<any>();

  blockNumberList: number[] = [];
  labels = Labels.getLabels();
  form: FormGroup = this.fb.group({
    dropdownList: this.fb.array([])
  });

  constructor(
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.createForm();
    this.createBlockNumberList();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.createForm();
    this.createBlockNumberList();
  }

  createForm() {
    this.form = this.fb.group({
      dropdownList: this.fb.array([])
    });
    if (this.itensList) {
      this.itensList.forEach(item => {
        if (item.blockNumber) {
          this.dropdownFormList.push(this.fb.control(item.blockNumber.toString()));
          this.onDropdownChange(item);
        } else {
          this.dropdownFormList.push(this.fb.control(''));
        }
      })
    }
  }

  onDropdownChange(item) {
    const selected = { dropdownList: this.dropdownFormList.value, item: item }
    this.dropdownChange.emit(selected);
    if (this.itensList) {
      this.itensList.forEach((item, i) => {
        if (item.blockNumber) {
          if ((this.form.get('dropdownList') as FormArray).length > i) {
            (this.form.get('dropdownList') as FormArray).at(i).setValue(item.blockNumber.toString());
            this.dropdownChange.emit(selected);
          }
        }
      })
    }
  }

  get dropdownFormList(): FormArray {
    return this.form.get('dropdownList') as FormArray;
  }

  createBlockNumberList() {
    this.blockNumberList = [];
    for (let i = 0; i < this.dropdownItensNumber; i++) {
      this.blockNumberList.push(i);
    }
  }

}

import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
	selector: 'app-appointment-brand-list',
	templateUrl: './appointment-brand-list.component.html',
	styleUrls: ['./appointment-brand-list.component.scss']
})

export class AppointmentBrandListComponent implements OnInit, ILanguageConfig {
	@Input() array: { image: string, imageOff: string, imageOn: string, checked: boolean, disabled: boolean, brand: string }[] = [];
	@Output() onChange = new EventEmitter();

	_languageData;
	_iconDir = './../../../../../assets/logo/';

	constructor() { }

	ngOnInit() {
		this.getLanguageFile();
		this.fakeArray();
		this.uncheckAll();
		this.check(1);
	}

	fakeArray() {
		this.array = [
			{ image: '', imageOff: this._iconDir + 'alianca_logo_gray.svg', imageOn: this._iconDir + 'alianca_logo_rgb.svg', checked: false, disabled: true, brand: String(this._languageData.cabotageAliance)},
			{ image: '', imageOff: this._iconDir + 'hamburg_sud_logo_gray.svg', imageOn: this._iconDir + 'hamburg_sud_logo_rgb.svg', checked: true, disabled: false, brand: String(this._languageData.deepSeaHamburgSud)},
			{ image: '', imageOff: this._iconDir + 'maersk-off.svg', imageOn: this._iconDir + 'maersk-on.svg', checked: false, disabled: true, brand: String(this._languageData.deepSeaMaersk)},
			{ image: '', imageOff: this._iconDir + 'alianca-whiteflag-off.svg', imageOn: this._iconDir + 'alianca-whiteflag-on.svg', checked: false, disabled: true, brand: String(this._languageData.whiteFlagHamburgSud)},
			{ image: '', imageOff: this._iconDir + 'maersk-whiteflag-off.svg', imageOn: this._iconDir + 'maersk-whiteflag-on.svg', checked: false, disabled: true, brand: String(this._languageData.whiteFlagMaersk)}
		];
	}

	getLanguageFile() {
		this._languageData = require('./language.json').en;
	}

	onSwichChange(changes) {
		this.uncheckAll();
		if (changes.checked) {
			this.check(changes.index);
		}
		this.onChange.emit({index: changes.index});
	}

	disableAll() {
		this.array.forEach((value, index) => {
			this.disable(index);
		});
	}

	disable(index: number) {
		if (this.array && this.array[index]) {
			this.array[index].disabled = true;
			this.array[index].checked = false;
			this.array[index].image = this.array[index].imageOff;
		}
	}

	uncheckAll() {
		if (this.array) {
			for (const item of this.array) {
				item.checked = false;
				item.image = item.imageOff;
			}
		}
	}

	check(index: number) {
		this.uncheckAll();
		if (this.array && this.array[index]) {
			this.array[index].disabled = false;
			this.array[index].checked = true;
			this.array[index].image = this.array[index].imageOn;
		}
	}
}
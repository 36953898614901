import { Directive, Input, Output, ElementRef, SimpleChanges, HostListener, EventEmitter, forwardRef } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';
import { Colors } from 'src/colors';

@Directive({
	selector: '[appNumber]',
	providers: [
		{ provide: NG_VALIDATORS, useExisting: forwardRef(() => NumberDirective), multi: true }
	]
})
export class NumberDirective implements Validator {
	@Input() isNumber = false;
	@Input() isInteger = false;
	@Input() ngModel: string;
	@Input() validation: any;
	@Output() ngModelChange = new EventEmitter();

	private control: AbstractControl;
	private lastValue: string;

	constructor(private element: ElementRef) { }

	ngOnChanges(changes: SimpleChanges) {
		if (changes.ngModel && changes.ngModel.currentValue && this.ngModel != this.lastValue) {
			const event = {target: {value: changes.ngModel.currentValue}};
			this.onInput(event);
		}
	}

	validate(c: AbstractControl): { [key: string]: any } {
		this.control = c;
		this.element.nativeElement.parentElement.style.backgroundColor = '';

		return null;
	}

	@HostListener( 'input', ['$event'] )
	onInput( $event: any ) {
		let value = $event.target.value;
		if (value && value.toString()) {
			value = value.toString().replace(/\D/g, '');
			this.doChange(value);
		}
	}

	doChange(value) {
		value = value.replace(/\D/g, '');

		if (this.isNumber) {
			value = Number(value);
		}

		if (this.isInteger) {
			while (Number(value) > Number(2147483647)) {
				value = value.toString().substring(0,value.length - 1);
			}
		}

		this.ngModel = value;
		setTimeout(() => {
			this.lastValue = value;
			this.ngModelChange.emit(value);
			this.element.nativeElement.value = value;
		}, 15);
	}

	@HostListener( 'blur', ['$event'] )onBlur( $event: any ) {
		if (this.control && this.control.value != undefined) {
			if (this.validation && !this.validation()) {
				this.element.nativeElement.parentElement.style.backgroundColor = Colors.color.red_error_background;
				this.control.setErrors({number: false});

				return;
			}

			this.element.nativeElement.parentElement.style.backgroundColor = '';

			this.control.setErrors(null);
		}
	}
}
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'time'
})
export class TimePipe implements PipeTransform {
	transform(value: string): any {
		if (value) {
			const array = value.split(':');
			value = array[0] + 'h' + array[1];
		}

		return value;
	}
}
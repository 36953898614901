import { Component, Input, OnInit } from '@angular/core';
import { Icons } from 'src/icons';
import { Labels } from 'src/internationalization/labels/labels';

@Component({
  selector: 'app-loading-x-discharge',
  templateUrl: './loading-x-discharge.component.html',
  styleUrls: ['./loading-x-discharge.component.scss']
})
export class LoadingXDischargeComponent implements OnInit {

  @Input() data;
	@Input() perVvd: boolean = false;
	@Input() showColumnLoading: boolean = false;
	@Input() showColumnDischarge: boolean = false;
	@Input() showColumnLoadingNext: boolean = false;
	@Input() showColumnDischargeNext: boolean = false;
  public _labels = Labels.getLabels();

	_iconDir = './../../../../../assets/icon/';
	_icons = {
		add: this._iconDir + 'plus-blue.svg',
		hide: this._iconDir + 'hide_teus_tons.svg'
	};

	constructor() { }

  ngOnInit(): void {
  }

	showDischarge() {
		this.showColumnDischarge = !this.showColumnDischarge;
	}

	showLoading() {
		this.showColumnLoading = !this.showColumnLoading;
	}

	showDischargeNext() {
		this.showColumnDischargeNext = !this.showColumnDischargeNext;
	}

	showLoadingNext() {
		this.showColumnLoadingNext = !this.showColumnLoadingNext;
	}
}
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FileAttached } from 'src/app/resource/page/product-management/products/create-products/model/file-attached.model';
import { Attachment } from 'src/app/resource/page/product-management/products/create-products/model/product.model';
import { ProductFormTypeEnum } from 'src/app/resource/page/product-management/products/model/product-form-type.enum';
import { Labels } from 'src/internationalization/labels/labels';
import { Messages } from 'src/internationalization/messages/messages';
import { ConfirmService } from '../../service/confirm.service';

@Component({
	selector: 'app-input-multiple-files',
	templateUrl: './input-multiple-files.component.html',
	styleUrls: ['./input-multiple-files.component.scss']
})
export class InputMultipleFilesComponent implements OnInit {
	@ViewChild('fileUpload', { static: false }) fileUpload: ElementRef;
	@Input() disabled;
	@Input() myFiles = [];
	@Output() filesEmitter = new EventEmitter<any[]>();
	@Output() fileDownload = new EventEmitter<any>();

	public _labels = Labels.getLabels();
	public _messages = Messages.getMessages();
	public formType;
	public enumFormType = ProductFormTypeEnum;
	public fileNameIterator = 1;


	constructor(
		private confirmService: ConfirmService
	) { }

	onFileSelected(event) {
		if (event && event.target.files) {
			for (var i = 0; i < event.target.files.length; i++) {
				let sameDocument = false;
				const file = event.target.files[i];

				const value = this.myFiles.filter(filteredFile => {
					if(file.name === filteredFile.name) {
						sameDocument = true;
						return sameDocument
					}
				});

				if (sameDocument) {
					this.confirmService.open(undefined, this._labels.messageTitle.alert, this._messages.question.Rename_file + ` ${file.name}?`,
						() => {
							const fileWithSameName = this.myFiles.filter(e => file.name === e.name)[0];
							const extensionFile = this.getExtension(fileWithSameName.name);

							const newFileName = this.getFilenameCompared(file.name) + extensionFile;
							const newFile = new File([file], newFileName, { type: file.type });
							this.myFiles.push(newFile);
							this.filesEmitter.emit(this.myFiles);
							this.fileUpload.nativeElement.value = '';
						}, () => {
						//function not currently used
						}

					);

				} else {
					this.myFiles.push(file);
					this.filesEmitter.emit(this.myFiles);
					this.fileUpload.nativeElement.value = '';
				}
			}
		}
	}

	getFilenameCompared(fileName: string): string {
		let counter = 1;
		const fileNameNoExtension = this.getFilename(fileName);
		let fileWithSameName = this.myFiles.filter(e => {
			const fileNameNoExtensionFilter = this.getFilename(e.name);
			return `${fileNameNoExtension}(${counter})` == `${fileNameNoExtensionFilter}`;
		})[0];
		while (fileWithSameName) {
			counter++;
			fileWithSameName = this.myFiles.filter(e => {
				const fileNameNoExtensionFilter = this.getFilename(e.name);
				const fileNameNoExtensionWithCounter = `${fileNameNoExtension}(${counter})`;
				return fileNameNoExtensionWithCounter == fileNameNoExtensionFilter;
			})[0];
		}
		return `${fileNameNoExtension}(${counter})`;
	}

	getFilename(fileName: string): string {
		const extensionFile = fileName.substr(-4);
		if (extensionFile.includes('.')) {
			return fileName.substring(0, fileName.length - 4);
		} else {
			return fileName.substring(0, fileName.length - 5);
		}
	}

	getExtension(fileName: string): string {
		const extensionFile = fileName.substr(-4);
		if (extensionFile.includes('.')) {
			return extensionFile;
		} else {
			return fileName.substr(-5);
		}
	}

	removeFile(fileToRemove) {
		if (!this.disableButtons()) {
			this.myFiles = this.myFiles.filter(file => fileToRemove != file);
			this.filesEmitter.emit(this.myFiles);
			this.fileUpload.nativeElement.value = '';

			}
	}

	formatBytes(bytes, decimals = 2) {
		if (bytes === 0) {
			return '0 Bytes';
		}
		const k = 1024;
		const dm = decimals <= 0 ? 0 : decimals;
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
		const i = Math.floor(Math.log(bytes) / Math.log(k));
		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + sizes[i];
	}

	ngOnInit(): void {
		this.getData();
	}

	getData() {
		const dataString = localStorage.getItem('productFormType');
		this.formType = JSON.parse(dataString);
	}

	disableButtons(): boolean {
		if (this.formType === ProductFormTypeEnum.VIEW) {
			return true;
		}
	}

	downloadFile(fileAttached: FileAttached) {
		if (fileAttached.uri
			&& (this.formType === ProductFormTypeEnum.EDIT || this.formType === ProductFormTypeEnum.VIEW)) {
			this.fileDownload.emit(fileAttached);
		}
	}
}

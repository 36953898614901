import { Directive, Input, ElementRef, Renderer2, SimpleChanges } from '@angular/core';

@Directive({
	selector: '[addButton]'
})
export class AddButtonDirective {
	@Input() size: string;

	constructor(private element: ElementRef, private renderer: Renderer2) {
		renderer.addClass(element.nativeElement, 'btn');
		renderer.addClass(element.nativeElement, 'btn-outline-success');
		renderer.addClass(element.nativeElement, 'custom-button');
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.size && changes.size.currentValue) {
			this.renderer.addClass(this.element.nativeElement, changes.size.currentValue + '-button');
		}
	}
}
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'numberDecimal'
})
export class NumberDecimalPipe implements PipeTransform {
	// local and currency = in agreement ISO
	transform(value: any, decimalPlaces = 2, local: string,
			isMonetary = false, currency: string): any {

		if ((value != '' || value == 0) && value != undefined) {
			value = Intl.NumberFormat(local, {
				style: 'decimal',
				maximumFractionDigits: decimalPlaces,
				minimumFractionDigits: decimalPlaces
			}).format(value);

			value = value.toString().replace(/\D/g, '');

			if (value.length > decimalPlaces) {
				const integer = value.substring(0,value.length - decimalPlaces);
				const decimal = value.substring(value.length - decimalPlaces,value.length);
				value = parseFloat(integer + '.' + decimal);
			}

			if (isMonetary) {
			value = value.toLocaleString(local, {
				style: 'currency',
				currency
			});
			} else {
			value = value.toLocaleString(local, {
				style: 'decimal',
				maximumFractionDigits: decimalPlaces,
				minimumFractionDigits: decimalPlaces
			});
			}
		} else {
			value = '';
		}

		return value;
	}
}
import { Injectable } from '@angular/core';
import { HttpClientService } from './http-client.service';
import { Pagination } from '../dto/pagination';
import {query} from '@angular/animations';

@Injectable({
	providedIn: 'root'
})
export class ObjectFilterBaseService {
	private _pagination = new Pagination();

	constructor(private httpClientService: HttpClientService) {
		this._pagination.pageSize = 20;
	}

	setPagination(pagination) {
		this._pagination = pagination;
	}

	getObjectFilterList(microService: string, endPoint: string, strSearch: string, queryParams, hasPagination: boolean = true ) {
		if(hasPagination) return this.httpClientService.getList(microService, endPoint, strSearch, this._pagination, undefined, undefined, undefined, queryParams);
		else return this.httpClientService.getList(microService, endPoint, strSearch, undefined, undefined, undefined, undefined, queryParams);
	}

	getObjectFilterListGroup(microService: string, endPoint: string, strSearch: string, queryParams = null) {
		return this.httpClientService.getListGroup(microService, endPoint, strSearch, this._pagination, undefined, undefined, undefined, queryParams);
	}
}
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {UploadFileReactiveComponent} from './upload-file-reactive.component';
import {ReactiveFormsModule} from '@angular/forms';


@NgModule({
  declarations: [UploadFileReactiveComponent],
	imports: [CommonModule, ReactiveFormsModule],
	exports: [UploadFileReactiveComponent]
})
export class UploadFileReactiveModule { }

import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { MatMenu } from '@angular/material';

@Component({
	selector: 'app-notification-dropdown',
	templateUrl: './notification-dropdown.component.html',
	styleUrls: ['./notification-dropdown.component.scss']
})
export class NotificationDropdownComponent implements OnInit {
	@ViewChild(MatMenu, { static: false }) menu: MatMenu;
	@Input() notificationList;
	constructor() { }

	ngOnInit() { }
}
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Icons } from 'src/icons';
import { Labels } from 'src/internationalization/labels/labels';
import { Messages } from 'src/internationalization/messages/messages';

@Component({
  selector: 'app-acr-confirm',
  templateUrl: './acr-confirm.component.html',
  styleUrls: ['./acr-confirm.component.css']
})
export class AcrConfirmComponent implements OnInit {

	public _labels = Labels.getLabels();
	public _messages = Messages.getMessages();
	public _icons = Icons;
	public widthEl: string;
	public heightEl: string;
	public maxHeightEl: string;
	public scroll: 'hidden' | 'scroll' = 'hidden'; 


	constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<AcrConfirmComponent>) {
		this._defineSizePercent(20);
		this._verifyIfEnableScroll();
	 }

	ngOnInit() {
	 }

	close() {
		this.dialogRef.close();
	}

	confirm() {
		this.dialogRef.close({ confirm: true });
	}

	private _verifyIfEnableScroll(){
		if(this.data &&  this.data.message.length > 410){
			this.scroll = 'scroll';
		}
		console.log(this.data.message.length)
	}

		private _defineSizePercent(percent: number){
		let percentage =  (percent / 100);
		let sizeText = 125;
		this.widthEl = (parseFloat(this.dialogRef._containerInstance._config.width.replace("px", "")) * percentage) + "px";
		this.maxHeightEl = (parseFloat(this.dialogRef._containerInstance._config.maxHeight.toString()) - sizeText) + "px" ;
	}
}
import { Directive, ElementRef, Input, Output, EventEmitter, HostListener, SimpleChanges, OnDestroy } from '@angular/core';
import { timer, Subscription } from 'rxjs';
import { CoreUtil } from '../core-util';

@Directive({
	selector: '[appOffsetWidth]'
})
export class OffsetWidthDirective implements OnDestroy {
	@Input() maxWidth;
	@Input() minWidth;
	@Input() width;

	@Output() widthChange = new EventEmitter();

	private _time: Subscription;
	private _count = 0;
	private _subscriptionList: Subscription[];

	constructor(private element: ElementRef) { }

	ngAfterViewInit() {
		this.schedule();
	}

	@HostListener('window:resize')
	onResize() {
		this.schedule();
	}

	ngOnChanges(change: SimpleChanges) {
		this.schedule();
	}

	schedule() {
		if (this._time) {
			this._time.unsubscribe();
		}

		this._time = timer(1).subscribe(() => {
			if (this.width != this.element.nativeElement.offsetWidth && this._count < 20) {
				this._count++;

				let width = this.element.nativeElement.offsetWidth;

				if (this.minWidth && width < this.minWidth) {
					width = this.minWidth;
				}

				if (this.maxWidth && width > this.maxWidth) {
					width = this.maxWidth;
				}

				this.width = width;
				this.widthChange.emit(this.width);
				this.schedule();
			} else {
				this._count = 0;
			}
		});

		this._subscriptionList = CoreUtil.incrementSubscriptionList(this._subscriptionList, this._time);
	}

	ngOnDestroy() {
		CoreUtil.unsubscribeSubscriptionList(this._subscriptionList);
	}
}
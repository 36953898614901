import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'adhoc_date'
})
export class AdhocDatePipe implements PipeTransform {
	transform(value: string): any {
		if (value && value.length > 10) {
			value = value.substring(0, 10);

			const array = value.split('-');

			value = array[2] + '/' + array[1] + '/' + array[0];

	}
		return value;
	}
}
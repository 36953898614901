import { Directive, ElementRef, Input, Output, EventEmitter, HostListener } from '@angular/core';

@Directive({
	selector: '[appTime]'
})
export class TimeDirective {
	@Input() ngModel: string;
	@Output() ngModelChange = new EventEmitter();

	constructor(private element: ElementRef) { }

	@HostListener( 'input', ['$event'] )
	onInput( $event: any ) {
		let value = $event.target.value;
		if (value) {
			value = value.replace(/\D/g, '');

			if (value.length > 2) {
				value = value.substring(0,4).replace(/^(\d{2})?(\d{2})?/, '$1:$2');
			}

			this.doChange(this.validate(value));
		}
	}

	validate(value) {
		switch (value.length + '') {
			case '1':
				if (Number(value.substring(0,1)) > 2) {
					value = '';
				}
				break;
			case '2':
				if (Number(value.substring(0,1)) > 2) {
					value = '';
				} else if (Number(value.substring(0,2)) > 23) {
					value = value.substring(0,1);
				}
				break;
			case '4':
			case '5':
				if (Number(value.substring(0,1)) > 2) {
					value = '';
				} else if (Number(value.substring(0,2)) > 23) {
					value = value.substring(0,1);
				} else if (Number(value.substring(3,4)) > 5) {
					value = value.substring(0,2);
				}
				break;
		}

		return value;
	}

	doChange(value) {
		setTimeout(() => {
			this.element.nativeElement.value = value;
			this.ngModelChange.emit(value);
		}, 15);
	}

	@HostListener( 'blur', ['$event'] )onBlur( $event: any ) {
		const value = $event.target.value;
		if (!value || value.replace(/\D/g, '').length < 4) {
			this.doChange('');
		}
	}
}
import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
	selector: '[restrictNegativeNumbers]'
})
export class restrictNegativeNumbers {
	constructor(private el: ElementRef, private renderer: Renderer2) {}

	@HostListener('keydown', ['$event']) onKeyDown(event): void {
		if (event.key === '-') {
			event.preventDefault();
		}
	}

	@HostListener('input', ['$event']) onInput(event): void {
		const inputValue: string = event.target.value;
		const formattedValue = inputValue.replace('-', '');
		
		// Define o valor formatado no elemento de entrada
		this.renderer.setProperty(this.el.nativeElement, 'value', formattedValue);
	}
}
import { Directive, Input, ElementRef, Renderer2, HostListener } from '@angular/core';

@Directive({
	selector: '[customTimeFormat]'
})
export class CustomTimeFormatDirective {
	@Input() use24Hours: boolean = false;

	constructor(private el: ElementRef, private renderer: Renderer2) {}

	@HostListener('input', ['$event']) onInput(event: Event): void {
		const inputValue: string = this.el.nativeElement.value;

		// Remove caracteres não numéricos
		const numericValue: string = inputValue.replace(/[^\d]/g, '');

		// Formata a hora enquanto o usuário digita
		const formattedValue = this.formatTimeWhileTyping(numericValue);

		// Define o valor formatado no elemento de entrada
		this.renderer.setProperty(this.el.nativeElement, 'value', formattedValue);
	}

	private formatTimeWhileTyping(value: string): string {
		// Adiciona a máscara de hh:mm enquanto o usuário digita
		const maskedValue = value.replace(/^(\d{0,2})(\d{0,2})/, '$1:$2');

		// Verifica se o formato de hora deve ser 24 horas ou não
		const timeFormat = this.use24Hours ? 'HH:mm' : 'hh:mm';

		// Obtém horas e minutos
		const [hours, minutes] = maskedValue.split(':').map(part => parseInt(part, 10));

		// Verifica se as horas e minutos estão dentro dos limites permitidos
		if (!this.use24Hours) {
			if (hours < 0 || hours > 99 || minutes < 0 || minutes > 59) {
				return '';
			}

			// Limita os minutos a 59 se as horas atingirem o limite
			if (hours === 99 && minutes > 0) {
				return `${hours}:00`;
			}
		}

		return maskedValue.endsWith(':') ? maskedValue.slice(0, -1) : maskedValue;
	}
}

import { Pipe, PipeTransform } from '@angular/core';
import { CoreUtil } from '../core-util';
import { MatTable } from '@angular/material';

@Pipe({
	name: 'arraySort'
})
export class ArraySortPipe implements PipeTransform {
	transform(array: any[], property: string, asc: boolean, gridList: MatTable<any>): any[] {
		if (array && property && property.length > 0) {
			array = array.sort((a, b) => {
				if (this.splitProperty(a, property) > this.splitProperty(b, property)) {
					return asc ? 1 : -1;
				}

				if (this.splitProperty(b, property) > this.splitProperty(a, property)) {
					return asc ? -1 : 1;
				}

				return 0;
			});

			if (gridList) {
				setTimeout(() => {
					gridList.renderRows();
				}, 15);
			}
		}

		return array;
	}

	splitProperty(obj: any, property: string) {
		let rtn = CoreUtil.getSplittedProperty(obj, property);

		if (typeof rtn == 'number') {
			return rtn;
		}

		if (typeof rtn == 'boolean') {
			return rtn ? rtn.toString().toLowerCase() : '';
		}

		if (Array.isArray(rtn)) {
			let rtnJoin = rtn.join(',');
			return rtnJoin.toLowerCase();
		}

		if (typeof rtn == 'object' && rtn != null) {
			return rtn.textContent;
		}

		if (typeof rtn == null){
			return rtn;
		}

		return rtn ? rtn.toLowerCase() : '';
	}
}
import { Directive, HostBinding, HostListener, Output, EventEmitter } from '@angular/core';
import { Constants } from 'src/constants';
import { Messages } from 'src/internationalization/messages/messages';
import { ToastService } from '../service/toast.service';

@Directive({
	selector: '[appDragDropFileUpload]'
})
export class DragDropDirective {
	@HostBinding('class.fileover') fileOver: boolean;
	@Output() fileDropped = new EventEmitter<any>();
	private _messages = Messages.getMessages();
	public _constants = Constants.constant;

	constructor(private toastService: ToastService) { }

	@HostListener('dragover', ['$event']) onDragOver(evt) {
		evt.preventDefault();
		evt.stopPropagation();
		this.fileOver = true;
	}

	@HostListener('dragleave', ['$event']) public onDragLeave(evt) {
		evt.preventDefault();
		evt.stopPropagation();
		this.fileOver = false;
	}

	@HostListener('drop', ['$event']) public ondrop(evt) {
		evt.preventDefault();
		this.fileOver = false;
		const file = evt.dataTransfer;

		if (evt.dataTransfer.files[0].type === 'image/png' || evt.dataTransfer.files[0].type === 'image/jpeg') {
		if (file)	{
				this.fileDropped.emit(file);
			}
		} else	{
			this.toastService.show(this._constants.messageType.error, this._messages.only_format_png_jpg);

			return;
		}
	}
}
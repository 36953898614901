import { Directive, Input, Output, EventEmitter, ElementRef, SimpleChanges, HostListener, forwardRef } from '@angular/core';
import { NG_VALIDATORS, AbstractControl } from '@angular/forms';
import { Colors } from 'src/colors';

@Directive({
	selector: '[appCpf]',
	providers: [
		{ provide: NG_VALIDATORS, useExisting: forwardRef(() => CpfDirective), multi: true }
	]
})
export class CpfDirective {
	@Input() ngModel: string;
	@Output() ngModelChange = new EventEmitter();

	private control: AbstractControl;
	private lastValue: string;

	constructor(private element: ElementRef) {
	}

	validate(c: AbstractControl): { [key: string]: any } {
		this.control = c;
		this.element.nativeElement.parentElement.style.backgroundColor = '';

		return null;
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.ngModel && changes.ngModel.currentValue && this.ngModel != this.lastValue) {
			const event = {target: {value: changes.ngModel.currentValue}};
			this.onInput(event);
		}
	}

	@HostListener( 'input', ['$event'] )
	onInput( $event: any ) {
		let value = $event.target.value;
		if (value) {
			value = value.replace(/\D/g, '').substring(0,14);

			if (value.length > 2) {
				if (value.length <= 6) {
					value = value.substring(0,6).replace(/^(\d{3})?(\d{3})?/, '$1.$2');
				} else if (value.length <= 9) {
					value = value.substring(0,9).replace(/^(\d{3})?(\d{3})?(\d{3})?/, '$1.$2.$3');
				} else {
					value = value.substring(0,11).replace(/^(\d{3})?(\d{3})?(\d{3})?(\d{2})?/, '$1.$2.$3-$4');
				}
			}
			this.doChange(value);
		}
	}

	@HostListener( 'blur', ['$event'] )onBlur( $event: any ) {
		let value = $event.target.value;

		if (this.control && this.control.value) {
			value = value.replace(/\D/g, '');
			if (value && (this.doValidate(value) != value || value.length < 11)) {
				this.element.nativeElement.parentElement.style.backgroundColor = Colors.color.red_error_background;
				this.control.setErrors({taxId: false});

				return;
			}

			this.element.nativeElement.parentElement.style.backgroundColor = '';
			this.control.setErrors(null);
		}
	}

	doChange(value) {
		setTimeout(() => {
			this.element.nativeElement.value = value;
			value = value.replace(/\D/g, '');
			this.lastValue = value;
			this.ngModel = value;
			this.ngModelChange.emit(value);
		}, 15);
	}

	doValidate(value) {
		const rtn = value.substring(0,value.length - 1);

		if (value == '') return '';

		if (value.length != 11) return value;

		if (value == '00000000000' ||
				value == '11111111111' ||
				value == '22222222222' ||
				value == '33333333333' ||
				value == '44444444444' ||
				value == '55555555555' ||
				value == '66666666666' ||
				value == '77777777777' ||
				value == '88888888888' ||
				value == '99999999999') return rtn;

		let s = 0;
		let r;

		for (let i = 1; i <= 9; i++) s = s + parseInt(value.substring(i - 1, i)) * (11 - i);
		r = (s * 10) % 11;

		if ((r == 10) || (r == 11)) r = 0;
		if (r != parseInt(value.substring(9, 10)) ) return rtn;

		s = 0;
		for (let i = 1; i <= 10; i++) s = s + parseInt(value.substring(i - 1, i)) * (12 - i);
		r = (s * 10) % 11;

		if ((r == 10) || (r == 11)) r = 0;
		if (r != parseInt(value.substring(10, 11) ) ) return rtn;

		return value;
	}
}
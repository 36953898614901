import {
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoadService } from '../service/load.service';

@Injectable({ providedIn: 'root' })
export class LoaderInterceptor implements HttpInterceptor {
	private requests: HttpRequest<any>[] = [];

	constructor(private _loadService: LoadService) {}

	intercept(
		req: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		if (req.url.indexOf('userinformation') < 0) {
			this.requests.push(req);
			this._loadService.isHideAll().subscribe(hide => {
                if (!hide) {
                    this._loadService.show();
                }
            });
			const mytimer = setTimeout(() => {
				this._loadService.hide();
			}, 10000);
			return next.handle(req).pipe(
				finalize(() => {
					this.removeRequest(req);
					if (this.requests.length === 0) {
						this._loadService.hide();
					}
					clearTimeout(mytimer);
				})
			);
		}
		return next.handle(req);
	}

	removeRequest(req: HttpRequest<any>) {
		const i = this.requests.indexOf(req);
		if (i >= 0) {
			this.requests.splice(i, 1);
		}
	}
}

import { Messages } from 'src/internationalization/messages/messages';
import { Constants } from 'src/constants';
import { Labels } from 'src/internationalization/labels/labels';
import { HttpClientService } from './service/http-client.service';
import { LoginService } from '../resource/service/login.service';
import { CheckTest } from './checkTest';
import { CoreUtil } from './core-util';

export class CheckTools {
	public static _constants = Constants.constant;

	public static showVersions(services, httpClientService: HttpClientService, loginService: LoginService) {
		// SERVICES
		services.forEach(service => {
			status += service.name + '&nbsp;';
			httpClientService.getUrl(service.url)
				.subscribe((response: any) => {
					if(service.name == 'Vessel' || service.name == 'Schedule'){
						let val = response.data.date;

						let value = val.split(" ");
						let date = value[0].split("-");
						let hour = value[1].split(":");
						let v1 = date[1]+"."+date[2];
						let v2 = hour[0]+"."+hour[1];
						service.resp = v1+"."+v2;
					}else{
						service.resp = response.version;
					}
				}, error => {
					if (error.status && error.name) {
						service.resp = error.status + ': ' + error.name;
					} else {
						service.resp = error.message;
					}
				});
		});

		setTimeout(() => {
			console.log('');
			console.log('Versions:');
			console.log('  WEB: ' + Constants.constant.version);
			services.forEach(service => {
				console.log('  ' + service.name + ': ' + service.resp);
			});
		}, 8000);
	}

	public setVersionStr(val:any):any{
		let value = val.split(" ");
		let date = value[0].split("-");
		let hour = value[1].split(":");
		let v1 = date[1]+"."+date[2];
		let v2 = hour[0]+"."+hour[1];
		return v1+"."+v2;
	}

	public static checkLabels() {
		this.doCheckLabels('Labels', this.objectToArray(Labels.labelsEnUs), 'En-US', this.objectToArray(Labels.labelsPtBr), 'Pt-BR');
		this.doCheckLabels('Labels', this.objectToArray(Labels.labelsEnUs), 'En-US', this.objectToArray(Labels.labelsEsEs), 'Es-ES');
		this.doCheckLabels('Labels', this.objectToArray(Labels.labelsPtBr), 'Pt-BR', this.objectToArray(Labels.labelsEsEs), 'Es-ES');
	}

	public static checkMessages() {
		this.doCheckLabels('Messages', this.objectToArray(Messages.messagesEnUs), 'En-US', this.objectToArray(Messages.messagesPtBr), 'Pt-BR');
		this.doCheckLabels('Messages', this.objectToArray(Messages.messagesEnUs), 'En-US', this.objectToArray(Messages.messagesEsEs), 'Es-ES');
		this.doCheckLabels('Messages', this.objectToArray(Messages.messagesPtBr), 'Pt-BR', this.objectToArray(Messages.messagesEsEs), 'Es-ES');
	}

	public static exportLabels() {
		this.doExportToXLS('Labels', this.objectToArray(Labels.labelsEnUs), this.objectToArray(Labels.labelsPtBr), this.objectToArray(Labels.labelsEsEs));
	}

	public static exportMessages() {
		this.doExportToXLS('Messages', this.objectToArray(Messages.messagesEnUs), this.objectToArray(Messages.messagesPtBr), this.objectToArray(Messages.messagesEsEs));
	}

	// Convert only 2 levels - DO NOT CHANGE THIS METHOD
	private static objectToArray(objectItem): any {
		const arrayValues = [];

		Object.keys(objectItem).map(key => {
			if (typeof (objectItem[key]) === 'string' && key !== null) {
				if (!arrayValues.includes(key)) {
					arrayValues.push(key + '::' + objectItem[key]);

					return arrayValues;
				}
			} else {
				Object.keys(objectItem[key]).map(key2 => {
					if (!arrayValues.includes(key + '/' + key2)) {
						arrayValues.push(key + '/' + key2 + '::' + objectItem[key][key2]);

						return arrayValues;
					}
				});
			}
		});

		return arrayValues;
	}

	private static doCheckLabels(type: string, array1, strings1: string, array2, string2: string) {
		const labels2Splited = [];
		const labels1Splited = [];
		const notContains2Items = [];
		const notContains1Items = [];
		const supostEqualsItems = [];

		array1.forEach(item => {
			labels1Splited.push(item.split('::')[0]);
		});

		array2.forEach(item => {
			labels2Splited.push(item.split('::')[0]);
		});

		array1.forEach(item => {
			if (!labels2Splited.includes(item.split('::')[0])) {
				notContains2Items.push(item);
			}

			if (array2.includes(item)) {
				supostEqualsItems.push(item);
			}
		});

		array2.forEach(item => {
			if (!labels1Splited.includes(item.split('::')[0])) {
				notContains1Items.push(item);
			}
		});

		notContains2Items.forEach(item => {
		});
		notContains1Items.forEach(item => {
		});
		supostEqualsItems.forEach(item => {
		});
	}

	private static doExportToXLS(title: string, arrayEnUS, arrayPtBR, arrayEsES) {
		const arrayKeys = [];
		const arrayData = [];
		const arrayHeader = [
			{ key: 'keyLabel', value: title },
			{ key: 'enus', value: 'En-US' },
			{ key: 'ptbr', value: 'Pt-BR' },
			{ key: 'eses', value: 'Es-ES' },
		];

		arrayEnUS.forEach(item => {
			arrayKeys.push(item.split('::')[0]);
		});

		arrayPtBR.forEach(item => {
			if (!arrayKeys.includes(item.split('::')[0])) {
				arrayKeys.push(item.split('::')[0]);
			}
		});

		arrayEsES.forEach(item => {
			if (!arrayKeys.includes(item.split('::')[0])) {
				arrayKeys.push(item.split('::')[0]);
			}
		});

		arrayKeys.sort((a, b) =>  (a > b ? 1 : -1));

		arrayKeys.forEach(item => {
			const label = {
				keyLabel: item,
				enus: '',
				ptbr: '',
				eses: '',
			};

			arrayEnUS.forEach(itemLanguage => {
				if (item == itemLanguage.split('::')[0]) {
					label.enus = itemLanguage.split('::')[1];
				}
			});

			arrayPtBR.forEach(itemLanguage => {
				if (item == itemLanguage.split('::')[0]) {
					label.ptbr = itemLanguage.split('::')[1];
				}
			});

			arrayEsES.forEach(itemLanguage => {
				if (item == itemLanguage.split('::')[0]) {
					label.eses = itemLanguage.split('::')[1];
				}
			});

			arrayData.push(label);
		});

		CoreUtil.exportXls(arrayData, arrayHeader, undefined, undefined, '', title);
	}

	public static importLabels(xlsDataArray) {
		this.doExportToTxt(xlsDataArray, 'Labels');
	}

	public static importMessages(xlsDataArray) {
		this.doExportToTxt(xlsDataArray, 'Messages');
	}

	private static doExportToTxt(xlsDataArray, type) {
		const xlsDataArrayChangedEnUS = [];
		const xlsDataArrayChangedPtBR = [];
		const xlsDataArrayChangedEsES = [];
		let arrayEnUS = [];
		let arrayPtBR = [];
		let arrayEsES = [];

		if (type === 'Messages') {
			arrayEnUS = this.objectToArray(Messages.messagesEnUs);
			arrayPtBR = this.objectToArray(Messages.messagesPtBr);
			arrayEsES = this.objectToArray(Messages.messagesEsEs);
		} else
		if (type === 'Messages') {
			arrayEnUS = this.objectToArray(Labels.labelsEnUs);
			arrayPtBR = this.objectToArray(Labels.labelsPtBr);
			arrayEsES = this.objectToArray(Labels.labelsEsEs);
		}

		arrayEnUS.forEach(item => {
			const item2 = xlsDataArray.find(a => a[type] === item.split('::')[0]);

			if (item2 && item != item2[type] + '::' + item2['En-US']) {
				xlsDataArrayChangedEnUS.push(item + ' /->/ ' + item2['En-US']);
			}
		});

		arrayPtBR.forEach(item => {
			const item2 = xlsDataArray.find(a => a[type] === item.split('::')[0]);

			if (item2 && item != item2[type] + '::' + item2['Pt-BR']) {
				xlsDataArrayChangedPtBR.push(item + ' /->/ ' + item2['Pt-BR']);
			}
		});

		arrayEsES.forEach(item => {
			const item2 = xlsDataArray.find(a => a[type] === item.split('::')[0]);

			if (item2 && item != item2[type] + '::' + item2['Es-ES']) {
				xlsDataArrayChangedEsES.push(item + ' /->/ ' + item2['Es-ES']);
			}
		});

	}

	public static checkDateUtil() {
	}
}

import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { AuthGuard } from './resource/guard/auth.guard';
import { RoleValidation, EvaluationTypes } from './resource/guard/roleUrlCheck';

export type RoutesWithRoleRules = Route & {
	roleValidation: keyof typeof RoleValidation.prototype.validationTypes;
	evaluationType: EvaluationTypes;
	rolePool?: string[]
}

export const routes: RoutesWithRoleRules[] = [
	{
		path: 'login',
		loadChildren: './resource/login/login.module#LoginModule',
		canActivate: [],
		canActivateChild: [],
		roleValidation: 'none',
		evaluationType:'normal'
	},
	{
		path: '',
		loadChildren: './resource/page/dashboard/dashboard.module#DashboardModule',
		canActivate: [AuthGuard],
		canActivateChild: [AuthGuard],
		roleValidation: 'none',
		evaluationType:'normal'
	},
	{
		path: 'customer',
		loadChildren: './resource/page/customer/customer.module#CustomerModule',
		canActivate: [AuthGuard],
		canActivateChild: [AuthGuard],
		roleValidation: 'none',
		evaluationType:'normal'
	},
	{
		path: 'general_register',
		loadChildren: './resource/page/general-register/general-register.module#GeneralRegisterModule',
		canActivate: [AuthGuard],
		canActivateChild: [AuthGuard],
		roleValidation: 'generic',
		evaluationType:'normal'
	},
	{
		path: 'insurance',
		loadChildren: './resource/page/insurance/insurance.module#InsuranceModule',
		canActivate: [AuthGuard],
		canActivateChild: [AuthGuard],
		roleValidation: 'generic',
		evaluationType:'normal'

	},
	{
		path: 'vendor',
		loadChildren: './resource/page/vendor/vendor.module#VendorModule',
		canActivate: [AuthGuard],
		canActivateChild: [AuthGuard],
		roleValidation: 'generic',
		evaluationType:'normal'

	},
	{
		path: 'profile_management',
		loadChildren: './resource/page/management/management.module#ManagementModule',
		canActivate: [AuthGuard],
		canActivateChild: [AuthGuard],
		roleValidation: 'generic',
		evaluationType:'normal'

	},
	{
		path: 'appointment',
		loadChildren: './resource/page/appointment/appointment.module#AppointmentModule',
		canActivate: [AuthGuard],
		canActivateChild: [AuthGuard],
		roleValidation: 'generic',
		evaluationType:'normal'
	},
	{
		path: 'wiki',
		loadChildren: './resource/page/wiki/wiki.module#WikiModule',
		canActivate: [AuthGuard],
		canActivateChild: [AuthGuard],
		roleValidation: 'generic',
		evaluationType:'normal'

	}, {
		path: 'vessel-management',
		loadChildren: () => import('./resource/page/product/product.module').then(m => m.ProductModule),
		canActivate: [AuthGuard],
		canActivateChild: [AuthGuard],
		roleValidation: 'generic',
		evaluationType:'normal'

	},
	{
		path: 'product-management',
		loadChildren: () => import('./resource/page/product-management/product-management.module').then(m => m.ProductManagementModule),
		canActivate: [AuthGuard],
		canActivateChild: [AuthGuard],
		roleValidation: 'generic',
		evaluationType:'normal'
	},
	{
		path: 'acr',
		loadChildren: () => import('./resource/page/product/vessel-capacity/acr/acr.module').then(m => m.AcrModule),
		canActivate: [AuthGuard],
		canActivateChild: [AuthGuard],
		roleValidation: 'generic',
		evaluationType:'normal'
	},
	{
		path: 'localities',
		loadChildren: () => import('./resource/page/localities-mapping/localities-mapping.module').then(m => m.LocalitiesMappingModule),
		canActivate: [AuthGuard],
		canActivateChild: [AuthGuard],
		roleValidation: 'generic',
		evaluationType:'normal'
	},
	{
		path: 'costing',
		loadChildren: () => import('./resource/page/costing/costing.module').then(m => m.CostingModule),
		canActivate: [AuthGuard],
		canActivateChild: [AuthGuard],
		roleValidation: 'allClosed',
		evaluationType:'normal',
		rolePool: [
			`intercab-cost-cost-subcostmodulevisualize-maindata-visualize`,
			`intercab-cost-cost-subcostmoduleedit-maindata-edit`,
		],
	},
	{
		path: 'agreement',
		loadChildren: () => import('./resource/page/agreement/agreement.module').then(m => m.AgreementModule),
		canActivate: [AuthGuard],
		canActivateChild: [AuthGuard],
		roleValidation: 'allClosed',
		evaluationType:'normal',
		rolePool: [
			`intercab-commercial-agreement-subcommercialagreementcreation-maindata-edit`,
			`intercab-commercial-agreement-subcommercialagreementmanagement-maindata-edit`,
			`intercab-commercial-agreement-subcommercialagreementvisualize-maindata-visualize`,
			`intercab-commercial-agreement-subcommercialagreementedit-maindata-edit`,
			`intercab-pricing-pricing-subpricingpricingarchitectureapproval-maindata-edit`,
			`intercab-pricing-pricing-subpricingagreementmanagementpricingmanager-maindata-edit`,
			`intercab-pricing-pricing-subpricingagreementmanagementpricingcoordination-maindata-edit`,
			`intercab-pricing-pricing-subpricingagreementmanagementpricingteam-maindata-edit`,
			`intercab-pricing-agreement-subpricingagreementmanagementexpire-maindata-edit`,
			`intercab-pricing-agreement-subpricingagreementmanagement-maindata-visualize`,
			`intercab-pricing-agreement-subpricingagreementmanagementpostpone-maindata-edit`,
		],
	},
	{
		path: 'pricing',
		loadChildren: () => import('./resource/page/pricing/pricing.module').then(m => m.PricingModule),
		canActivate: [AuthGuard],
		canActivateChild: [AuthGuard],
		roleValidation: 'allClosed',
		evaluationType:'normal',
		rolePool: [
			`intercab-pricing-pricing-subpricingcreation-maindata-edit`,
			`intercab-pricing-pricing-subpricingmanagement-maindata-edit`,
			`intercab-pricing-pricing-subpricinginstancemanagement-maindata-edit`,
			`intercab-pricing-pricing-subpricingpricingarchitectureapproval-maindata-edit`,
			`intercab-pricing-pricing-subpricingagreementmanagementpricingmanager-maindata-edit`,
			`intercab-pricing-pricing-subpricingagreementmanagementpricingcoordination-maindata-edit`,
			`intercab-pricing-pricing-subpricingagreementmanagementpricingteam-maindata-edit`,
			`intercab-pricing-agreement-subpricingagreementmanagementexpire-maindata-edit`,
			`intercab-pricing-agreement-subpricingagreementmanagement-maindata-visualize`,
			`intercab-pricing-agreement-subpricingagreementmanagementpostpone-maindata-edit`,
			'intercab-pricing-agreement-subpricingagreementmanagement-maindata-visualize'
		],
	},
	{
		path: 'pricing-architecture',
		loadChildren: () => import('./resource/page/pricing-architecture/pricing-architecture.module').then(m => m.PricingArchitectureModule),
		canActivate: [AuthGuard],
		canActivateChild: [AuthGuard],
		roleValidation: 'allClosed',
		evaluationType:'normal',
		rolePool: [
			`intercab-pricing-pricing-subpricingpricingarchitectureapproval-maindata-edit`,
			`intercab-pricing-pricing-subpricingagreementmanagementpricingmanager-maindata-edit`,
			`intercab-pricing-pricing-subpricingagreementmanagementpricingcoordination-maindata-edit`,
			`intercab-pricing-pricing-subpricingagreementmanagementpricingteam-maindata-edit`,
			`intercab-pricing-agreement-subpricingagreementmanagementexpire-maindata-edit`,
			`intercab-pricing-agreement-subpricingagreementmanagement-maindata-visualize`,
			`intercab-pricing-agreement-subpricingagreementmanagementpostpone-maindata-edit`,
		],
	},
	{
		path: 'booking',
		loadChildren: () => import('./resource/page/booking/booking.module').then(m=>m.BookingModule),
		canActivate: [AuthGuard],
		canActivateChild: [AuthGuard],
		roleValidation: 'generic',
		evaluationType:'normal'
	},

];
@NgModule({
	imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
	exports: [RouterModule]
})
export class AppRoutingModule { }
import { Pipe, PipeTransform } from '@angular/core';
import { Labels } from 'src/internationalization/labels/labels';
import { Constants } from 'src/constants';

@Pipe({
	name: 'boolean'
})
export class BooleanPipe implements PipeTransform {
	public _labels = Labels.getLabels();
	private _constants = Constants.constant;

	constructor() { }

	transform(value): string {
		if (value === this._constants.general.true || value === true) {
			return this._labels.component.yes;
		} else if (value === this._constants.general.false || value === false) {
			return this._labels.component.no;
		} else {
			return '';
		}
	}
}
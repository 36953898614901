// truncate.service.ts
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class TruncateService {
	truncateText(text: string, limit: number): string {

		if (text === undefined) {
			return '';
		}

		const truncatedText = text.length > limit ? text.substring(0, limit) + '...' : text;
		return truncatedText;
	}
}
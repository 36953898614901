import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-complex-select',
  templateUrl: './complex-select.component.html',
  styleUrls: ['./complex-select.component.scss']
})
export class ComplexSelectComponent implements OnInit {
  @Input() filterPlaceholder: string = 'Search';
  @Input() selectedItemsLabel: string = null;
  @Input() emptyFilterMessage: string = null;
  @Input() formController: FormControl;
  @Input() showHeader: boolean = true;
  @Input() multiple: boolean = false;
  @Input() disabled: boolean = false;
  @Input() filter: boolean = true;
  @Input() arrayData: any[] = [];
  @Input() propertyLabel: string;
  @Input() propertyValue: string;
  @Input() placeholder: string;
  @Input() value: any = null;
  @Input() label: string;

	@Output() valueChange = new EventEmitter<any>();
	@Output() arrayDataChange = new EventEmitter<any>();
	@Output() searchTextChange = new EventEmitter<string>();

  public selectedItemsLabelEdited: string;
  public filterBy: string;

  constructor() {
   }

  ngOnInit() {
    let value = this.formController ? this.formController.value : this.value;
    this.filterBy = this.propertyLabel;
    this._setValue(value);
    this.setSelectedItemsLabel();
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  eventSearchEmitter(value: string){
    this.searchTextChange.emit(value);
  }

  eventValueEmitter(value: string){
    this._setValue(value);
    this.setSelectedItemsLabel();
    this.arrayDataChange.emit(this.arrayData);
    this.valueChange.emit(value);
  }

  setSelectedItemsLabel(): void{
    if(this.selectedItemsLabel){
      this.selectedItemsLabelEdited = this.selectedItemsLabel;
    }else{
      if(this.multiple){
        let value = this._getValue();
        if(value){
          this.selectedItemsLabelEdited = `${value[0][this.propertyLabel]}({0})`;
        }else{
          this.selectedItemsLabelEdited = `${this.arrayData[0][this.propertyLabel]}({0})`;
        }
      }
    }
  }

  private _getValueByPropertyLabel(justFirstValue = false){
    let value = this._getValue(justFirstValue);
    if(Array.isArray(value)){
      return value.map(val => val[this.propertyLabel]);
    }else{
      return value[this.propertyLabel];
    }
  }

  private _getValue(justFirstValue = false){
    let value = null;
    if(this.formController){
      value = this.formController.value;
    }else{
      value = this.value;
    }

    if(justFirstValue){
      if(Array.isArray(value)){
        value = value[0];
      }else{
        value = value;
      }
    }

    return value;
  }

  private _setValue(value: any){
    if(this.multiple && !Array.isArray(value)){
      value = value ? [value] : [];
    }

    if(this.formController){
      this.formController.setValue(value);
      this.disabled = this.formController.disabled;
    }else{
      this.value = value;
    }
  }

  public existValue(): boolean{
    let value = this._getValue();

    if(this.multiple && Array.isArray(value)){
      if(value.length <= 0){
        value = null;
      }
    }

    return value;
  }
}

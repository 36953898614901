import { LocaleSwitcher } from 'src/internationalization/locale-switcher';

export class Labels {
	public static labelsPtBr = require('./labels_pt-br.json');
	public static labelsEnUs = require('./labels_en-us.json');
	public static labelsEsEs = require('./labels_es-es.json');

	public static getLabels(locale = LocaleSwitcher.getLocale()) {

		switch (locale) {
			case 'pt':
			case 'pt-br':
				return this.labelsPtBr;
			case 'en':
			case 'en-us':
				return this.labelsEnUs;
			case 'es':
			case 'es-es':
				return this.labelsEsEs;
		}
	}
}
import { isNgTemplate } from '@angular/compiler';
import { Component, Input, OnInit } from '@angular/core';
import { Labels } from 'src/internationalization/labels/labels';

@Component({
  selector: 'app-booking-x-allocation-per-team',
  templateUrl: './booking-x-allocation-per-team.component.html',
  styleUrls: ['./booking-x-allocation-per-team.component.scss']
})
export class BookingXAllocationPerTeamComponent implements OnInit {

  @Input() data;
  @Input() perVvd;
  public _labels = Labels.getLabels();
            
  
  constructor() { }


  ngOnInit(): void {
  }


  changeColorsBar(team: string): string {
    switch (team) {
      case 'CABOTAGE':
        return '#1E4E8A';
        break;
      case 'TS_CABOTAGE':
        return '#42ACDB';
        break;
      case 'TS CABOTAGE':
        return '#42ACDB';
        break;
      case 'IMPORT':
        return '#920101';
        break;
      case 'EXPORT':
        return '#EC0101';
        break;
      case 'FEEDER':
        return '#FCD34F';
        break;
      case 'EMPTY':
        return '#CED3D5';
        break;
    }
  }

  changeColorsTable(team: string): string {
    switch (team) {
      case 'CABOTAGE':
        return '#1e4e8a33';
        break;
      case 'TS_CABOTAGE':
        return '#86bcff33';
        break;
      case 'TS CABOTAGE':
        return '#86bcff33';
        break;
      case 'IMPORT':
        return '#92010126';
        break;
      case 'EXPORT':
        return '#ec010126';
        break;
      case 'FEEDER':
        return '#fcd34f33';
        break;
      case 'EMPTY':
        return '#ced3d533';
        break;
    }
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { Labels } from 'src/internationalization/labels/labels';

@Component({
  selector: 'app-status-with-label-for-schedule-port-list',
  templateUrl: './status-with-label-for-schedule-port-list.component.html',
  styleUrls: ['./status-with-label-for-schedule-port-list.component.scss']
})
export class StatusWithLabelForSchedulePortListComponent implements OnInit {

  @Input() status:any;
	@Input() columns:boolean;
	@Input() statusTooltip: string;
	_labels = Labels.getLabels();

  constructor() { }

  ngOnInit(): void {
  }

  getText(status){
		switch (status) {
			case 'ONGOING':
				return this._labels.on_going;
			case 'ON_GOING':
				return this._labels.on_going;
			case 'TO_BE_INITIATED':
				return this._labels.to_be_initiated;
			case 'TO BE INITIATED':
				return this._labels.to_be_initiated;
			case 'FINISHED':
				return this._labels.title.finished;
			case 'ON_TIME':
				return this._labels.on_time;
			case 'DELAYED':
				return this._labels.delayed;
			case 'EXTRA CALL':
				return this._labels.extra_call;
			case 'OMITTED':
				return this._labels.omitted;
			case 'PENDING':
				return '';	
			case 'ACTIVE':
				return '';
			default:
				return '';
		}
	}
	getDefaultStatusText() {
		switch (this.status) {
			case 'PENDING':
				return this._labels.pending;
			case 'ACTIVE':
				return this._labels.active;
			case 'INACTIVE':
				return this._labels.inactive;
			default:
				return '';
		}
	}
  
}

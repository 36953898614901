import { Component, Input } from '@angular/core';
import { EButtonDirective } from 'src/app/resource/enums/e-button-directive.enum';

@Component({
  selector: 'app-button-icon',
  templateUrl: './button-icon.component.html',
  styleUrls: ['./button-icon.component.scss']
})
export class ButtonIconComponent {
	@Input() src: string;
	@Input() isIconRight: boolean;
	@Input() iconSize: string;
	@Input() buttonDirective: EButtonDirective;

	public _eButtonDirective = EButtonDirective;
}
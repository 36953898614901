import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-input-mask',
  templateUrl: './input-mask.component.html',
  styleUrls: ['./input-mask.component.scss']
})
export class InputMaskComponent implements OnInit {

  @Input()
  value: string = '';

  @Input()
  formController: FormControl;

  @Input()
  maxLength: number = null;

  @Input()
  disabled: boolean = false;

  @Input()
  mask: string = "";

  @Output()
  valueChange = new EventEmitter<string>();

  constructor() { }

  ngOnInit() {
    if(this.formController){
      this.changeValue(this.formController.value);
      this.disabled = this.formController.disabled;
    }else{
      this.changeValue(this.value);
    }
  }

  changeValue(value: string): void {
    if (this.existValue(value)) {
      this.value = this.convertValue(value);
    }
  }

  keyUp(value: string): void {
    if (this.existValue(value)) {
      value = this.convertValue(value);
      this.value = value;
    }
  }

  convertValue(value: string): string {
    const rgxs: RegexAndSeparator[] = this.convertMaskToRegex(this.mask);
    value = this.validateMaxLength(value);
    if (this.mask) {
      if (!this.mask.includes("9")) {
        value = value.replace(/([0-9])$/g, "");
      }
      if (!this.mask.includes("X")) {
        value = value.replace(/([a-zA-Z])$/g, "")
      }

      rgxs.forEach(rgx => {
        value = value.replace(rgx.separator, "");
      });
    }

    rgxs.forEach(rgx => {
      value = value.replace(rgx.regex, `${rgx.separator}$1`);
    });

    this.valueChange.emit(value);
    if(this.formController){
      this.formController.setValue(value);
    }

    return value;
  }

  convertMaskToRegex(rgx: any): RegexAndSeparator[] {
    let regexes: RegexAndSeparator[] = [];
    let countGroups: number = 0;
    let groups = [[]];

    //agrupar caracteres repetidos da mascara
    for (let i = rgx.length; i > 0; i--) {
      let group = groups[countGroups];

      if (group) {
        if ((i - 1) >= 0 && rgx[i] === rgx[i - 1]) {
          if (!group.includes(i)) {
            group.push(i);
          }

          if (!group.includes(i - 1)) {
            group.push(i - 1);
          }
        } else {
          if (group.length > 0) {
            countGroups++;
            groups[countGroups] = [];
          }
        }
      }
    }

    let lengthAux = 0;
    for (let i = 0; i < groups.length; i++) {
      let length = groups[i].length;
      let lastIndex = groups[i][length - 1];
      lengthAux = lengthAux + length + ((i > 0) ? 1 : 0);
      let separatorsList = this.mask.replace(rgx[lastIndex], "");
      let separators = "";
      let symbol = "";

      //remover elementos repetidos na lista de separadores
      for (let j = 0; j < separatorsList.length; j++) {
        if (separatorsList.indexOf(separatorsList[j]) === j) {
          if (separatorsList[j] !== "9" && separatorsList[j] !== "X") {
            separators += `\\${separatorsList[j]}`;
          } else {
            separators += `${separatorsList[j]}`;
          }
        }
      }

      switch (rgx[lastIndex]) {
        case "X":
          symbol = `[${separators}A-Za-z]`;
          break;
        case "9":
          symbol = `[${separators}0-9]`;
          break;
        default:
          symbol = `${separators}.`;
          break;
      }

      let separator = rgx[lastIndex - 1] ? `${rgx[lastIndex - 1]}` : "";
      let regex = new RegExp(`(${symbol}{${lengthAux}})$`, 'g');
      regexes.push(new RegexAndSeparator(regex, separator));
    }
    return regexes;
  }

  validateMaxLength(value: string): string {
    if (this.mask && this.mask.length > 0) {
      this.maxLength = this.mask.length;
    }

    if (this.maxLength) {
      value = value.substring(0, this.maxLength);
    }

    return value;
  }

  existValue(value: string): boolean {
    if (value) {
      return true;
    }

    return false;
  }
}

class RegexAndSeparator {
  regex: RegExp;
  separator: string;

  constructor(regex: RegExp, separator: string) {
    this.regex = regex;
    this.separator = separator;
  }
}
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutModule } from './layout/layout.module';
import { HttpClientModule } from '@angular/common/http';
import { ComponentModule } from './component/component.module';
import { DirectiveModule } from './directive/directive.module';
import { PipeModule } from './pipe/pipe.module';
import { SharedModule } from '../shared/shared.module';

@NgModule({
	imports: [
		CommonModule,
		HttpClientModule,
		LayoutModule,
		ComponentModule,
		DirectiveModule,
		PipeModule,
		SharedModule,
	],
	exports: [
		CommonModule,
		HttpClientModule,
		LayoutModule,
		ComponentModule,
		DirectiveModule,
		SharedModule,
		PipeModule
	]
})
export class CoreModule { }
import { Component, OnInit, ViewChild, Inject, InjectionToken, OnDestroy } from '@angular/core';
import { CoreUtil } from 'src/app/core/core-util';
import { ObjectFilterBaseService } from 'src/app/core/service/object-filter-base.service';
import { Icons } from 'src/icons';
import { Constants } from 'src/constants';
import { Subscription, timer } from 'rxjs';

export const RADIO_BUTTON_LIST_CONTAINER_DATA = new InjectionToken<{}>('RADIO_BUTTON_LIST_CONTAINER_DATA');

@Component({
	selector: 'app-radio-button-list-content',
	templateUrl: './radio-button-list-content.component.html',
	styleUrls: ['./radio-button-list-content.component.scss']
})
export class RadioButtonListContentComponent implements OnInit, OnDestroy {
	@ViewChild('searchInput', { static: false }) searchInput;

	public _icons = Icons;
	public _search = '';
	public arrayData: any[];
	public _microServices = Constants.constant.microServices;

	private _dispatcher: Subscription;
	private _subscriptionList: Subscription[];

	constructor(@Inject(RADIO_BUTTON_LIST_CONTAINER_DATA) public componentData: any,
		private service: ObjectFilterBaseService) {
	}

	ngOnInit() {
		if (!this.componentData.parent._arrayData) {
			this.getList(this.componentData.queryParams);
		} else {
			this.arrayData = this.componentData.parent._arrayData;
			this.getValue();
		}
	}

	ngAfterViewInit() {
		setTimeout(() => {
			this.searchInput.nativeElement.focus();
		}, 1);
	}

	getList(queryParms: string) {
		const response = resp => {
			if (resp && resp.data) {
				this.arrayData = this.componentData.preData.concat(resp.data);

				if (this.componentData.dataTransformer) {
					this.arrayData.forEach(item => {
						item = (this.componentData.dataTransformer.length > 1) ?
							this.componentData.dataTransformer[0](item, ...this.componentData.dataTransformer.slice(1)) :
							this.componentData.dataTransformer[0](item);
					});
				}

				this.getValue();

				if (!this.componentData.parent._arrayData) {
					this.componentData.parent._arrayData = this.arrayData;
				}
			}
		};

		const subscription = this.service
			.getObjectFilterList(this.componentData.microService, this.componentData.endPoint, this._search, queryParms)
			.subscribe(response);
		this._subscriptionList = CoreUtil.incrementSubscriptionList(this._subscriptionList, subscription);
	}

	callGetList(queryParams: string) {
		if (this._dispatcher) {
			this._dispatcher.unsubscribe();
		}

		this._dispatcher = timer(150).subscribe(() => {
			this.getList(queryParams);
		});
		this._subscriptionList = CoreUtil.incrementSubscriptionList(this._subscriptionList, this._dispatcher);
	}

	getValue() {
		const idProperty = this.componentData.idProperty;
		const value = this.componentData.value;

		if (value && value[idProperty]) {
			this.componentData.value = this.arrayData.find(a => a[idProperty] === value[idProperty]);
		}
	}

	splitProperty(obj: any) {
		return CoreUtil.getSplittedProperty(obj, this.componentData.auxDisplayProperty || this.componentData.displayProperty);
	}

	change(event) {
		this.componentData.parent.change(event);
	}

	ngOnDestroy() { CoreUtil.unsubscribeSubscriptionList(this._subscriptionList); }
}
import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';

@Component({
	selector: 'app-masked-input',
	templateUrl: './masked-input.component.html',
	styleUrls: ['./masked-input.component.scss']
})
export class MaskedInputComponent implements OnInit {
	@ViewChild('element', {static: false}) element;

	@Input() value;
	@Input() pipe: string;
	@Input() width: string;
	@Input() placeholder: string = '';
	@Input() isFormField = false;
	@Input() disabled = false;
	@Input() suffix;
	@Input() maxlength = 1024;
	@Input() validateCurrentDateMessage: string;
	@Input() validatePeriodInitialMessage: string;
	@Input() validatePeriodFinalMessage: string;
	@Output() valueChange = new EventEmitter();
	@Output() onKeyDownEnterOrBlur = new EventEmitter();
	@Output() onKeyDownEnter = new EventEmitter();
	@Output() onFocus = new EventEmitter();

	constructor() { }

	ngOnInit() { }

	change(event) {
		this.value = event;
		this.valueChange.emit(event);
	}

	keyDownEnterOrBlur() {
		this.onKeyDownEnterOrBlur.emit();
	}

	keyDownEnter() {
		this.onKeyDownEnter.emit();
	}
}
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-block-items',
  templateUrl: './block-items.component.html',
  styleUrls: ['./block-items.component.scss']
})
export class BlockItemsComponent implements OnInit {

  @Input() blockTitle: string = '';
  @Input() itemsList: any[] = [];
  @Input() displayProperty: string = '';

  constructor() { }

  ngOnInit(): void {
  }

}

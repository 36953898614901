export class Booking {
	public placeOfReceipt: string;
	public placeOfDelivery: string;
	public portOfLoading: string;
	public portOfDischarge: string;
	public bookingNumber: string;
	public bookingCreationDate: string;
	public brand: string;
	public creationUser: string;
	public customerTradingName: string;
	public date: string;
	public imdChargeType: string;
	public insideBL: boolean;
	public exportation: boolean;
	public hour: string;
	public numberOfContainers: number;
	public numberOfPedings: number;
	public numberOfConfirmed: number;
	public numberOfUnderImdAnalysis: number;
	public numberOfUnderCsAnalysis: number;
	public numberOfSent: number;
	public status: string;
	public type: string;
}
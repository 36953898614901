import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Icons} from '../../../../icons';

@Component({
  selector: 'app-list-items-dropdown',
  templateUrl: './list-items-dropdown.component.html',
  styleUrls: ['./list-items-dropdown.component.scss']
})
export class ListItemsDropdownComponent {
	@Input() value: any;
	@Input() displayProperty = 'description';
	@Output() deleteItem = new EventEmitter();
	icons = Icons.icon;
}

import { Directive, Input, SimpleChanges } from '@angular/core';

@Directive({
	selector: '[appDirty]'
})
export class DirtyDirective {

	@Input() value;
	@Input() hiddenInput;

	private _inputEvent = new Event('input', {
		bubbles: true,
		cancelable: true,
	});

	constructor() { }

	ngOnChanges(changes: SimpleChanges) {
		if (!changes.value.firstChange) {
			this.hiddenInput.dispatchEvent(this._inputEvent);
		}
	}
}
import { Injectable } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ConfirmService } from './confirm.service';
import { MatDialogRef } from '@angular/material';
import { Messages } from 'src/internationalization/messages/messages';
import { Labels } from 'src/internationalization/labels/labels';
import { AbstractForm } from '../abstract/abstract-form';

@Injectable({
	providedIn: 'root'
})
export class DeactivateService implements CanDeactivate<AbstractForm> {
	public _labels = Labels.getLabels();
	private _messages = Messages.getMessages();

	constructor(private confirmService: ConfirmService) { }

	canDeactivate(component: AbstractForm, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot,
		nextState?: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {

		if (!component || !(component.dirty || component.tabDirty)) {
			return true;
		}

		return new Observable<boolean>(observer => {
			this.confirmService.open(undefined, this._labels.messageTitle.alert, this._messages.question.give_up_edittion,
				() => {
					observer.next(true);
				}, () => {
					observer.next(false);
				});
		});
	}

	close(component: AbstractForm, dialogRef: MatDialogRef<any>, returnObj?: any) {
		if (component.dirty || component.tabDirty) {
			this.confirmService.open(undefined, this._labels.messageTitle.alert, this._messages.question.give_up_edittion,
				() => {
					dialogRef.close(returnObj ? returnObj : undefined);
				}, () => {

				});
		} else {
			dialogRef.close(returnObj ? returnObj : undefined);
		}
	}
}
import { Directive, ElementRef, Renderer2, Input, OnChanges, SimpleChanges, HostListener } from '@angular/core';

@Directive({
	selector: '[secondaryButton]'
})
export class SecondaryButtonDirective implements OnChanges {
	@Input() size: string;
	@Input() allowScroll: boolean = false;
	@Input() scrollTo: HTMLElement;

	constructor(private element: ElementRef, private renderer: Renderer2) {
		renderer.addClass(element.nativeElement, 'btn');
		renderer.addClass(element.nativeElement, 'btn-outline-primary');
		renderer.addClass(element.nativeElement, 'custom-button');
		renderer.addClass(element.nativeElement, 'secondary-button');
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.size && changes.size.currentValue) {
			this.renderer.addClass(this.element.nativeElement, changes.size.currentValue + '-button');
		}
	}

	@HostListener('click', ['$event.target'])
	scrollToTop(btn) {
		if (!this.allowScroll) { return; }

		const scrollBehavior: ScrollToOptions = { behavior: 'smooth', top: 0, left: 0 };

		if (this.scrollTo) {
			this.scrollTo.scrollIntoView(scrollBehavior);
		} else {
			window.scrollTo(scrollBehavior);
		}
	}
}
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { LoginService } from 'src/app/resource/service/login.service';
import { Icons } from 'src/icons';
import { UserLanguage } from '../../dto/userlanguage-dto';
import { UserLanguageService } from '../../service/userlanguage.service';
import { Response } from 'src/app/resource/dto/response';
import { CoreUtil } from '../../core-util';
import { Subscription } from 'rxjs';
import { LocaleSwitcher } from 'src/internationalization/locale-switcher';
import { User } from 'src/app/resource/dto/user';

@Component({
	selector: 'app-language-selector',
	templateUrl: './language-selector.component.html',
	styleUrls: ['./language-selector.component.scss']
})
export class LanguageSelectorComponent implements OnInit, OnDestroy {
	@Output() languageChanged = new EventEmitter();

	private _icons = Icons;
	private subscriptionList: Subscription[];
	public isInit;
	public user: User;
	public languageArray = [
		{ key: 'en-us', img: this._icons.getIconPath(this._icons.icon.flag_en, 'png'), text: 'English' },
		{ key: 'pt-br', img: this._icons.getIconPath(this._icons.icon.flag_br, 'png'), text: 'Português' },
		{ key: 'es-es', img: this._icons.getIconPath(this._icons.icon.flag_es, 'png'), text: 'Español' },
	];

	constructor(public loginService: LoginService,
		private userlanguageService: UserLanguageService) {
	}

	ngOnInit() {
		this.user = this.loginService.user;
	}

	onLanguageChange(language: string): void {
		if (!language) return;

		const oldLanguage = this.loginService.user.locale || LocaleSwitcher.getLocale();
		const body = new UserLanguage(language);
		this.loginService.user.locale = language;

		LocaleSwitcher.setLocale(this.loginService.user.locale);

		const subscription = this.userlanguageService.patch(this.loginService.user.id, body)
			.subscribe((result: Response<UserLanguage>) => {
				if (result) {
					// this.loginService.user.locale = language;
					// LocaleSwitcher.setLocale(this.loginService.user.locale);
					window.location.reload();
					// TODO: Waiting for better solution of labels swapping
					// this.languageChanged.emit(language);
				}
			}, error => {
				this.loginService.user.locale = oldLanguage;

				LocaleSwitcher.setLocale(oldLanguage);
			});
		CoreUtil.incrementSubscriptionList(this.subscriptionList, subscription);
	}

	getTriggerImage(): string {
		let icon;

		switch (LocaleSwitcher.getLocale()) {
			case 'en-us':
			case 'en':
				icon = this._icons.icon.flag_en;
				break;
			case 'pt-br':
			case 'pt':
				icon = this._icons.icon.flag_br;
				break;
			case 'es-es':
			case 'es':
				icon = this._icons.icon.flag_es;
				break;
		}

		return this._icons.getIconPath(icon, 'png');
	}

	ngOnDestroy(): void {
		CoreUtil.unsubscribeSubscriptionList(this.subscriptionList);
	}
}
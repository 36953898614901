import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { ToastComponent } from '../layout/toast/toast.component';
import { Constants } from 'src/constants';
import { Colors } from 'src/colors';

@Injectable({
	providedIn: 'root'
})
export class ToastService {
	private _constants = Constants.constant;
	private _colors = Colors.color;

	constructor(private _snackBar: MatSnackBar) { }

	show(type, message) {
		let color = '';

		switch (type) {
			case this._constants.messageType.success:
				color = this._colors.success; // '#30AB22';
				break;
			case this._constants.messageType.warning:
				color = this._colors.warning; // '#FFC320'
				break;
			case this._constants.messageType.info:
				color = this._colors.info; // '#57BCFF'
				break;
			case this._constants.messageType.error:
				color = this._colors.error; // '#FF4136'
				break;
			case this._constants.messageType.api_error:
				color = this._colors.error; // '#FF4136'
				break;
			case this._constants.messageType.pricing_architecture_error:
				color = this._colors.error; // '#FF4136'
				break;
		}

		document.documentElement.style.setProperty('--snackBarBorder', `solid 8px ${color}`);

		this._snackBar.openFromComponent(ToastComponent, {
			duration: (type === this._constants.messageType.success ? 5 : 10) * 1000,
			horizontalPosition: 'right',
			panelClass: ['snack-bar'],
			data: { type, message}
		});
	}

	warning(message: string): void {
		this.show(this._constants.messageType.warning, message);
	}

	error(message: string): void {
		this.show(this._constants.messageType.error, message);
	}

	apiError(message: string): void {
		this.show(this._constants.messageType.api_error, message);
	}

	success(message: string): void {
		this.show(this._constants.messageType.success, message);
	}

	info(message: string): void {
		this.show(this._constants.messageType.info, message);
	}

	hide(): void {
		this._snackBar.dismiss();
	}
}

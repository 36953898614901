import { Component, OnInit, Input } from '@angular/core';
import { Icons } from 'src/icons';

@Component({
	selector: 'app-main-form',
	templateUrl: './main-form.component.html',
	styleUrls: ['./main-form.component.scss']
})
export class MainFormComponent implements OnInit {

	@Input() breadcrumb: string;
	@Input() currentPage: string;

	public _icons = Icons;

	constructor() { }

	ngOnInit() {
	}
}
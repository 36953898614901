import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'large_number_pipe'
})
export class LargeNumberPipe implements PipeTransform {
	transform(value: any): any {
		value = value.toString();
		if (value.length > 7) {
			value = '######';
			return value;
		}
		return value;
	}
}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'app-checkbox',
	templateUrl: './checkbox.component.html',
	styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements OnInit {
	@Input() title: string;
	@Input() hint: string;
	@Input() field: boolean;
	@Output() fieldChange = new EventEmitter();

	constructor() { }

	ngOnInit() {
	}
}
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import {Icons} from '../../../../icons';
import {Labels} from '../../../../internationalization/labels/labels';
import { Tooltip } from '../../dto/tooltip';

@Component({
  selector: 'app-download-upload-csv',
  templateUrl: './download-upload-csv.component.html',
  styleUrls: ['./download-upload-csv.component.scss']
})
export class DownloadUploadCsvComponent {
	@Input() fileNameDownload: string;
	// Input File Type on Download
	@Input() typeFileDownload: string;
	// File size follows blob pattern which is in bytes
	@Input() fileSize: number = 0;
	// Use this variable if it is blob
	@Input() file: Blob;
	// Use this variable if it is a download-file url
	@Input() uriFile: string;
	// Use this variable to set the file download status
	@Input() downloaded = false;
	// Visible icon despite of having file or not
	@Input() icon: boolean = false;
	// File size and downloaded file information visible
	@Input() info: boolean = true;
	// Use this variable as false to dont listen to click
	@Input() listenClick: boolean = true;
	// Use this variable to emit the file download status
	@Input() fileNameUpload: string;
	// Input File Type on Upload
	@Input() typeFileUpload: string;
	// File size follows blob pattern which is in bytes
	@Input() accept: string;
	// Input select one more one file
	@Input() multipleUpload: boolean = true;
	// Boolean to show or not show icon attachment_no
	@Input() csvIsValid: boolean = true;
	@Input() uploadControl: unknown;
	@Input() subTitles: unknown[] = [];
	@Input() tooltip: Tooltip;
	@Input() disabledUpload: boolean = false;
	@Input() modal: { hasModal?: boolean, index?: number};
	@Output() clickIcon = new EventEmitter<boolean>();
	@Output() wasDownloaded = new EventEmitter<boolean>();

	_icons = Icons;
	_labels = Labels.getLabels();

	openModal(){
		this.clickIcon.emit();
	}

	onDownload(){
		this.wasDownloaded.emit();
	}

}

import { Directive, Input, Output, EventEmitter, ElementRef, SimpleChanges, HostListener } from '@angular/core';

@Directive({
	selector: '[appZipCode]'
})
export class ZipCodeDirective {
	@Input() ngModel: string;
	@Output() ngModelChange = new EventEmitter();

	private isLoaded = false;

	constructor(private element: ElementRef) { }

	ngOnChanges(changes: SimpleChanges) {
		if (changes.ngModel && changes.ngModel.currentValue && !this.isLoaded) {
			this.isLoaded = true;
			const event = {target: {value: changes.ngModel.currentValue}};
			this.onInput(event);
		}
	}

	@HostListener( 'input', ['$event'] )
	onInput( $event: any ) {
		let value = $event.target.value;
		if (value) {
			value = value.replace(/\D/g, '').substring(0,10);

			if (value.length > 5) {
				value = value.substring(0,8).replace(/^(\d{5})?(\d{3})?/, '$1-$2');
			}
			this.doChange(value);
		}
	}

	@HostListener( 'blur', ['$event'] )onBlur( $event: any ) {
		const value = $event.target.value;
		if (!value || value.replace(/\D/g, '').length < 8) {
			this.doChange('');
		}
	}

	doChange(value) {
		setTimeout(() => {
			this.ngModelChange.emit(value);
			this.element.nativeElement.value = value;
		}, 15);
	}
}
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LocaleSwitcher } from 'src/internationalization/locale-switcher';

@Injectable({
	providedIn: 'root'
})
export class AuthorizerService {
	constructor(private http: HttpClient) { }

	authenticate(token: any): Observable<any> {
		const url = `${environment.authorizerHost}userinformation`;

		return this.http.post<any>(url, {}, this.getHttpOptions(token.replace('Bearer ','')));
	}

	private getHttpOptions(token) {
		return {
			headers: new HttpHeaders({
				Authorization:  'Bearer ' + token || localStorage.getItem('msal.idtoken'),
				'Consumer-Key': environment.consumerkey,
				'Content-Type': 'application/json',
				locale: LocaleSwitcher.getLocale(),
				Accept: 'application/json'
			})
		};
	}
}
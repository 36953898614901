import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AbstractTable} from '../../abstract/abstract-table';

@Component({
	selector: 'app-data-table',
	templateUrl: './data-table.component.html',
	styleUrls: ['./data-table.component.scss']
})
export class DataTableComponent extends AbstractTable {

	@Input() arrayTab: any[];
	@Input() tabSelectedDefault: '';
	@Input() customEmptyMessage: string = '';
	@Input() haveText?: boolean = false;
	@Input() hasIconTitle?: boolean = true;
	@Input() iconType: string = 'list_blue';
	@Input() checkBoxHeaderAlign: { vertical: string, horizontal: string } = {vertical: '9px', horizontal: '11px'};

	@Output() _onSelectAll = new EventEmitter();

	constructor() {
		super();
	}

	onSelectAll(event) {
		this._onSelectAll.emit(event);
	}

}

import { Component, OnInit, ViewChild, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { MatOption, MatSelect } from '@angular/material';
import { Labels } from 'src/internationalization/labels/labels';
import { CoreUtil } from '../../core-util';
import { ObjectFilterBaseService } from '../../service/object-filter-base.service';
import { Response } from 'src/app/resource/dto/response';
import { Subscription, timer } from 'rxjs';
import { Icons } from 'src/icons';

@Component({
	selector: 'app-dropdown-group-list',
	templateUrl: './dropdown-group-list.component.html',
	styleUrls: ['./dropdown-group-list.component.scss']
})
export class DropdownGroupListComponent implements OnInit, OnChanges {
	@ViewChild('selectInput', { static: false }) selectInput: MatSelect;
	@ViewChild('optionAll', { static: false }) optionAll: MatOption;
	@ViewChild('hiddenInput', { static: false }) hiddenInput;

	@Input() idProperty = 'id';
	@Input() displayProperty: string;
	@Input() auxDisplayProperty: string;
	@Input() placeholder: string;
	@Input() pipe: string;
	@Input() arrayData: any[];
	@Input() microService: string;
	@Input() endPoint: string;
	@Input() showPlaceholderAsDisplay: boolean = false;
	@Input() queryParams: string;
	@Input() disabled: boolean = false;
	@Input() showCheckAllSelect: boolean = true; // to show Selec All or not
	@Input() showSearch: boolean = true; // to show input search or not
	@Input() allSelectionMode: boolean = false;
	@Input() allSelected: boolean = false;
	@Input() overlayAbsolute: boolean = false;
	@Input() multiple: boolean = true;
	@Input() hideListOnEmptyValue = false;
	@Input() customSearchText: string = '';
	@Input() arrayGroupParams: string[];
	@Input() statusProperty: string = 'status';
	@Input() isGroupCheck: boolean = false; // Is not prepared for Select All. Only select all children in group
	@Input() showListItems = false; // Display list of selected items under dropdown
	@Input() chekedValueIfNotHide: boolean = false;
	@Input()
	set value(value: any | any[]) {
		if (value) {
			if (Array.isArray(value)) {
				this._value = this.multiple ? [] : null;
				if (this.isGroupCheck) {
					const valueUpdated = [];

					if (this.arrayData) {
						this.arrayData.forEach(item => {
							if (item.checked) {
								valueUpdated.push(item);
							}

							if (item.list) {
								item.list.forEach(child => {
									if (child.checked) {
										valueUpdated.push(child);
									}
								});
							}
						});
					}

					value = valueUpdated;
				}

				for (const v of value) {
					if (v && v.id != -1) {
						if (this.arrayData && this.auxDisplayProperty) {
							const find = this.arrayData.find(a => a.id == v.id);
							if (find) {
								v[this.auxDisplayProperty] = find[this.auxDisplayProperty];
							}
						}
						if(this.multiple)
							this._value.push(v);
						else
							this._value = v;
					}
				}
			}
		}
	}

	@Output() valueChange = new EventEmitter();
	@Output() selectorOpened = new EventEmitter();
	@Output() allSelectedChange = new EventEmitter();
	@Output() changeSearch = new EventEmitter();
	public _icons = Icons;
	public _labels = Labels.getLabels();
	public _search = '';
	public _value: any | any[] = [];
	public _selectAll = false;
	public _showSelectAll = true;
	public _selectAllValuesArray: any[];
	public _selectAllValue = {
		id: -1,
		description: this._labels.select_all,
		groupParam: ''
	};

	private _dispatcher: Subscription;
	private _unselectAll = false;
	private _selectAllClicked = false;
	private _inputEvent = new Event('input', {
		bubbles: true,
		cancelable: true,
	});

	constructor(private service: ObjectFilterBaseService) { }

	ngOnInit() {
		this.setArray();
		this.fixBooleans();
		this.updateGroupCheck();
		this.setGroupCheckConfig();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes && changes.endPoint && changes.endPoint.currentValue) {
			this.getList(this.queryParams);
		}

		if (changes.customSearchText && changes.customSearchText.currentValue && !changes.customSearchText.isFirstChange) {
			this._search = changes.customSearchText.currentValue;
			this.callGetList();
		}

		if (changes.allSelected) {
			if (!changes.allSelected.currentValue && this.selectInput) {
				this._selectAll = false;
				this._selectAllClicked = true;

				this.changeAllSelected(false);
				this.change([]);
				this.cleanSelectAll();
			}
		}
	}

	setArray() {
		if (this.arrayGroupParams) {
			this._selectAllValuesArray = this.arrayGroupParams
				.map((v, i) => ({ id: -(i + 1), description: this._labels.select_all, groupParam: v }));
		}
	}

	// 'true' is different of true
	private fixBooleans() {
		this.showPlaceholderAsDisplay = this.checkStringBoolean(this.showPlaceholderAsDisplay);
		this.disabled = this.checkStringBoolean(this.disabled);
		this.showCheckAllSelect = this.checkStringBoolean(this.showCheckAllSelect);
		this.allSelectionMode = this.checkStringBoolean(this.allSelectionMode);
		this.allSelected = this.checkStringBoolean(this.allSelected);
		this.overlayAbsolute = this.checkStringBoolean(this.overlayAbsolute);
	}

	private checkStringBoolean(check) {
		return JSON.parse(check);
	}

	callGetList() {
		this.changeSearch.emit(this._search)
		if (this._dispatcher) {
			this._dispatcher.unsubscribe();
		}

		this._dispatcher = timer(150).subscribe(() => {
			this.getList(this.queryParams);
		});
	}

	getList(queryParams: string = '') {
		if (!this.endPoint) {
			this.updateGroupCheck();
			return;
		}
		this.service.getObjectFilterListGroup(this.microService, this.endPoint, this._search, queryParams)
			.subscribe((resp: any) => {
				if (resp && resp.data) {
					this.arrayData = resp.data;

					if (this.arrayData && this.auxDisplayProperty) {
							this.arrayData.forEach((value) => value[this.auxDisplayProperty] = value[this.displayProperty]);
					}

					if (!this.multiple) {
							this._value = this.arrayData.find(a => a.id == this._value.id);
					}

					this.updateGroupCheck();
				}
			})
			.unsubscribe();
	}

	private updateGroupCheck() {
		// Peroli: Same code of the  >@Input() set value(value: any | any[]) < in begin
		if (this.isGroupCheck) {
			const checked = [];

			if (this.arrayData) {
				this.arrayData.forEach(item => {
					if (item.checked) {
						checked.push(item);
					}

					if (item.list) {
						item.list.forEach(itemChild => {
							if (itemChild.checked) {
								checked.push(itemChild);
							}
						});
					}
				});
			}

			this.value = checked;
		}
	}

	private setGroupCheckConfig() {
		// Peroli: the component isn't prepared for check all yet
		if (this.isGroupCheck) {
			this.showCheckAllSelect = false;
			this.allSelectionMode = false;
			this.allSelected = false;
			this.multiple = true;
		}else {
			this.multiple = false;
		}
	}

	cleanSelectAll() {
		if (this.multiple) {
			this.unselectAll(this._value);
		}
	}

	splitProperty(obj: any, property?: string) {
		if (property) {
			return CoreUtil.getSplittedProperty(obj, property);
		} else {
			return CoreUtil.getSplittedProperty(obj, this.auxDisplayProperty ? this.auxDisplayProperty : this.displayProperty);
		}
	}

	unselectAll(event) {
		const all = event.find(v => v.id == -1);
		if (all) {
			event = event.filter(v => typeof v.id === 'string' || v.id != -1);

			this._unselectAll = false;
			this._selectAll = false;
		}
	}

	triggerHiddenInput() {
		this.hiddenInput.nativeElement.dispatchEvent(this._inputEvent);
	}

	change(event) {
		if (!this.multiple) {
			this._value = event;

			this.valueChange.emit(this._value);
		} else {
			this.triggerHiddenInput();
			this.showSelectAll();
			this.changeAllSelected(false);

			setTimeout(() => {
				if (this._selectAll) {
					if (!this._unselectAll) {
						if (this._search || !this.allSelectionMode) {
							event = [{ id: -1, description: this._labels.component.select_all }];

							for (const data of this.arrayData) {
								event.push(Object.assign({}, data));
							}
						} else if (event.filter(e => e.id != -1).length > 0) {
							if (this._value.filter(v => v.id != -1).length == 0) {
								this.unselectAll(event);
							} else {
								event = [];
							}
						}
					} else {
						this.unselectAll(event);
					}
				} else if (this._selectAllClicked) {
					this._selectAllClicked = false;
					event = [];
				}

				if (this.isGroupCheck) {
					this.updateGroupCheck();
				} else {
					this._value = event.filter(e => e.id != -1);
				}

				this.valueChange.emit(this._value);
			});
		}
	}

	selectClick(value) {
		if (value && value.id == -1) {
			this._selectAll = this.optionAll && this.optionAll.selected;

			if (!this._selectAll) {
				this._selectAll = false; // to avoid undefined
				const selectAll = this._value.find(v => v.id == -1);
				if (selectAll) {
					this._selectAllClicked = true;
				}

				this.changeAllSelected(false);
			} else if (!this._search) {
				this.changeAllSelected(true);
			}
		} else {
			this._unselectAll = this._selectAll;
		}
	}

	changeAllSelected(value) {
		this.allSelected = value;

		this.allSelectedChange.emit(this.allSelected);
	}

	onSelectOpen(event) {
		if(this.chekedValueIfNotHide) {
			this._search = "";
		}
		this.selectorOpened.emit(event);
	}

	hideSelectAll() {
		this._selectAll = false;
		this._showSelectAll = false;
	}

	showSelectAll() {
		if (this._dispatcher) {
			this._dispatcher.unsubscribe();
		}

		if (!this._showSelectAll) {
			this._selectAll = true;
		}

		this._showSelectAll = true;
	}

	updateAllComplete(group, item) {
		item.checked = !item.checked;
		group.checked = group.list != null && group.list.every(t => t.checked);
	}

	someComplete(group): boolean {
		if (group.list == null) {
			return false;
		}

		const ret =  group.list.filter(t => t.checked).length > 0 && !group.checked;

		return ret;
	}

	setAll(group) {
		group.checked = !group.checked;

		if (group.list == null) {
			return;
		}

		if(!this.chekedValueIfNotHide) {
			group.list.forEach(t => {
				if (!t.disabled) {
					t.checked = group.checked;
				}
			});
		}else {
			group.list.forEach(t => {
				if (!t.disabled && !t.hide) {
					t.checked = group.checked;
				}
			});
		}
	}

	getPlaceholderValue(): string {
		if (!this.placeholder) return this.placeholder;
		if (!this.showPlaceholderAsDisplay) return this.placeholder;
		const value = this._value;
		if (value) {
			const isArray = Array.isArray(value);
			if (isArray) {
				return value.length > 0 ? null : this.placeholder;
			}
			return null;
		}
		return this.placeholder;
	}

	getValueForListItems() {
		const valueList = this._value.filter((value) => !('list' in value));
		return valueList;
	}

	deleteItem(event) {
		this.changeCheckedStatusInArrayData(event);
		this.removeElementFromValue();
	}

	changeCheckedStatusInArrayData(event) {
		const { element } = event;
		let groupIndex;
		let groupChildIndex;

		groupIndex = this.arrayData.findIndex((data) =>
			data.list.some((child, childIndex) => {
				const validationResult =
					child.description === element.description &&
					child.groupParam === element.groupParam;
				if (validationResult) groupChildIndex = childIndex;
				return validationResult;
			})
		);

		if (groupIndex >= 0) {
			this.updateAllComplete(
				this.arrayData[groupIndex],
				this.arrayData[groupIndex].list[groupChildIndex]
			);
		}
	}

	removeElementFromValue() {
		this._value = this._value.filter((value) => value.checked);
		this.change(this._value);
	}
}
import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DeactivateService} from '../../../../../../../core/service/deactivate.service';
import {AbstractForm} from '../../../../../../../core/abstract/abstract-form';
import {Icons} from '../../../../../../../../icons';

@Component({
	selector: 'app-prefix-tax-id-list-modal',
	templateUrl: './prefix-tax-id-list-modal.component.html',
	styleUrls: ['./prefix-tax-id-list-modal.component.scss']
})
export class PrefixTaxIdListModalComponent extends AbstractForm implements OnInit {
	dialogConfig: { title: string; icon: string; };
	listTaxId: any[] = [];
	disableScroll = true;
	public icons = Icons;


	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		public dialogRef: MatDialogRef<PrefixTaxIdListModalComponent>,
		public deactivateService: DeactivateService,
	) {
		super();
		this.listTaxId = this.data.relatedTaxIds;
	}

	ngOnInit(): void {
		this.dialogConfig = {
			title: 'Associated Tax IDs',
			icon: 'new_eye'
		};
	}

	onClose() {
		this.deactivateService.close(this, this.dialogRef);
	}

	protected readonly Icons = Icons;
}

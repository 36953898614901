import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ObjectMultiFilter} from '../../dto/object-multi-filter.dto';
import {Icons} from '../../../../icons';
import {Labels} from '../../../../internationalization/labels/labels';

@Component({
  selector: 'app-dropdown-itens',
  templateUrl: './dropdown-itens.component.html',
  styleUrls: ['./dropdown-itens.component.scss']
})
export class DropdownItensComponent implements OnInit {

	icons = Icons.icon;
	_labels = Labels.getLabels();

	@Input() filter: ObjectMultiFilter;
	@Output() removeDropdownValueEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

	removeDropdownValue(filterValue, i) {
		filterValue.arrayValue.splice(i, 1);
		const array = [];

		for (const a of filterValue.arrayValue) {
			array.push(a);
		}

		filterValue.arrayValue = array;
		this.removeDropdownValueEvent.emit();
	}

}

import { LocaleSwitcher } from 'src/internationalization/locale-switcher';

export class Messages {
	public static messagesPtBr = require('./messages_pt-br.json');
	public static messagesEnUs = require('./messages_en-us.json');
	public static messagesEsEs = require('./messages_es-es.json');

	public static getMessages(locale = LocaleSwitcher.getLocale()) {

		switch (locale) {
			case 'pt':
			case 'pt-br':
				return this.messagesPtBr;
			case 'en':
			case 'en-us':
				return this.messagesEnUs;
			case 'es':
			case 'es-es':
				return this.messagesEsEs;
		}
	}
}
export class MathUtil {

	public static multiply(value: any, value2: any, decimalPlaces = 0): number {
		value = this.valueToNumber(value, decimalPlaces);
		value2 = this.valueToNumber(value2, decimalPlaces);
		value = (parseFloat(value) * parseFloat(value2)).toFixed(decimalPlaces);
		return parseFloat(value);
	}

	public static divide(value: any, value2: any, decimalPlaces = 0): number {
		value = this.valueToNumber(value, decimalPlaces);
		value2 = this.valueToNumber(value2, decimalPlaces);
		value = (parseFloat(value) / parseFloat(value2)).toFixed(decimalPlaces);
		return parseFloat(value);
	}

	public static subtract(value: any, value2: any, decimalPlaces = 0): number {
		value = this.valueToNumber(value, decimalPlaces);
		value2 = this.valueToNumber(value2, decimalPlaces);
		value = (parseFloat(value) - parseFloat(value2)).toFixed(decimalPlaces);
		return parseFloat(value);
	}

	public static sum(value: any, value2: any, decimalPlaces = 0): number {
		value = this.valueToNumber(value, decimalPlaces);
		value2 = this.valueToNumber(value2, decimalPlaces);
		value = (parseFloat(value) + parseFloat(value2)).toFixed(decimalPlaces);
		return parseFloat(value);
	}

	public static valueToNumber(value: any, decimalPlaces = 0): number {
		if (value) {
			if(decimalPlaces > 0){
				let decimalPlaceReg = new RegExp(`([0-9]{${decimalPlaces}})$`, 'g');
				value = value.toString().replace(",", "").replace(".", "").replace(decimalPlaceReg, `.$1`);
				value = parseFloat(value).toFixed(decimalPlaces);
			}
			return  parseFloat(value);
		}
		return 0;
	}
}
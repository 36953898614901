import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'app-select-filter',
	templateUrl: './select-filter.component.html',
	styleUrls: ['./select-filter.component.scss']
})
export class SelectFilterComponent implements OnInit {
	@Input() arrayData: any[];
	@Input() id = 'id';
	@Input() display = 'description';
	@Input() pipe: string;
	@Input() placeholder: string;
	@Input() filter: any;
	@Input() value: any;

	@Output() filterChange = new EventEmitter();
	@Output() valueChange = new EventEmitter();
	@Output() trigger = new EventEmitter();

	constructor() { }

	ngOnInit() { }

	changeFilter(event) {
		this.filter = event;
		this.filterChange.emit(event);

		this.value = this.filter.type == 'period' ? [] : undefined;
		this.doTrigger();
	}

	changeValue(event) {
		this.value = event;
		this.valueChange.emit(event);
	}

	changeValueArray(event,pos) {
		this.value[pos] = event;
		this.valueChange.emit(this.value);
	}

	doTrigger() {
		if (this.filter && this.value && (!Array.isArray(this.value) || this.value.length > 0)) {
			if (this.filter.type != 'period' || this.value[0] || this.value[1]) {
				this.trigger.emit();
			}
		}
	}
}
import { Directive, Input, Output, EventEmitter, ElementRef, HostListener, SimpleChanges, forwardRef } from '@angular/core';
import { NG_VALIDATORS, AbstractControl, Validator } from '@angular/forms';
import { Colors } from 'src/colors';

@Directive({
	selector: '[appTaxId]',
	providers: [
		{ provide: NG_VALIDATORS, useExisting: forwardRef(() => TaxIdDirective), multi: true }
	]
})
export class TaxIdDirective implements Validator {
	@Input() ngModel: string;
	@Output() ngModelChange = new EventEmitter();

	private control: AbstractControl;
	private lastValue: string;

	constructor(private element: ElementRef) { }

	validate(c: AbstractControl): { [key: string]: any } {
		this.control = c;
		this.element.nativeElement.parentElement.style.backgroundColor = '';

		return null;
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.ngModel && changes.ngModel.currentValue && this.ngModel != this.lastValue) {
			const event = {target: {value: changes.ngModel.currentValue}};
			this.onInput(event);
		}
	}

	@HostListener( 'input', ['$event'] )
	onInput( $event: any ) {
		let value = $event.target.value;
		if (value) {
			value = value.replace(/\D/g, '').substring(0,14);

			if (value.length > 2) {
				if (value.length < 6) {
					value = value.substring(0,5).replace(/^(\d{2})?(\d{3})?/, '$1.$2');
				} else if (value.length < 9) {
					value = value.substring(0,8).replace(/^(\d{2})?(\d{3})?(\d{3})?/, '$1.$2.$3');
				} else if (value.length < 13) {
					value = value.substring(0,12).replace(/^(\d{2})?(\d{3})?(\d{3})?(\d{4})?/, '$1.$2.$3/$4');
				} else {
					value = value.substring(0,14).replace(/^(\d{2})?(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, '$1.$2.$3/$4-$5');
				}
			}
			this.doChange(value);
		}
	}

	@HostListener( 'blur', ['$event'] )onBlur( $event: any ) {
		let value = $event.target.value;

		if (this.control && this.control.value) {
			value = value.replace(/\D/g, '');

			if (value && (this.doValidate(value) != value || value.length < 14)) {
				this.element.nativeElement.parentElement.style.backgroundColor = Colors.color.red_error_background;
				this.control.setErrors({taxId: false});

				return;
			}

			this.element.nativeElement.parentElement.style.backgroundColor = '';
			this.control.setErrors(null);
		}
	}

	doChange(value) {
		setTimeout(() => {
			this.element.nativeElement.value = value;
			value = value.replace(/\D/g, '');
			this.lastValue = value;
			this.ngModel = value;
			this.ngModelChange.emit(value);
		}, 15);
	}

	doValidate(value) {
		const rtn = value.substring(0,value.length - 1);

		if (value == '') return '';

		if (value.length != 14) return value;

		if (value == '00000000000000' ||
				value == '11111111111111' ||
				value == '22222222222222' ||
				value == '33333333333333' ||
				value == '44444444444444' ||
				value == '55555555555555' ||
				value == '66666666666666' ||
				value == '77777777777777' ||
				value == '88888888888888' ||
				value == '99999999999999') {
			return rtn;
		}

		length = value.length - 2;
		let numbers = value.substring(0,length);
		const digits = value.substring(length);
		let sum = 0;
		let pos = length - 7;

		for (let i = length; i >= 1; i--) {
			sum += numbers.charAt(length - i) * pos--;

			if (pos < 2) pos = 9;
		}

		let result = sum % 11 < 2 ? 0 : 11 - sum % 11;

		if (result != digits.charAt(0)) return rtn;

		length = length + 1;
		numbers = value.substring(0,length);
		sum = 0;
		pos = length - 7;

		for (let i = length; i >= 1; i--) {
			sum += numbers.charAt(length - i) * pos--;

			if (pos < 2) pos = 9;
		}

		result = sum % 11 < 2 ? 0 : 11 - sum % 11;

		if (result != digits.charAt(1)) return rtn;

		return value;
	}
}
import { Pipe, PipeTransform } from '@angular/core';
import {enumTransform} from '../../shared/helpers/pipe-function/enum-transform';

@Pipe({
	name: 'enum'
})
export class EnumPipe implements PipeTransform {
	transform(value: string): string {
		return enumTransform(value);
	}
}
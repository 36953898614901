import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'cpfCnpj'
})
export class CpfCnpjPipe implements PipeTransform {
	transform(value: any): any {
		if(this.isNumeric(value)) {
			if (value && value.length > 2 && value != "N/A") {
				const additionalValue = value.substring(14);

				value = value.replace(/\D/g, '').substring(0, 14);

				if (value.length <= 6) {
					value = value.replace(/^(\d{3})?(\d{3})?/, '$1.$2');
				} else if (value.length <= 9) {
					value = value.replace(/^(\d{3})?(\d{3})?(\d{3})?/, '$1.$2.$3');
				} else if (value.length < 12) {
					value = value.replace(/^(\d{3})?(\d{3})?(\d{3})?(\d{2})?/, '$1.$2.$3-$4');
				} else if (value.length < 13) {
					value = value.replace(/^(\d{2})?(\d{3})?(\d{3})?(\d{4})?/, '$1.$2.$3/$4');
				} else {
					value = value.replace(/^(\d{2})?(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, '$1.$2.$3/$4-$5');
				}

				value += additionalValue;
			}
			return value;
		} else {
			return value;
		}

	}
	isNumeric(value: any): boolean {
		return (/^[0-9]+$/.test(value) ||  /^\d{14}\s\(\+1\)$/.test(value));
	}
}


import { Component, OnInit, Input } from '@angular/core';
import { Icons } from 'src/icons';

@Component({
	selector: 'app-card-information',
	templateUrl: './card-information.component.html',
	styleUrls: ['./card-information.component.scss']
})
export class CardInformationComponent implements OnInit {
	@Input() icon: string;
	@Input() title: string;
	@Input() mainPaddingBottom: string = ' 1rem';

	public _icons = Icons;

	constructor() { }

	ngOnInit() {
	}
}

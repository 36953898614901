import {Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-input-count',
  templateUrl: './input-count.component.html',
  styleUrls: ['./input-count.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => InputCountComponent),
			multi: true
		}
	]
})
export class InputCountComponent implements ControlValueAccessor {

	value: any;
	onChange: (value: any) => void;
	onTouched: () => void;

	@Input() placeholder: string;
	@Input() maxlength: string;
	@Input() minlength: string;
	@Input() disabled = false;
	@Input() mask: string;

	writeValue(value: any): void {
		this.value = value;
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	getAccountant(): number {
		if (this.value) {
			return this.value.toString().length;
		}
		return 0;
	}
}

import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { Icons } from 'src/icons';

@Component({
	selector: 'app-chekbox-list',
	templateUrl: './chekbox-list.component.html',
	styleUrls: ['./chekbox-list.component.scss']
})
export class ChekboxListComponent implements OnInit {
	@Input() title: string;
	@Input() label: string;
	@Input() icon: string;
	@Input() arrayList: any[];
	@Input() activedList: any[];
	@Input() id: string = 'id';
	@Input() display: string = 'description';
	@Input() readonly: boolean = false;
	@Input() disabled: boolean = false;
	@Input() noPadding = false;
	@Output() activedListChange = new EventEmitter();

	public _icons = Icons;

	constructor() {
	}

	ngOnInit() {
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.activedList && changes.activedList.currentValue && changes.activedList.currentValue.length > 0) {
			this.cleanAll();
			for (const actived of this.activedList) {
				const item = this.arrayList.find(a => a[this.id] == actived[this.id]);
				if (item) {
					item.checked = true;
				}
			}
		} else {
			this.activedList = [];
			this.cleanAll();
		}
	}

	cleanAll() {
		if (this.arrayList) {
			for (const item of this.arrayList) {
				item.checked = false;
			}
		}
	}

	updateActivedList() {
		this.activedList = [];

		for (const type of this.arrayList) {
			if (type.checked) {
				const object = {};
				object[this.id] = type[this.id];
				object[this.display] = type.description;
				this.activedList.push(object);
			}
		}

		this.activedListChange.emit(this.activedList);
	}
}
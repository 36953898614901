export default {
	redirectUri: 'https://intercab-qa.alianca.com.br/',
	apiUri: 'https://api-stage.alianca.com.br/intercab',
	apiMaerskUri: 'https://api-stage.maersk.com/intercab',
	fileReference: 'none.ts',
	ambient: 'qa' as const,
	version: '1.8.0',
	consumerkey: 'VWxDVTDvCoMG4QZShAQd4svboaTuwIPR',
	instrumentationKey: '30287add-fbe6-4f4e-a51a-c04a3b7f5f94',
	azfUri: 'https://faazbsrg2qhsud001upf.azurewebsites.net',
	commercialUri:
		'https://api-stage.alianca.com.br/intercab' + '/commercial/v1/commercial/'
};

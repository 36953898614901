import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'taxId'
})
export class TaxIdPipe implements PipeTransform {
	transform(value: any): any {
		if (value && value.length > 2) {
			if(value == 'N/A') return 'N/A';
			if (value.length < 6) {
				value = value.substring(0,5).replace(/^(\d{2})?(\d{3})?/, '$1.$2');
			} else if (value.length < 9) {
				value = value.substring(0,8).replace(/^(\d{2})?(\d{3})?(\d{3})?/, '$1.$2.$3');
			} else if (value.length < 13) {
				value = value.substring(0,12).replace(/^(\d{2})?(\d{3})?(\d{3})?(\d{4})?/, '$1.$2.$3/$4');
			} else {
				value = value.substring(0,14).replace(/^(\d{2})?(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, '$1.$2.$3/$4-$5');
			}
		}

		return value;
	}
}
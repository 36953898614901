import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { Labels } from '../../../../internationalization/labels/labels';
@Component({
  selector: 'app-operation-type',
  templateUrl: './operation-type.component.html',
  styleUrls: ['./operation-type.component.scss']
})
export class OperationTypeComponent {

	@Input() form: any;
	@Input() formIsValid?: any;
	@Input() operationTypeList: any[] = [];
	@Input() disabledToggle: any;
	@Input() executionDTOListHasValue: number;
	@Input() placeholder: string;
	@Input() matTooltip: any;
	@Input() arrayDataDropdown: any;
	@Input() disabledDropdown: any;
	@Input() valueDropdown: any;
	@Input() operationTypeIsDoor: any;

	@Output() valueChangeDropdown = new EventEmitter();
	@Output() valueChangeToggle = new EventEmitter();


	labels = Labels.getLabels();

	valueChangeExecution(event){
		this.valueChangeDropdown.emit(event);
	}

	valueChangeDoor(event, index) {
		this.valueChangeToggle.emit({isChecked: event.checked, index: index})
	}

}

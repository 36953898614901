import { Directive, Input, Output, EventEmitter, ElementRef, HostListener, SimpleChanges } from '@angular/core';

@Directive({
	selector: '[appPhone]'
})
export class PhoneDirective {
	@Input() ngModel: string;
	@Input() isMobile = false;
	@Input() isTelMobile = false;
	@Output() ngModelChange = new EventEmitter();

	private isLoaded = false;

	constructor(private element: ElementRef) { }

	ngOnChanges(changes: SimpleChanges) {
		if (changes.ngModel && changes.ngModel.currentValue && !this.isLoaded) {
			this.isLoaded = true;
			this.onInput({target: {value: this.ngModel}});
		}
	}

	@HostListener( 'input', ['$event'] )
	onInput( $event: any ) {
		let value = $event.target.value;

		if (value) {
			value = value.replace(/\D/g, '').substring(0,!this.isMobile ? 12 : 13);

			if (value.length < 3) {
				value = value.replace(/^(\d{2})?/, '+$1');
			} else if (value.length < 5) {
				value = value.replace(/^(\d{2})?(\d{2})?/, '+$1 $2');
			} else if (!this.isMobile) {
				if (value.length < 9) {
					value = value.replace(/^(\d{2})?(\d{2})?(\d{4})?/, '+$1 $2 $3');
				} else {
					value = value.replace(/^(\d{2})?(\d{2})?(\d{4})?(\d{4})?/, '+$1 $2 $3-$4');
				}
			} else if (value.length < 10) {
				value = value.replace(/^(\d{2})?(\d{2})?(\d{5})?/, '+$1 $2 $3');
			} else if (value.length == 12) {
				value = value.replace(/^(\d{2})?(\d{2})?(\d{4})?(\d{4})?/, '+$1 $2 $3-$4');
			} else {
				value = value.replace(/^(\d{2})?(\d{2})?(\d{5})?(\d{4})?/, '+$1 $2 $3-$4');
			}
		}

		this.doChange(value);
	}

	doChange(value) {
		setTimeout(() => {
			this.element.nativeElement.value = value;
			this.ngModelChange.emit(value);
		}, 15);
	}

	@HostListener( 'focus', ['$event'] )onFocus( $event: any ) {
		const value = $event.target.value;
		if (!value) {
			this.doChange('+55');
		}
	}

	@HostListener( 'blur', ['$event'] )onBlur( $event: any ) {
		const value = $event.target.value;

		if (!value) {
			this.doChange('');
		}

		if (value.replace(/\D/g, '').length < 12) {
			this.doChange('');
		}

		if (value.replace(/\D/g, '').length < 13 && this.isMobile && !this.isTelMobile) {
			this.doChange('');
		}

		if (value.replace(/\D/g, '').length < 12 && this.isMobile && this.isTelMobile) {
			this.doChange('');
		}
	}
}
import { Directive, Input, Output, EventEmitter, ElementRef, HostListener, SimpleChanges } from '@angular/core';

@Directive({
	selector: '[appOffsetHeight]'
})
export class OffsetHeightDirective {
	@Input() height;
	@Output() heightChange = new EventEmitter();

	constructor(private element: ElementRef) { }

	ngAfterViewInit() {
		this.schedule();
	}

	@HostListener('window:resize')
	onResize() {
		this.schedule();
	}

	ngOnChanges(change: SimpleChanges) {
		this.schedule();
	}

	schedule() {
		setTimeout(() => {
			if (this.height != this.element.nativeElement.offsetWidth) {
				this.height = this.element.nativeElement.offsetHeight;
				this.heightChange.emit(this.height);
			}
		}, 1);
	}
}
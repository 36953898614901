
export class ObjectUtils {
	public static isEqual(obj1: any, obj2: any){
        const keys = Object.keys(obj1);
        const values = Object.values(obj1);
        const values2 = Object.values(obj2);
        const keys2 = Object.keys(obj2);
        let equals = true;

        if(keys.length != keys2.length){
            return false;
        }

        for(let i = 0; i < keys.length; i++){
            if(keys[i] != keys2[i] || values[i] != values2[i]){
                equals = false;
                break;
            }
        }

        return equals;
    }
}
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-grid-filter-tab-button',
  templateUrl: './grid-filter-tab-button.component.html',
  styleUrls: ['./grid-filter-tab-button.component.scss']
})
export class GridFilterTabButtonComponent {
  @Input() label: string;
  @Input() isActive: boolean = false;
  @Output() tabSelected = new EventEmitter<string>();

  onClick() {
    this.tabSelected.emit(this.label);
  }
}
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Icons } from 'src/icons';
import { Labels } from 'src/internationalization/labels/labels';
import { Messages } from 'src/internationalization/messages/messages';

@Component({
	selector: 'app-confirm',
	templateUrl: './confirm.component.html',
	styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {
	public _labels = Labels.getLabels();
	public _messages = Messages.getMessages();
	public _icons = Icons;

	constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<ConfirmComponent>) {
	}

	ngOnInit() {
	}

	close() {
		this.dialogRef.close();
	}

	confirm() {
		this.dialogRef.close({ confirm: true });
	}
}
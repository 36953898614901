import { Component, OnInit, ViewChild, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { CoreUtil } from '../../core-util';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Labels } from 'src/internationalization/labels/labels';
import { Icons } from 'src/icons';

@Component({
	selector: 'app-drag-and-drop',
	templateUrl: './drag-and-drop.component.html',
	styleUrls: ['./drag-and-drop.component.scss']
})
export class DragAndDropComponent implements OnInit, OnChanges {
	@ViewChild('hiddenInput', { static: false }) hiddenInput;
	@ViewChild('arrayDropList', { static: false }) arrayDropList;
	@ViewChild('activeDropList', { static: false }) activeDropList;

	@Input() title: string;
	@Input() activedTitle: string;
	@Input() idPropertyName: string;
	@Input() displayProperties: string[];
	@Input() arrayList: any[];
	@Input() activedList: any[];
	@Input() widths: number[];
	@Input() readonly = false;
	@Input() allowEdit = false;
	@Input() pipe: string;
	@Input() singleColumn = true;
	@Input() noSelectionMessage: string;
	@Input() validateCurrentDateMessage: string;
	@Input() useDragHandler = false;
	@Input() keepOrderAfterRemove: boolean = false;
	@Input() sortParamLeftList: string = '';

	@Output() activedListChange = new EventEmitter();
	@Output() change = new EventEmitter();

	public _labels = Labels.getLabels();
	public _icons = Icons;
	public _closeIcon = this._icons.icon.close;
	public _dragHandlerIconSrc = this._icons.getIconPath(this._icons.icon.drag_handler);
	public _editMode: boolean[] = [];

	private _arrayListRef: any[];
	// unused code found 19/03/2021
	// private _activedListRef: any[];
	// unused code found 19/03/2021
	// private _inputEvent = new Event('input', {
	// 	bubbles: true,
	// 	cancelable: true,
	// });

	constructor() { }

	ngOnInit() {
		this.orderLeftList();
	}

	ngOnChanges(changes: SimpleChanges) {
		for (const name in changes) {
			const change = changes[name];
			switch (name) {
				case 'arrayList':
					if (change.currentValue) {
						this._arrayListRef = change.currentValue.filter(obj => true);
					}
					break;
				// unused code found 19/03/2021
				// case 'activedList':
				// 	if (change.currentValue) {
				// 		this._activedListRef = change.currentValue.filter(obj => true);
				// 	}
				// 	break;
			}
		}
	}

	drop(event: CdkDragDrop<any[]>) {
		if (event.previousContainer === event.container) {
			moveItemInArray(
				event.container.data,
				event.previousIndex,
				event.currentIndex);
		} else {
			transferArrayItem(
				event.previousContainer.data,
				event.container.data,
				event.previousIndex,
				event.currentIndex);

			this.orderLeftList();
		}
	}

	add(list: any[]) {
		for (let index = list.length - 1; index >= 0; index--) {
			const data = list[index];
			if (data.checked) {
				transferArrayItem(
					this.arrayList,
					this.activedList,
					index,
					this.activedList.length
				);
			}
		}
	}

	remove(item: any, index: number) {
		item.checked = false;
		const targetIndex = this.keepOrderAfterRemove ?
			this._arrayListRef.indexOf(item) :
			-1;
		transferArrayItem(
			this.activedList,
			this.arrayList,
			index,
			targetIndex > -1 ? targetIndex : this.arrayList.length
		);

		this.orderLeftList();
	}

	orderLeftList() {
		if (this.sortParamLeftList != '' && this.sortParamLeftList != undefined && this.arrayList != undefined) {
			this.arrayList = this.doSort(this.arrayList);
		}
	}

	private doSort(arrayList: any[]): any[] {
		return arrayList.sort((item1, item2) => {
			item1.checked = false;
			item2.checked = false;

			if (item1[this.sortParamLeftList] > item2[this.sortParamLeftList]) {
				return 1;
			}

			if (item1[this.sortParamLeftList] < item2[this.sortParamLeftList]) {
				return -1;
			}

			return 0;
		});
	}

	splitProperty(obj: any, property: string) {
		return CoreUtil.getSplittedProperty(obj, property);
	}
}
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'numberFormater'
})
export class NumberFormaterPipe implements PipeTransform {

	transform(value: any): any {
		if (value && value.toString().length >= 4) {
			value = value.toString().replace(/\D/g, '');
			if (value.length == 4) {
				value = value.substring(0,4).replace(/^(\d{1})?(\d{3})?/, '$1.$2');
			} else if (value.length <= 5) {
				value = value.substring(0,5).replace(/^(\d{2})?(\d{3})?/, '$1.$2');
			} else if (value.length <= 6) {
				value = value.substring(0,6).replace(/^(\d{3})?(\d{3})?/, '$1.$2');
			} else if (value.length <= 9) {
				value = value.substring(0,9).replace(/^(\d{3})?(\d{3})?(\d{3})?/, '$1.$2.$3');
			}
		}

		return value;
	}

}

import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
	name: 'negative_and_large_pipe'
})
export class negative_number_and_large_pipe implements PipeTransform {
	transform(value: any): any {
		if (value) {
			value = value.toString();
			if (value.substring(0, 1) == '-') {
				const array = value.split('-');
				value = '(' + array[1] + ')';
			}
			if (value.length > 9 && !value.includes('(') || value.includes('(') && value.length > 11) {
				value = '######';
			}
		}

		return value;
	}
}
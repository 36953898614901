export default {
	  redirectUri: 'https://intercab.alianca.com.br/',
    apiUri: 'https://api.alianca.com.br/intercab',
	  apiMaerskUri: 'https://api.maersk.com/intercab',
    fileReference: 'environment.prod.ts',
	  ambient: 'prod' as const,
    version: '1.7.0',
    consumerkey: 'oAYLB1lpDPv4LLKQ0h1Vw4hxwK5XBXL8',
    instrumentationKey: '0c122a13-be1a-4afe-b9b2-34d1648ebd86',
    azfUri: 'https://api.alianca.com.br/intercab/function/uploadfilestoblob',
		commercialUri: 'https://api.alianca.com.br/intercab' + '/commercial/v1/commercial/',
}
import { Component, OnInit, ViewChild, Input, Output, EventEmitter, Injector, HostListener, OnDestroy, AfterViewInit } from '@angular/core';
import { CoreUtil } from '../../core-util';
import { Overlay, OverlayRef, PositionStrategy } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { RadioButtonListContentComponent, RADIO_BUTTON_LIST_CONTAINER_DATA } from './radio-button-list-content/radio-button-list-content.component';

@Component({
	selector: 'app-radio-button-list',
	templateUrl: './radio-button-list.component.html',
	styleUrls: ['./radio-button-list.component.scss']
})
export class RadioButtonListComponent implements OnInit, OnDestroy, AfterViewInit {
	@ViewChild('hiddenInput', { static: false }) hiddenInput;
	@ViewChild('inputField', { static: false }) inputField;

	@Input() idProperty = 'id';
	@Input() displayProperty: string = 'description';
	@Input() auxDisplayProperty: string = '';
	@Input() placeholder: string;
	@Input() pipe: string;
	@Input() value: any;
	@Input() microService: string;
	@Input() endPoint: string;
	@Input() queryParams: string;
	@Input() disabled: boolean = false;
	@Input() excludingArray: any[] = [];
	@Input() preData: any[] = [];
	@Input() dataTransformer: any[];

	@Output() valueChange = new EventEmitter();

	private overlayRef: OverlayRef;
	private overlayPosition: PositionStrategy;
	private formComponentPortal: ComponentPortal<RadioButtonListContentComponent>;
	private _hasAttached = false;
	public _arrayData: any[];

	private _subscriptionList: any;
	private _inputEvent = new Event('input', {
		bubbles: true,
		cancelable: true,
	});

	constructor(private overlay: Overlay, private injector: Injector) { }

	ngOnInit() { }

	ngAfterViewInit() {
		this.overlayRef = this.overlay.create({
			positionStrategy: this.getOverlayPosition(),
			width: this.inputField.element.nativeElement.offsetWidth,
			hasBackdrop: true,
			backdropClass: 'cdk-overlay-transparent-backdrop'
		});

		const subscription = this.overlayRef.backdropClick().subscribe(() => {
			this.overlayRef.detach();
		});

		this._subscriptionList = CoreUtil.incrementSubscriptionList(this._subscriptionList, subscription);
	}

	splitProperty(obj: any) {
		return CoreUtil.getSplittedProperty(obj, this.displayProperty);
	}

	change(event) {
		this.value = event;

		this.valueChange.emit(event);
		this.triggerHiddenInput();
	}

	triggerHiddenInput() {
		this.hiddenInput.nativeElement.dispatchEvent(this._inputEvent);
	}

	show() {
		if (!this.overlayRef.hasAttached()) {
			this.formComponentPortal = new ComponentPortal(RadioButtonListContentComponent, null,
				this.createInjector());
			this.overlayRef.attach(this.formComponentPortal);

			if (!this._hasAttached) {
				this._hasAttached = true;
			}
		} else {
			this.overlayRef.detach();
		}
	}

	getOverlayPosition(): PositionStrategy {
		this.overlayPosition = this.overlay.position()
			// tslint:disable-next-line: deprecation
			.connectedTo(
				this.inputField.element,
				{ originX: 'start', originY: 'top' },
				{ overlayX: 'start', overlayY: 'top' }
			);

		return this.overlayPosition;
	}

	createInjector(): PortalInjector {
		const dataToPass = {
			parent: this,
			value: this.value,
			idProperty: this.idProperty,
			displayProperty: this.displayProperty,
			auxDisplayProperty: this.auxDisplayProperty,
			dataTransformer: this.dataTransformer,
			endPoint: this.endPoint,
			microService: this.microService,
			queryParams: this.queryParams,
			pipe: this.pipe,
			excludingArray: this.excludingArray,
			preData: this.preData,
		};

		const injectorTokens = new WeakMap();
		injectorTokens.set(RADIO_BUTTON_LIST_CONTAINER_DATA, dataToPass);

		return new PortalInjector(this.injector, injectorTokens);
	}

	triggerText() {
		return !this.value ?
			undefined :
			this.dataTransformer ?
				this.transformText(this.value, this.dataTransformer) :
				this.splitProperty(this.value);
	}

	transformText(value: any, dataTransformer: any[],) {
		if (value[this.idProperty]) {
			value = (dataTransformer.length > 1) ?
				dataTransformer[0](value, ...dataTransformer.slice(1)) :
				dataTransformer[0](value);

			return this.value[this.auxDisplayProperty] || this.value[this.displayProperty];
		} else { return undefined; }
	}

	@HostListener('window:scroll')
	onWindowScroll() {
		if (this._hasAttached) {
			this.overlayRef.updatePosition();
		}
	}

	@HostListener('window:resize')
	onResize() {
		this.overlayRef.updateSize({ width: this.inputField.element.nativeElement.offsetWidth });
	}

	ngOnDestroy() {
		CoreUtil.unsubscribeSubscriptionList(this._subscriptionList);
	}
}
import {ActivatedRoute, Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {ToastService} from 'src/app/core/service/toast.service';
import {Constants} from '../../../constants';
import {Messages} from '../../../internationalization/messages/messages';
import {LoginService} from '../service/login.service';
import {AccessService} from "../service/access.service";

export type EvaluationTypes = 'strong' | 'normal';

@Injectable({
	providedIn: 'root'
})
export class RoleValidation {
	private _constants = Constants.constant;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private toastService: ToastService,
		private loginService: LoginService,
		private accessService: AccessService
	) {
		if (localStorage.getItem('accesswarning')) {
			localStorage.removeItem('accesswarning');
			this.notifyAccess();
		}
		router.events.subscribe((evt: any) => {
			if (evt.snapshot && evt.snapshot.routeConfig) {
				this.onRouteChange(evt.snapshot);
			}
		});
	}

	public validationTypes = {
		generic: {
			description:
				"Route should have a 'Create' button on it's screen that requires Edit/Management roles for it to be enabled. Allows all users to visualize management. If it's an edit route it should have a validation to it's save button so this works.",
			rules: {
				managementScreen: 'open',
				others: 'closed'
			}
		},
		allClosed: {
			description:
				"Route should have a 'Create' button on it's screen that requires Edit/Management roles for it to be enabled will redirect immediately. If it's an edit route it should have a validation to it's save button so this works.",
			rules: {
				managementScreen: 'closed',
				others: 'closed'
			}
		},
		none: {
			description: 'All open',
			rules: {
				managementScreen: 'open',
				others: 'open'
			}
		}
	};

	public hasRole = (role: string) => {
		return this.loginService.user.roleList.some(
			(item) => (item as unknown as string) === role
		);
	};

	public async hasAccessToRoute(currentRoute) {
		const {rolePool} = currentRoute;
		let user: any = JSON.parse(localStorage.getItem("ic_user"));
		if(!user) return false;
		await this.accessService.setLoginInfo(user);
		if (!this.loginService.user) return;
		if (!this.loginService.user.roleList) return;
		if (!this.loginService.user.roleList.length) return;

		for (const role of rolePool) {
			if (this.hasRole(role)) return true;
		}

		return false;
	}

	private notifyAccess() {
		this.toastService.show(
			this._constants.messageType.error,
			Messages.getMessages().you_are_not_allowed
		);
	}

	private redirect() {
		localStorage.setItem('accesswarning', 'true');
		window.location.href = window.location.origin;
	}

	public onRouteChange({routeConfig}) {
		if (!routeConfig || !routeConfig.rolePool) return;
		if (!this.hasAccessToRoute(routeConfig)) return this.redirect();
	}
}

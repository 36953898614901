import { OnInit, ViewChild } from '@angular/core';
import { Constants } from 'src/constants';
import { Icons } from 'src/icons';
import { Labels } from 'src/internationalization/labels/labels';
import { Messages } from 'src/internationalization/messages/messages';
import { GridListComponent } from '../component/grid-list/grid-list.component';
import { ObjectMultiFilter } from '../dto/object-multi-filter.dto';

import * as moment from 'moment';

export abstract class AbstractGridList implements OnInit {
	@ViewChild(GridListComponent, { static: false }) gridList: GridListComponent;

	public _labels = Labels.getLabels();
	public _messages = Messages.getMessages();
	public _constants = Constants.constant;
	public _icons = Icons.icon;
	public _date = (moment().format("DD/MM/yyyy HH[h]MM a "));
	public _mapFilter: Map<string, ObjectMultiFilter> = new Map<string, ObjectMultiFilter>();

	ngOnInit(): void {
		this.updateLanguage();
	}

	public updateLanguage() {
		this._labels = Labels.getLabels();
		this._messages = Messages.getMessages();
	}

	updateTableHeight() {
		if (this.gridList) {
			this.gridList.updateTableHeight();
		}
	}

	hasFilter(searchText) {
		if (searchText && searchText.trim().length > 0) {
			return true;
		}

		for (const filter of this._mapFilter) {
			const data = filter[1];

			if (!data.filter) {
				if ((data.arrayValue && data.arrayValue.length > 0) || data.value || data.allSelected) {
					return true;
				}
			} else {
				if (data.filter.filter && data.filter.value) {
					return true;
				}
			}
		}

		return false;
	}
}
import {ToastService} from './service/toast.service';
import {Messages} from 'src/internationalization/messages/messages';
import {Pagination} from 'src/app/core/dto/pagination';
import * as XLSX from 'xlsx';
import {Constants} from 'src/constants';
import {Subscription} from 'rxjs';

export class CoreUtil {
	private static _messages = Messages.getMessages();
	private static _constants = Constants.constant;

	public static getMenuWidth(): number {
		return window.document.getElementById('menu').clientWidth;
	}

	public static getToolbarWidth(): number {
		return window.document.getElementById('toolbar').clientWidth;
	}

	public static getToolbarHeight(): number {
		return window.document.getElementById('toolbar').clientHeight;
	}

	public static getMaxWidth(widthWished: number): number {
		const maxWidth = CoreUtil.getToolbarWidth() * 0.8; // 80% max
		if (widthWished <= maxWidth) {
			return widthWished;
		} else {
			return maxWidth;
		}
	}

	public static getMaxHeight(heightWished: number): number {
		const maxHeight = CoreUtil.getToolbarHeight() * 0.8; // 80% max
		if (heightWished <= maxHeight) {
			return heightWished;
		} else {
			return maxHeight;
		}
	}

	public static getSplittedProperty(obj: any, property: string): any {
		if (!property) {
			return obj ? obj[property] : obj;
		}
		if (property.includes('[') && property.includes(']')) {
			return this.getSplittedPropertyList(obj, property);
		}
		const properties = property.split('.');
		if (obj && properties.length > 1) {
			let suffix = '';
			for (let i = 1; i < properties.length; i++) {
				suffix += properties[i];

				if ((i + 1) < properties.length) {
					suffix += '.';
				}
			}

			return CoreUtil.getSplittedProperty(obj[properties[0]], suffix);
		}

		return obj ? obj[property] : obj;
	}

	public static getSplittedPropertyList(obj: any, property: string) {
		let returnAttribute = obj;
		const properties = property.split('.');
		for (let element of properties) {
			if (element.includes('[') && element.includes(']')) {
				const index = element.replace(/\D+/g, "");
				const firstElement = element.substring(0, element.length - (2 + index.length));
				if (index != '') {
					if (returnAttribute[firstElement][index] != null && returnAttribute[firstElement][index] != undefined) {
						returnAttribute = returnAttribute[firstElement][index];
					} else {
						return null;
					}
				} else {
					returnAttribute = returnAttribute[firstElement];
				}

			} else {
				if (returnAttribute[element] != null && returnAttribute[element] != undefined) {
					returnAttribute = returnAttribute[element];
				} else {
					return null;
				}
			}
		}
		return returnAttribute;
	}

	public static isNotEmpty(value: string) {
		return value && value.trim().length > 0;
	}

	public static imageLoader(input, obj, property, type, label, toastService: ToastService) {
		setTimeout(() => {
			if (input && input.target && input.target.value) {
				input.target.value = '';
			}
		}, 100);

		if ((input && input.target && input.target.files[0].type == type) || (input.files && input.files[0].type == type)) {
			const maximumSize = 5;
			if ((input && input.target && input.target.files[0].size / (1e+6) > maximumSize) || (input.files && input.files[0].size / (1e+6) > maximumSize)) {
				const message = this._messages.maximum_size;
				toastService.show(this._constants.messageType.error, message);

				return;
			}

			const reader = new FileReader();
			reader.onload = () => {
				const result = reader.result.toString();
				if (type === 'image/png') {
					if (result.substring(0, 40).indexOf('stream') !== -1) {
						obj[property] = result.replace('data:application/octet-stream;base64', 'data:image/png;base64');
					} else {
						obj[property] = result.replace('data:;base64', 'data:image/png;base64');
					}
				} else if (type === 'image/jpeg') {
					if (result.substring(0, 40).indexOf('stream') !== -1) {
						obj[property] = result.replace('data:application/octet-stream;base64', 'data:image/jpeg;base64');
					} else {
						obj[property] = result.replace('data:;base64', 'data:image/jpeg;base64');
					}
				}
			};

			if (input && input.target && input.target.files[0]) {
				reader.readAsDataURL(new Blob([input.target.files[0]]));
			} else if (input.files && input.files[0].type) {
				reader.readAsDataURL(new Blob([input.files[0]]));
			}

			return;
		} else {
			toastService.show(this._constants.messageType.error, this._messages.png_error_message);
		}
	}

	public static isValidValidity(from, to, toastService: ToastService) {
		const dateFrom = new Date(from);
		const dateTo = new Date(to);
		if (dateFrom.getTime() > dateTo.getTime()) {
			toastService.show(this._constants.messageType.warning, this._messages.invalid_validity);

			return false;
		}

		return true;
	}

	public static exportXLSWithHTML(htmlTable, filName: string, sheetNames: string = 'data') {
		const worksheet: XLSX.WorkSheet = XLSX.utils.table_to_sheet(htmlTable, {
			dateNF: 'dd/mm/yyyy HH:mm',
			cellDates: true,
			raw: false
		});
		const workbook: XLSX.WorkBook = {Sheets: {data: worksheet}, SheetNames: [sheetNames]};
		XLSX.writeFile(workbook, filName + this._constants.extensions.dotXLS);
	}

	public static exportXls(array, arrayHeader, xlsHeader, dataTransformer, accordionColumnList, title, accordionChildColumnList?) {
		const xslData = [];
		for (const row of array) {
			const item = {};
			let rowAux = Object.assign({}, row);

			if (dataTransformer) {
				if (dataTransformer.length > 1) {
					rowAux = dataTransformer[0](rowAux, ...dataTransformer.slice(1));
				} else {
					rowAux = dataTransformer[0](rowAux);
				}
			}

			const vesselOperators = rowAux.vesselOperators || [];

			if (vesselOperators.length === 0) {
				for (const col of arrayHeader) {
					let value;
					value = this.getSplittedProperty(rowAux, col.key);
					item[col.value] = value;
				}

				if (!accordionColumnList) {
					xslData.push(item);
				} else {
					const arrayList = this.getSplittedProperty(rowAux, accordionColumnList);

					if (arrayList && arrayList.length > 0) {
						const hasSubMenu = arrayHeader.filter(ar => ar.subMenu).length > 0;
						let addedSubMenu = false;
						for (const a of arrayList) {
							const aux = Object.assign({}, item);
							const grandChild = this.getSplittedProperty(a, accordionChildColumnList);

							if (hasSubMenu) {
								// Verifica subMenu
								if (!addedSubMenu && arrayList.length > 1 && a != arrayList[0]) {
									const auxSubMenu = Object.assign({}, item);

									for (const col of arrayHeader.filter(ar => ar)) {
										auxSubMenu[col.value] = col.subMenu || '';
									}

									xslData.push(auxSubMenu);
									addedSubMenu = true;
								}
								if (arrayList.length > 1 && a != arrayList[0]) {
									// Filhos
									for (const col of arrayHeader.filter(ar => ar)) {
										if (col.acordeonChild) {
											let value = this.getSplittedProperty(a, col.acordeonChild);
											if (!value && accordionChildColumnList && grandChild) {
												value = this.getSplittedProperty(grandChild[0], col.acordeonChild);
											}
											aux[col.value] = value;
										} else {
											aux[col.value] = '';
										}
									}


								} else {
									// Pai
									for (const col of arrayHeader.filter(ar => ar.acordeonChild)) {
										let value = this.getSplittedProperty(a, col.acordeonChild);
										if (!value && accordionChildColumnList && grandChild) {
											value = this.getSplittedProperty(grandChild[0], col.acordeonChild);
										}
										aux[col.value] = value;
									}
								}
							} else {
								for (const col of arrayHeader.filter(ar => ar.acordeonChild)) {
									let value = this.getSplittedProperty(a, col.acordeonChild);
									aux[col.value] = value;
								}
							}

							xslData.push(aux);
						}
					} else {
						xslData.push(item);
					}
				}
			} else {
				for (const operator of vesselOperators) {
					const item = {};

					for (const col of arrayHeader) {
						if (!col.acordeonChild) {
							let value = this.getSplittedProperty(rowAux, col.key);
							item[col.value] = value;
						}
					}

					// Adiciona os dados do operador de embarcação
					item['Vessel Code'] = operator.vesselCodeMsk;
					item['Operator Code'] = operator.operatorCode;

					xslData.push(item);
				}
			}
		}

		const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(xslData);

		if (xlsHeader) {
			const ref = worksheet['!ref'].split(':');
			const col = ref[1].replace(/\d/g, '');
			const row = Number(ref[1].replace(/\D/g, ''));
			const headers = Object.keys(xlsHeader);

			const keys = Object.keys(worksheet).filter(w => w != '!ref').map(w => w.replace(/\d/g, ''))
				.filter((value, index, self) => {
					return self.indexOf(value) === index;
				}).sort((a, b) => {
					const strA = a.length + a;
					const strB = b.length + b;

					return strA < strB ? 1 : (strA > strB ? -1 : 0);
				});

			for (let i = row; i > 0; i--) {
				for (const key of keys) {
					if (worksheet[key + i]) {
						worksheet[key + (i + headers.length)] = Object.assign({}, worksheet[key + i]);
						worksheet[key + i] = undefined;
					}
				}
			}

			for (let i = 0; i < headers.length; i++) {
				worksheet['A' + (i + 1)] = {t: 's', v: headers[i] + ':'};

				if (typeof xlsHeader[headers[i]] == 'function') {
					worksheet['B' + (i + 1)] = {t: 's', v: xlsHeader[headers[i]]()};
				} else {
					worksheet['B' + (i + 1)] = {t: 's', v: xlsHeader[headers[i]]};
				}
			}

			worksheet['!ref'] = 'A1:' + col + (row + headers.length);
		}

		const workbook: XLSX.WorkBook = {Sheets: {data: worksheet}, SheetNames: ['data']};
		XLSX.writeFile(workbook, title + this._constants.extensions.dotXLS);
	}


	// update pagination, checking pagination for empty data on pages > 1.
	public static updatePagination(pagination: Pagination): Pagination {
		if (pagination.totalData == 0) {
			if (pagination.page > 0 && pagination.totalPages >= 1
				&& pagination.page >= pagination.totalPages) {
				pagination.page = pagination.totalPages - 1;
				if (pagination.page < 0) {
					pagination.page = 0;
				}
			}
		}

		return pagination;
	}

	// return true, case necessary new search for page. And updated pagination.
	public static redoListSearch(pagination: Pagination): { update: boolean, pagination: Pagination; } {
		const ret = {update: false, pagination};
		const paginationRecalc = this.updatePagination(Object.assign({}, pagination));

		if (pagination.page != paginationRecalc.page) {
			ret.update = true;
			ret.pagination = paginationRecalc;
		}

		return ret;
	}

	public static incrementSubscriptionList(subscriptionList: Subscription[], subscription: Subscription) {
		if (!subscriptionList) {
			subscriptionList = [];
		}

		if (!subscriptionList.some(sub => sub === subscription)) {
			subscriptionList.push(subscription);
		}

		return subscriptionList;
	}

	public static unsubscribeSubscriptionList(subscriptionList: Subscription[]) {
		if (subscriptionList) {
			subscriptionList.forEach(sub => {
				if (sub) {
					sub.unsubscribe();
				}
			});
		}
	}

	public static checkInputError(input: any, isError: boolean, errorColor: string) {
		if (isError) {
			input.style.backgroundColor = errorColor;
		} else {
			input.style.backgroundColor = '';
		}

		return isError;
	}

	public static sortObjectParams(obj: any): any {
		const returnObj = {};
		for (const key of Object.keys(obj).sort()) {
			returnObj[key] = obj[key];
		}

		return returnObj;
	}

	public static removeItemFromArray(array: any[], atribute: string, atributeValue: any): any[] {
		const removeIndex = array.find(item => item[atribute] === atributeValue);
		const retorno = array.splice(removeIndex, 0);

		return retorno;
	}

	public static areDifferentArrays(array1: any[], array2: any[]): boolean {
		return JSON.stringify(array1) !== JSON.stringify(array2);
	}

	public static transformObjectInStringParams(obj: any): string {
		let queryParams = '';
		for (const property in obj) {
			queryParams = queryParams + property + "=" + obj[property] + "&";
		}
		return queryParams.substring(0, queryParams.length - 1);
	}

	// generates ordered, numeric arrays, similarly to range() of Python language.
	// Call: CoreUtil.range(<number>)
	public static range = (n: number) => [...Array(n).keys()];
}
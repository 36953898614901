import { LocaleSwitcher } from 'src/internationalization/locale-switcher';

export class Errors {
	public static errorsPtBr = require('./errors_pt-br.json');
	public static errorsEnUs = require('./errors_en-us.json');
	public static errorsEsEs = require('./errors_es-es.json');

	public static getErrors(locale = LocaleSwitcher.getLocale()) {

		switch (locale) {
			case 'pt':
			case 'pt-br':
				return this.errorsPtBr;
			case 'en':
			case 'en-us':
				return this.errorsEnUs;
			case 'es':
			case 'es-es':
				return this.errorsEsEs;
		}
	}
	
	public static getMessageByError(error: string) {
		let errors = this.getErrors();
		let errorDescription: string = error;
		
		if(errors[error]){
			errorDescription = errors[error]["descriprion"];
		}else{
			const errorsObj: {"message": string, "descriprion": string}[] = Object.values(errors);
			errorsObj.forEach(errorObj => {
				if(errorObj["message"] && errorObj["message"].toLocaleLowerCase() == error.toLocaleLowerCase()){
					errorDescription = errorObj["descriprion"];
					return;
				}
			});
		}
	
		return errorDescription;

	}
}
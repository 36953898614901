import { Component, OnInit, Input } from '@angular/core';
import { Labels } from 'src/internationalization/labels/labels';
import { Icons } from 'src/icons';

@Component({
	selector: 'app-text-list-display',
	templateUrl: './text-list-display.component.html',
	styleUrls: ['./text-list-display.component.scss']
})
export class TextListDisplayComponent implements OnInit {
	@Input() pipe: string;
	@Input() label: string;
	@Input() value: string[];
	@Input() showAll = true;
	@Input() badgeMode = false;
	// NOTE: to displaySelectAllBadge mode works, you NEED to pass the listFullSize value
	@Input() displaySelectAll = false;
	@Input() listFullSize: number;

	public _labels = Labels.getLabels();
	public _icons = Icons;

	constructor() { }

	ngOnInit() { }

	showAllSelectOrVal(val, value, i: number) {
		if (this.displaySelectAll && i === 0 && !this.showAll && value.length >= this.listFullSize) {
			return this._labels.component.all_selected;
		}

		return val;
	}
}
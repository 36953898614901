import { Directive, Input, Output, EventEmitter, ElementRef, HostListener, forwardRef } from '@angular/core';
import { Validator, NG_VALIDATORS, AbstractControl } from '@angular/forms';
import { Colors } from 'src/colors';

@Directive({
	selector: '[appHourMinute]',
	providers: [
			{ provide: NG_VALIDATORS, useExisting: forwardRef(() => HourMinuteDirective), multi: true }
	]
})
export class HourMinuteDirective implements Validator {
	@Input() ngModel: string;
	@Output() ngModelChange = new EventEmitter();

	private control: AbstractControl;

	constructor(private element: ElementRef) { }

	validate(c: AbstractControl): { [key: string]: any } {
		this.control = c;
		this.reset();

		return null;
	}

	@HostListener( 'input', ['$event'] )
	onInput( $event: any ) {
		let value = $event.target.value;
		if (value) {
			value = value.replace(/\D/g, '');

			if (value.length == 3) {
				value = value.substring(0,3).replace(/^(\d{1})?(\d{2})?/, '$1:$2');
			} else if (value.length == 4) {
				value = value.substring(0,4).replace(/^(\d{2})?(\d{2})?/, '$1:$2');
			} else if (value.length > 4) {
				value = value.substring(0,5).replace(/^(\d{3})?(\d{2})?/, '$1:$2');
			}

			this.doChange(value);
		}
	}

	doValidate(value) {
		if (value.length > 2 && Number(value.substring(value.length - 2)) > 59) {
			value = value.substring(0, value.length - 1);
		}

		return value;
	}

	doChange(value) {
		setTimeout(() => {
			this.element.nativeElement.value = value;
			this.ngModelChange.emit(value);
		}, 15);
	}

	reset() {
		this.element.nativeElement.parentElement.style.backgroundColor = '';
	}

	@HostListener( 'blur', ['$event'] )onBlur( $event: any ) {
		const value = $event.target.value;
		if (!value || value.replace(/\D/g, '').length < 3) {
			this.doChange('');
		} else {
			if (value != this.doValidate(value)) {
				const style = this.element.nativeElement.parentElement.style;
				style.backgroundColor = Colors.color.red_error_background;
				style.borderTop = '0';
				this.control.setErrors({hourMinute: false});
			} else {
				this.reset();
			}
		}
	}
}
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';

@Component({
	selector: 'app-default-input',
	templateUrl: './default-input.component.html',
	styleUrls: ['./default-input.component.scss']
})
export class DefaultInputComponent implements OnInit {

	@ViewChild('inputField', { static: false }) inputField: ElementRef;

	@Input() placeholder: string;
	@Input() field: any;
	@Input() disabled = false;
	@Input() maxlength = 1024;
	@Input() type: string = 'text';
	@Output() fieldChange = new EventEmitter();
	@Output() fieldBlur = new EventEmitter();

	constructor() { }

	ngOnInit() {
	}

	modelChange(event) {
		this.fieldChange.emit(event);
	}

	modelBlur() {
		this.fieldBlur.emit();
	}

	displayError() {
		const input = this.inputField.nativeElement.parentElement;

		if (input.className.indexOf(' input-error') < 0) {
			input.className += ' input-error';
		}
	}

	removeError() {
		const input = this.inputField.nativeElement.parentElement;
		input.className = input.className.replace(' input-error','');
	}
}
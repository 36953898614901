import {Component, Input, OnInit} from '@angular/core';
import {Icons} from "../../../../icons";

@Component({
  selector: 'app-checkbox-reactive',
  templateUrl: './checkbox-reactive.component.html',
  styleUrls: ['./checkbox-reactive.component.scss']
})
export class CheckboxReactiveComponent implements OnInit {
	@Input() checked: boolean = false;

	icons = Icons;

  constructor() { }

  ngOnInit(): void {
  }

}

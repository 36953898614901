import { Directive, ElementRef, HostListener } from "@angular/core";
import { NgControl } from "@angular/forms";

@Directive({
    selector: '[removeSpace]'
})
export class RemoveSpaceDirective {

    constructor(
        private elem: ElementRef,
        private control: NgControl
    ) {}

    @HostListener('keyup', ['$event']) keyup(e: any) {
        this.elem.nativeElement.value = this.elem.nativeElement.value.split(' ').join('');
        if (this.control && this.control.control) {
            this.control.control.setValue(this.elem.nativeElement.value.split(' ').join(''));
        }
    }
}
import { HostListener, ViewChild, ViewChildren, QueryList, OnInit } from '@angular/core';
import { MatTabGroup, MatTab, MatTabChangeEvent } from '@angular/material';
import { ConfirmService } from '../service/confirm.service';
import { Labels } from 'src/internationalization/labels/labels';
import { Messages } from 'src/internationalization/messages/messages';
import { ResettableFormDirective } from '../directive/resettable-form.directive';
import { Constants } from 'src/constants';
import { Icons } from 'src/icons';
import { Colors } from 'src/colors';
import { Errors } from 'src/internationalization/errors/errors';

export abstract class AbstractForm implements OnInit {
	@ViewChild(MatTabGroup, { static: false }) matTabGroup: MatTabGroup;
	@ViewChildren(ResettableFormDirective) forms: QueryList<ResettableFormDirective>;

	public _labels = Labels.getLabels();
	public _messages = Messages.getMessages();
	public _errors = Errors;
	public _constants = Constants.constant;
	public _icons = Icons.icon;
	public _colors = Colors.color;
	public dirty = false;
	public tabDirty = false;
	public tabFormIndexes: number[] = [];
	public lastTab: MatTab;
	private callback = false;
	private preventDirtyWhiteList = [this._labels.title.history];

	ngOnInit(): void {
		this.updateLanguage();
	}

	public updateLanguage() {
		this._labels = Labels.getLabels();
		this._messages = Messages.getMessages();
		this.preventDirtyWhiteList = [this._labels.title.history];
	}

	@HostListener('input', ['$event'])
	onInput($event: any) {
		if (this.lastTab && this.preventDirtyWhiteList.includes(this.lastTab.textLabel)) {
			return;
		}

		if (this.tabFormIndexes.find(i => i == this.matTabGroup.selectedIndex) != undefined) {
			this.tabDirty = true;
		} else {
			this.dirty = true;
		}
	}

	selectedTabChange(event: MatTabChangeEvent, confirmService: ConfirmService): void {
		if (!this.lastTab) {
			this.lastTab = this.matTabGroup._tabs.first;
		}

		if (this.tabDirty && this.lastTab != event.tab) {
			confirmService.open(undefined, this._labels.messageTitle.alert, this._messages.question.give_up_edittion,
				() => {
					const localLastTab = this.forms.find(f => f.tab == this.lastTab);
					if (localLastTab) { localLastTab.reset(); }
					this.lastTab = event.tab;
					this.tabDirty = false;
				}, () => {
					const tabs = this.matTabGroup._tabs.toArray();
					let index = 0;

					for (const tab of tabs) {
						if (tab == this.lastTab) break;

						index++;
					}

					this.callback = true;
					this.matTabGroup.selectedIndex = index;
				});
		} else {
			this.lastTab = event.tab;

			if (!this.callback) {
				this.emitChanged(event);
			} else {
				this.callback = false;
			}
		}
	}

	emitChanged(event) {
		if (this[this._constants.event.tabChanged]) {
			this[this._constants.event.tabChanged](event);
		}
	}
}
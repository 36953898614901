import { Directive, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';

@Directive({
	selector: '[appCheckboxList]'
})
export class CheckboxListDirective {
	@Input() ngModel;
	@Input() id: string = 'id';
	@Input() display: string = 'description';
	@Input() arrayList: any[];
	@Input() activedList: any[];
	@Output() activedListChange = new EventEmitter();

	constructor() { }

	ngOnChanges(changes: SimpleChanges) {
		if (changes.activedList) {
			if (changes.activedList.currentValue && changes.activedList.currentValue.length > 0 ) {
				for (const actived of this.activedList) {
					const item = this.arrayList.find(a => a[this.id] == actived[this.id]);
					if (item) {
						setTimeout(() => {
							item.checked = true;
						});
					}
				}
			} else {
				this.activedList = [];
			}
		}

		if (changes.ngModel && changes.ngModel.currentValue != undefined) {
			this.updateActivedList();
		}
	}

	updateActivedList() {
		this.activedList = [];

		for (const type of this.arrayList) {
			if (type.checked) {
				const object = {};
				object[this.id] = type[this.id];
				object[this.display] = type.description;
				this.activedList.push(object);
			}
		}

		const returnArray = [];
		for (const item of this.activedList) {
			returnArray.push(item);
		}

		setTimeout(() => {
			this.activedListChange.emit(returnArray);
		}, 1);
	}
}
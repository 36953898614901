import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'percent_and_large_pipe'
})
export class PercentLargePipe implements PipeTransform {
	transform(value: any): any {
		value = value.toString();
		if (value.length > 9) {
			value = '######';
			return value;
		}
		value = value + ' %';
		return value;
	}
}

import { ThrowStmt } from '@angular/compiler';
import { StringMap } from '@angular/compiler/src/compiler_facade_interface';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MathUtil } from 'src/app/core/math-util';
import { Messages } from 'src/internationalization/messages/messages';
import { LocaleUtils } from '../../model/enums/LocaleUtils';

@Component({
  selector: 'app-teus-and-tons',
  templateUrl: './teus-and-tons.component.html',
  styleUrls: ['./teus-and-tons.component.scss']
})
export class TeusAndTonsComponent implements OnInit {
  @Input()
  width: string = '';
  @Input()
  teus: string = '';

  @Input()
  tons: string = '';

  @Input()
  teusFormController: FormControl;

  @Input()
  tonsFormController: FormControl;

  @Input()
  display: 'vertical' | 'horizontal' = 'horizontal';

  @Input()
  disabled: boolean = false;

  @Input()
  displayErrors: boolean = true;

  @Input()
  displayAlign: "row" | "column" = "row";

  @Input()
  showDefaultInputs: boolean = true;

  @Input()
  startInZero: boolean = true;

  @Input()
  maxLength: number = 7;

  @Input()
  locale: string = LocaleUtils.EN_US;

  @Input()
  activeMask: boolean = false;

  @Output()
  teusChange = new EventEmitter<string>();

  @Output()
  verifyError = new EventEmitter<boolean>();

  @Output()
  tonsChange = new EventEmitter<string>();

  @Output()
  errorsChange = new EventEmitter<string>();

  showErrors: boolean = false;

  errors: string = "";

  flexAling: string = "col d-flex flex-";

  public messages = Messages.getMessages();

  teusMask: string = this.teus;
  tonsMask: string = this.tons;
  nullErrors: boolean;

  constructor() {

  }

  ngOnInit() {
    if (this.tonsFormController) {
      this.changeTons(this.tonsFormController.value);
      this.disabled = this.tonsFormController.disabled;
    } else {
      this.changeTons(this.tons);
    }

    if (this.teusFormController) {
      this.changeTeus(this.teusFormController.value);
      this.disabled = this.teusFormController.disabled;
    } else {
      this.changeTeus(this.teus);
    }
  }
  
  ngOnChanges(changes: SimpleChanges): void {
   if(changes.teus && changes.teus.currentValue != changes.teus.previousValue){
     if(this.disabled){
      this.changeTeus(changes.teus.currentValue);
     }
   }

   if(changes.tons && changes.tons.currentValue != changes.tons.previousValue){
    if(this.disabled){
      this.changeTons(changes.tons.currentValue);
    }
   }
  }

  changeTeus(value: string): void {
    if (this.existValue(value)) {
      value = this.validateMaxLength(value);
      value = this.formatByLocale(value);
      this.teusMask = value;
      value = this.maskOrNot(value);
      if (this.teusFormController) {
        this.teusFormController.setValue(value);
      }
      this.teus = value;
      this.existErrors();
    } else {
      if (this.startInZero) {
        this.teus = '0';
        value = '0';
        this.teusMask = '0';
      }
    }
    this.teusChange.emit((value));
  }

  changeTons(value: string): void {
    if (this.existValue(value)) {
      value = this.validateMaxLength(value);
      value = this.formatByLocale(value);
      this.tonsMask = value;
      value = this.maskOrNot(value);
      if (this.tonsFormController) {
        this.tonsFormController.setValue(value);
      }
      this.tons = value;
      this.existErrors();
    } else {
      if (this.startInZero) {
        this.tons = '0';
        value = '0';
        this.tonsMask = '0';
      }
    }
    this.tonsChange.emit(value);
  }

  removeSpecialCharacters(value: string): number {
    value = value.replace(/\,\./g, "").replace(/([0-9]{3})$/, `.$1`);
    return parseFloat(value);
  }

  keyUp(value: string, type: "teus" | "tons"): void {
    if (type == "tons") {
      this.changeTons(value);
    } else if (type == "teus") {
      this.changeTeus(value);
    }
  }

  formatByLocale(value: string): string {
    let decimalSymbol = "";

    switch (this.locale) {
      case LocaleUtils.PT_BR:
        decimalSymbol = ",";
      default:
        decimalSymbol = ".";
    }
    
      value = value.toString().replace(/\D/g, "").replace(/\,/g, "").replace(/\./g, "");
      if (value && value.length > 3) {
        value = value.replace(/([0-9]{3})$/, `${decimalSymbol}$1`);
        // remove zero in first place
        if (value[0] == "0" && (value[1] && value[1] != "." && value[1] != "0")) {
          value = value.substring(1, value.length);
        }
      }
      if(value != "0"){
        return value.replace(/^0+/, '');
     } else {
       return value;
     }
  }

  validateMaxLength(value: string): string {
    
    if (this.maxLength && value) {
      let max = this.activeMask ? this.maxLength+1 : this.maxLength;

      value = value.toString().substring(0, max);
    }

    return value;
  }

  existValue(value: string): boolean {
    if (value) {
      return true;
    }

    return false;
  }

  existErrors(): void {
    let teus = this.teus;
    let tons = this.tons;
    this.errors = "";
    this.verifyError.emit(false);
    if (this.teusFormController) {
      teus = this.teusFormController.value;
    }
    if (this.tonsFormController) {
      tons = this.tonsFormController.value;
    }

    if (teus && tons) {
      let teusT = MathUtil.valueToNumber(teus);
      let tonsT = MathUtil.valueToNumber(tons);
      this.showErrors = teusT >= tonsT;
      if (this.showErrors && (teusT > 0 && tonsT >= 0)) {
        this.errors = this.messages.the_tons_must_be_greater_than_teus;
        this.verifyError.emit(true);
      }
    } else {
      this.showErrors = false;
    }
    this.errorsChange.emit(this.errors);
  }

  verifyDisplay() {
    return (this.display == 'horizontal' ? 'col' : 'col-12');
  }

  maskOrNot(value: string) {
    if(!this.activeMask) {
      return value.toString().replace(/\D/g, "").replace(/\,/g, "").replace(/\./g, "");
    } else {
      return value;
    }
  }

}

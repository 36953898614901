import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Icons } from 'src/icons';
import { Labels } from 'src/internationalization/labels/labels';

@Component({
	selector: 'app-modal',
	templateUrl: './modal.component.html',
	styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
	public _labels = Labels.getLabels();
	public _icons = Icons;

	@Input() icon: string;
	@Input() title: string;
	@Input() create = false;
	@Input() add = false;
	@Input() edit = false;
	@Input() view = false;
	@Input() renovate = false;
	@Input() disableScroll = false;

	@Output() close = new EventEmitter();

	constructor() { }

	ngOnInit() { }
}
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'selectAll'
})
export class SelectAllPipe implements PipeTransform {
	transform(array: any[], selectAll: boolean, valueAll: any): any {
		if (array && selectAll) {
			array.unshift(valueAll);
		}

		return array;
	}
}
import {Labels} from '../../../../internationalization/labels/labels';

export function enumTransform(value: string): string {
	const _labels = Labels.getLabels();
	if (value) {
		const valueLowerCase = value.toLowerCase();
		return _labels[valueLowerCase];
	}
	return '';
}

import { Directive, Input, Output, ElementRef, SimpleChanges, HostListener, EventEmitter, forwardRef, Renderer2 } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';
import { Colors } from 'src/colors';
import { NumberDecimalPipe } from '../pipe/number-decimal.pipe';
import { CoreUtil } from '../core-util';

@Directive({
	selector: '[appNumberDecimal]',
	providers: [
		{ provide: NG_VALIDATORS, useExisting: forwardRef(() => NumberDecimalDirective), multi: true }
	]
})
export class NumberDecimalDirective implements Validator {
	// local and currency = in agreement ISO
	@Input() decimalPlaces = 2;
	@Input() local: string = '';
	@Input() isMonetary: boolean = false;
	@Input() hasDefaultEmptyValue: boolean = true;
	@Input() currency: string = '';
	@Input() maxDigits: number = 0;
	@Input() validation: any;
	@Input() ngModel: string;

	@Output() ngModelChange = new EventEmitter();

	private control: AbstractControl;
	private lastValue: string;
	private pipe = new NumberDecimalPipe();

	constructor(
		private renderer: Renderer2,
		private element: ElementRef
	) { }

	ngOnChanges(changes: SimpleChanges) {
		if (changes.ngModel && (changes.ngModel.currentValue || changes.ngModel.currentValue == 0) &&
			this.ngModel != this.lastValue) {
			const event = { target: { value: changes.ngModel.currentValue } };
			this.onInput(event);
		}
	}

	validate(c: AbstractControl): { [key: string]: any; } {
		this.control = c;
		this.element.nativeElement.parentElement.style.backgroundColor = '';

		return null;
	}

	@HostListener('input', ['$event'])
	onInput($event: any) {
		let value = $event.target.value;

		if (value && value.toString()) {
			value = value.toString().replace(/\D/g, '');
		}

		this.doChange(value);
	}

	getInitialPlaceholder(maxDigits: number, decimalPlaces: number): any {
		if (!maxDigits && !decimalPlaces) return '0,00';

		let integerNumbers = '';
		let decimalNumbers = '';

		if (maxDigits) {
			const integerNumbersRange = CoreUtil.range(maxDigits - decimalPlaces);
			integerNumbersRange.forEach(index => {
				integerNumbers = (index > 0 && index % 3 === 0 ? '0.' : '0') + integerNumbers;
			});
		} else {
			integerNumbers = '0';
		}

		decimalNumbers = decimalPlaces ? Array(decimalPlaces).fill(0).join('') : '00';

		return `${integerNumbers},${decimalNumbers}`;
	}

	doChange(value) {
		if (this.maxDigits > 0) {
			if (value.length > this.maxDigits) {
				value = value.substring(value.length - this.maxDigits, value.length);
			}
		}

		if (this.decimalPlaces > 1 && value.toString().indexOf('.') <= 0) {
			let value2 = '0.';

			for (let index = 0; index < this.decimalPlaces - 1; index++) {
				value2 = value2 + '0';
			}

			value = (value === '' && !this.hasDefaultEmptyValue)
				? value
				: value2 + value;
		}

		value = value.toString().replace(/\D/g, '');

		if (value.length > this.decimalPlaces) {
			const integer = Number(value.substring(0, value.length - this.decimalPlaces));
			const decimal = value.substring(value.length - this.decimalPlaces, value.length);
			if (this.decimalPlaces > 0) {
				value = integer + '.' + decimal;
			} else {
				value = integer;
			}
		}

		this.ngModel = value.toString();
		this.lastValue = value;


		//If necessary to not move highlighter 
		setTimeout(() => {
			this.lastValue = value;
			this.ngModelChange.emit(value);
			setTimeout(() => {
				let valueFormatted = this.pipe.transform(value, this.decimalPlaces, this.local, this.isMonetary, this.currency);
				let transformedValue = (value === '' || valueFormatted == 0) ? value : valueFormatted;
				this.renderer.setProperty(this.element.nativeElement, 'value', transformedValue);
			}, 50);
		}, 50);
	}

	// @HostListener('click', ['$event']) onClick($event: any) {
	// 	let value = $event.target.value;
	// 	if(value == '0'){
	// 		this.doChange("");
	// 	}
	// }

	@HostListener('blur', ['$event']) onBlur($event: any) {
		let value = $event.target.value;
		if (value == '0') {
			this.doChange("");
		}

		if (this.control && this.control.value != undefined) {
			if (this.validation && !this.validation()) {
				this.element.nativeElement.parentElement.style.backgroundColor = Colors.color.red_error_background;
				this.control.setErrors({ number: false });

				return;
			}

			this.element.nativeElement.parentElement.style.backgroundColor = '';
			this.control.setErrors(null);
		}
	}
}
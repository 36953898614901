// tooltip.directive.ts
import { Directive, ElementRef, Input, HostListener } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { TruncateService } from "./truncate-tooltip.service";

@Directive({
	selector: '[TruncateTooltip]',
	providers: [MatTooltip],
})
export class TruncateTooltipDirective {
	@Input('TruncateTooltip') text: string = '';

	constructor(
		private el: ElementRef,
		private truncateService: TruncateService,
		private matTooltip: MatTooltip
	) {
		this.matTooltip.position = 'above';
	}

	ngAfterViewInit(): void {
		this.updateTooltip();
	}

	private updateTooltip(): void {
		const truncatedText = this.truncateService.truncateText(this.text, 20);
		if (this.text.length > 20) {
			this.el.nativeElement.innerText = truncatedText;
			this.matTooltip.message = this.text;
			this.matTooltip.disabled = false;
		} else {
			this.el.nativeElement.innerText = this.text;
			this.matTooltip.disabled = true;
		}
	}

	@HostListener('mouseenter') onMouseEnter() {
		this.matTooltip.show();
	}

	@HostListener('mouseleave') onMouseLeave() {
		this.matTooltip.hide();
		this.updateTooltip();
	}
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ObjectUtils } from '../../object-utils';

@Component({
  selector: 'app-simple-select',
  templateUrl: './simple-select.component.html',
  styleUrls: ['./simple-select.component.scss']
})
export class SimpleSelectComponent implements OnInit {
	@Input() objects: any[] = [];
	@Input() objectLabel: string;
  @Input() value : any;
	@Input() label: string;
	@Input() classes: string;
  @Input() formController: FormControl;

	@Output() valueChange = new EventEmitter<any>();


  selectedObject : number;

  constructor() { }

  ngOnInit(): void {
    if(this.formController){
      this.value = this.formController.value;
    }

    this.searchObjectInObjects();
  }

	ngOnChanges(changes) {
		if (changes.value.currentValue || changes.formController.currentValue ) {
      this.searchObjectInObjects();
		}
	}


  public changeValue(index: number){
    if(index){
      this.value = this.objects[index];
      this.valueChange.emit(this.value);
      this.formController.setValue(this.value);
    }
  }

  private searchObjectInObjects(){
    if(this.objects){
      this.objects.forEach((obj, index) => {
        if(this.value && ObjectUtils.isEqual(obj, this.value)){
        }
      })
    }
  }
}

import { Directive, Input, Output, ElementRef, SimpleChanges, HostListener, EventEmitter, forwardRef } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';
import { Colors } from 'src/colors';
import { NumberDecimalPipe } from '../pipe/number-decimal.pipe';
import { CoreUtil } from '../core-util';

import { timer } from 'rxjs';
import { take } from 'rxjs/operators';

@Directive({
	selector: '[streamingDirective]',
	providers: [
		{ provide: NG_VALIDATORS, useExisting: forwardRef(() => StreamingDirective), multi: true }
	]
})

export class StreamingDirective {
	@Input() decimalPlaces = 2;
	@Input() local: string = '';
	@Input() isMonetary: boolean = false;
	@Input() customInitialPlaceholder: boolean = false;
	@Input() currency: string = '';
	@Input() maxDigits: number = 0;
	@Input() validation: any;
	@Input() ngModel: string;

	@Output() ngModelChange = new EventEmitter();

	private control: AbstractControl;
	private lastValue: string;
	private pipe = new NumberDecimalPipe();

	constructor(private element: ElementRef) { }

	ngOnChanges(changes: SimpleChanges) {
		if (changes.ngModel && (changes.ngModel.currentValue || changes.ngModel.currentValue == 0) &&
			this.ngModel != this.lastValue) {
			const event = { target: { value: changes.ngModel.currentValue } };
			this.onInput(event);
		}
	}

	validate(c: AbstractControl): { [key: string]: any; } {
		this.control = c;
		this.element.nativeElement.parentElement.style.backgroundColor = '';

		return null;
	}

	@HostListener('input', ['$event'])
	onInput($event: any) {
		let value = $event.target.value;

		if (value && value.toString()) {
			value = value.toString().replace(/\D/g, '');
		}

		if (Number(value) == 1 && this.customInitialPlaceholder) {
			value = this.getInitialPlaceholder(this.maxDigits, this.decimalPlaces); // '00.000.000,00';
			const waitAndCallModelChangeAfterTimer = timer(10);
			waitAndCallModelChangeAfterTimer.pipe(take(1)).subscribe(() => this.ngModelChange.emit(value));
		} else if (Number(value) >= 0) {
			this.doChange(value);
		} else {
			this.doChange(value);
		}
	}

	getInitialPlaceholder(maxDigits: number, decimalPlaces: number): any {
		if (!maxDigits && !decimalPlaces) return '0.00';

		let integerNumbers = '';
		let decimalNumbers = '';

		if (maxDigits) {
			let integerNumbersRange = CoreUtil.range(maxDigits - decimalPlaces);
			integerNumbersRange.forEach(index => {
				integerNumbers = (index > 0 && index % 3 === 0 ? '0.' : '0') + integerNumbers;
			});
		} else {
			integerNumbers = '0';
		}

		decimalNumbers = decimalPlaces ? Array(decimalPlaces).fill(0).join('') : '00.0';

		return `${integerNumbers},${decimalNumbers}`;
	}

	doChange(value) {
		if (this.maxDigits >= 0) {
			if (value.length > this.maxDigits) {
				value = value.substring(value.length - this.maxDigits, value.length);
			}
		}

		if (this.decimalPlaces > 1 && value.toString().indexOf('.') < 0) {
			let value2 = '0.';

			for (let index = 0; index < this.decimalPlaces - 1; index++) {
				value2 = value2 + '0';
			}

			value = value2 + value;


		}

		value = value.toString().replace(/\D/g, '');

		if (value.length > this.decimalPlaces) {
			const integer = Number(value.substring(0, value.length - this.decimalPlaces));
			const decimal = value.substring(value.length - this.decimalPlaces, value.length);
			value = integer + '.' + decimal;
		}

		this.ngModel = value.toString();
		this.lastValue = value;

		this.ngModel = value.toString();
		setTimeout(() => {
			this.lastValue = value;
			this.ngModelChange.emit(value);
			setTimeout(() => {
				let valueStr = this.pipe.transform(value, this.decimalPlaces, this.local, this.isMonetary, this.currency);
				valueStr = valueStr.replace(".", "");
				valueStr = valueStr.replace(",", ".");
				if(value == '00'){
					value = '0.00';
				}
				this.element.nativeElement.value = value;
			}, 50);
		}, 50);
	}
}
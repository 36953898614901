import { Directive, HostListener, Input, Output, EventEmitter, ElementRef, SimpleChanges } from '@angular/core';

@Directive({
	selector: '[appDate]'
})
export class DateDirective {

	@Input() ngModel: string;
	@Output() ngModelChange = new EventEmitter();
	@Output() onBlur = new EventEmitter();

	private lastValue: string;

	constructor(private element: ElementRef) {

	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.ngModel && changes.ngModel.currentValue && this.ngModel != this.lastValue) {
			this.onInput({target: {value: changes.ngModel.currentValue}});
		}
	}

	@HostListener( 'input', ['$event'] )
	onInput( $event: any ) {
		let value = $event.target.value;
		if (value) {
			value = value.replace(/\D/g, '');

			if (value.length > 2) {

				if (value.length <= 4) {
					value = value.substring(0,4).replace(/^(\d{2})?(\d{2})?/, '$1/$2');
				} else {
					value = value.substring(0,8).replace(/^(\d{2})?(\d{2})?(\d{4})?/, '$1/$2/$3');
				}
			}

			this.doChange(this.validate(value));
		}
	}

	validate(value) {
		let day;
		let month;
		switch (value.length + '') {
			case '1':
				if (Number(value.substring(0,1)) > 3) {
					value = '';
				}
				break;
			case '2':
				if (this.isNotValidDay(value)) {
					value = value.substring(0,1);
				}
				break;
			case '4':
				if (this.isNotValidDay(value)) {
					value = value.substring(0,1);
				} else if (Number(value.substring(3)) > 1) {
					value = value.substring(0,2);
				}
				break;
			case '5':
				if (this.isNotValidDay(value)) {
					value = value.substring(0,1);
				} else if (this.isNotValidMonth(value)) {
					value = value.substring(0,4);
				}
				break;
			case '6':
			case '7':
			case '8':
			case '9':
				if (this.isNotValidDay(value)) {
					value = value.substring(0,1);
				} else if (this.isNotValidMonth(value)) {
					value = value.substring(0,4);
				}
				break;
			case '10':
				if (this.isNotValidDay(value)) {
					value = value.substring(0,1);
				} else if (this.isNotValidMonth(value)) {
					value = value.substring(0,4);
				} else {

					day = Number(value.substring(0,2));
					month = Number(value.substring(3,5));

					if (month == 2 && day > 28) {
						const year = Number(value.substring(6));
						if (!(((year % 4 == 0) && (year % 100 != 0)) || (year % 400 == 0))) {
							value = value.substring(0,9);
						}
					}
				}

				break;
		}

		return value;
	}

	isNotValidDay(value) {
		return Number(value.substring(0,2)) > 31;
	}

	isNotValidMonth(value) {
		const day = Number(value.substring(0,2));
		const month = Number(value.substring(3,5));

		return (this.isThirtyDaysMonth(month) && day > 30) ||
			(month == 2 && day > 29) || month > 12;
	}

	isThirtyDaysMonth(month: number) {
		return month == 4 || month == 6 || month == 9 || month == 11;
	}

	doChange(value: string) {
		this.ngModel = value;
		setTimeout(() => {
			this.lastValue = value;
			this.element.nativeElement.value = value;
			if (value && value.length == 10) {
				const array = value.split('/');
				value = array[2] + '-' + array[1] + '-' + array[0];
			}
			this.ngModelChange.emit(value);
		}, 15);
	}

	@HostListener( 'blur', ['$event'] )blur( $event: any ) {
		const value = $event.target.value;
		if (!value || value.replace(/\D/g, '').length < 8) {
			this.doChange('');
		}

		setTimeout(() => {
			this.onBlur.emit();
		}, 30);
	}
}
import {Labels} from '../../../internationalization/labels/labels';
import {Messages} from '../../../internationalization/messages/messages';
import {Constants} from '../../../constants';
import {Icons} from '../../../icons';
import {EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {CoreUtil} from '../core-util';
import {ArrayHeaderGridListModel} from '../../shared/model/array-header-grid-list.model';
import {Pagination} from '../dto/pagination';
import {Colors} from '../../../colors';
import {ObjectMultiFilter} from '../dto/object-multi-filter.dto';
import {TaxIdPipe} from '../pipe/tax-id.pipe';
import {ZipCodePipe} from '../pipe/zip-code.pipe';
import {CpfCnpjPipe} from '../pipe/cpf-cnpj.pipe';
import {AdhocDatePipe} from '../pipe/adhoc-date.pipe';
import {BooleanPipe} from '../pipe/boolean.pipe';
import {DomainStatusPipe} from '../pipe/domain-status.pipe';
import {negative_number_and_large_pipe} from '../pipe/negative-number-and-large-pipe';
import {NumberDecimalPipe} from '../pipe/number-decimal.pipe';
import {NaPipe} from '../pipe/n_a.pipe';
import {PhonePipe} from '../pipe/phone.pipe';
import {StringSplitPipe} from '../pipe/string-split.pipe';
import {TimePipe} from '../pipe/time.pipe';
import {DatePipe} from '../pipe/date.pipe';
import {LargeNumberPipe} from '../pipe/large-number-pipe.pipe';
import {PercentLargePipe} from '../pipe/percent-large-number-pipe.pipe';
import {SpacingSemicolonCommaPipe} from '../pipe/spacing-semicolon-comma.pipe';
import {DataTableSelectedRows} from 'src/app/shared/model/data-table-selected-rows.model';
import {extense_and_negative_number_pipe} from '../pipe/extense-and-negative-number-pipe';
import {CapitalizeFirstLetter} from '../pipe/capitalize-first-letter.pipe';
import {EmptyValuePipe} from '../pipe/empty-value.pipe';

export abstract class AbstractTable implements OnInit, OnChanges {

	public _labels = Labels.getLabels();
	public _messages = Messages.getMessages();
	public _constants = Constants.constant;
	public _icons = Icons;
	public _expandAll = false;
	public _expandedElements: any[] = [];
	public _tableHeight;
	public _tableWidth;
	public _colors = Colors.color;
	private _pipes: any;
	public _displayedColumns = [];
	public _lastSearchText: string = '';
	public _totalColumns = 0;
	public _actionColumnHeaderWidth: number;
	public _buttonsColumnHeaderWidth: number;
	public _actualPage: number;
	public _actionWidth;
	public _buttonsWidth;
	public _selectAll = false;
	public _hasSubMenu: boolean = false;
	private _selectedRows: any[] = [];
	private _isMultipleOptionOnceChosen: boolean = false;
	private isSelectAllOnPage = false;
	public disabledAllSelection = false;

	@Input() title: string;
	@Input() arrayData: any[];
	@Input() mapFilter: Map<string, ObjectMultiFilter>;
	@Input() arrayAction: any[];
	@Input() arrayButton: any[];
	@Input() isViewDisabled = false;
	@Input() showDetails = false;
	@Input() filter = '';
	@Input() filterAsc = true;
	@Input() accordionShowFirstRow = true;
	@Input() openedAccordionMap: Map<number, boolean>;
	@Input() accordionColumnList: string;
	@Input() arrayHeader: ArrayHeaderGridListModel[];
	@Input() allowSelection = false;
	@Input() allowSelectionStart = false;
	@Input() isMultiplePagesSelection = false;
	@Input() arrayChildAction: any[];
	@Input() rowOpacity;
	@Input() disabledRow: (obj: any) => boolean;
	@Input() clickable = false;
	@Input() scroll = true;
	@Input() pagination: Pagination;
	@Input() searchText = '';
	@Input() applyShadow = false;
	@Input() height;
	@Input() toggleDisabled = false;
	@Input() searchFinished: boolean;
	@Input() gridInformation: string;
	@Input() readonly = false;
	@Input() exportsXls = false;
	@Input() exportsXlsDataTransformer;
	@Input() xlsHeader;
	@Input() isAdd = true;
	@Input() isAddDisabled = false;
	@Input() isXlsDisabled = false;
	@Input() showTitle = true;
	@Input() disableTooltip = false;
	@Input() imgXls = this._icons.icon.xls;
	@Input() verifySendAgreement: boolean;
	@Input() verifyGeneratePDF: boolean;
	@Input() iconAfterText: boolean = false;
	@Input() removedFilter: boolean = false;
	@Input() filterTabsList: string[] = [];
	@Input() blockCheckboxClass: boolean = false;
	@Input() showInfoIcon: boolean = false;
	@Input() detailInfoIcon: boolean = false;
	public _activeTab: string = '';

	@Output() showDetailsChange = new EventEmitter();
	@Output() clicked = new EventEmitter();
	@Output() paginationChange = new EventEmitter();
	@Output() globalSearch = new EventEmitter();
	@Output() add = new EventEmitter();
	@Output() xlsChange = new EventEmitter();
	@Output() inputChanged = new EventEmitter();
	@Output() arrayDataChange = new EventEmitter();
	@Output() selectedRows = new EventEmitter<DataTableSelectedRows<any>>();
	@Output() onActionInfoIcon = new EventEmitter();
	@Output() searchTabFilter = new EventEmitter();

	ngOnInit(): void {
		this.buildPipes();
	}

	buildPipes() {
		this._pipes = {
			taxId: new TaxIdPipe(),
			cpfCnpj: new CpfCnpjPipe(),
			zipCode: new ZipCodePipe(),
			phone: new PhonePipe(),
			boolean: new BooleanPipe(),
			stringSplit: new StringSplitPipe(),
			time: new TimePipe(),
			domainStatus: new DomainStatusPipe(),
			date: new DatePipe(),
			adhoc_date: new AdhocDatePipe(),
			numberDecimal: new NumberDecimalPipe(),
			largeNumber: new PercentLargePipe(),
			negativeNumber: new negative_number_and_large_pipe(),
			NaPipe: new NaPipe(),
			LargeNumberPipe: new LargeNumberPipe(),
			spacing_semicolon_comma: new SpacingSemicolonCommaPipe(),
			extenseNumber: new extense_and_negative_number_pipe(),
			capitalizeFirstLetter: new CapitalizeFirstLetter(),
			empty_value: new EmptyValuePipe(),
		};
	}

	ngOnChanges(changes: SimpleChanges) {
		if (this.arrayHeader && this.arrayHeader.length > 0) {
			this._displayedColumns = this.arrayHeader.map(a => a.key);
		}

		if (changes.searchText && !!changes.searchText.currentValue && !this._lastSearchText) {
			this._lastSearchText = changes.searchText.currentValue;
		}

		if (changes.removedFilter && changes.removedFilter.currentValue) {
			this.setFilter('', '');
		}

		if (this.openedAccordionMap) {
			this.expandMapped();
		}

		if (this.arrayHeader && this.arrayHeader.length > 0 && this.arrayAction && !this._displayedColumns.find(d => d == 'actions')) {

			if (this.allowSelectionStart && !this._displayedColumns.find(d => d == 'rowSelection')) {
				this._displayedColumns.unshift('rowSelection');
			}

			if (this.allowSelection && !this._displayedColumns.find(d => d == 'rowSelection')) {
				this._displayedColumns.push('rowSelection');
			}

			if (this.arrayAction.length > 0) {
				this._displayedColumns.push('actions');
			}
		}

		if (this.arrayHeader && this.arrayHeader.length > 0 && this.arrayButton && !this._displayedColumns.find(d => d == '')) {

			if (this.allowSelectionStart && !this._displayedColumns.find(d => d == 'rowSelection')) {
				this._displayedColumns.unshift('rowSelection');
			}

			if (this.allowSelection && !this._displayedColumns.find(d => d == 'rowSelection')) {
				this._displayedColumns.push('rowSelection');
			}

			if (this.arrayButton.length > 0) {
				this._displayedColumns.push('buttons');
			}
		}

		if (changes.arrayData && changes.arrayData.currentValue) {
			if (this.arrayData !== changes.arrayData.currentValue) {
				this.arrayData = changes.arrayData.currentValue;
			}
			this.updateTableHeight();
		}

		if (changes.arrayAction || changes.arrayChildAction) {
			this._actionColumnHeaderWidth = this.actionColumnWidth();
		}

		if (changes.arrayButton || changes.arrayChildAction) {
			this._buttonsColumnHeaderWidth = this.buttonColummnWidth();
		}

		if (this._displayedColumns) {
			this._totalColumns = this._displayedColumns.length;
		}
	}

	setFilter(acordeonChild, filter, doNotSort?) {
		if (doNotSort) return;

		if (!acordeonChild && this.arrayData && this.arrayData.length > 0) {
			if (filter != this.filter) {
				this.filterAsc = true;
				this.filter = filter;
			} else {
				this.filterAsc = !this.filterAsc;
			}
		}
	}

	expandMapped() {
		if (!this.openedAccordionMap) return;

		if (this.arrayData && this.arrayData.length > 0) {
			for (let i = 0; i < this.arrayData.length; i++) {
				const elem = this.openedAccordionMap.get(i);

				if (elem != undefined) {
					const data = this.arrayData[i];
					data.showAcordeon = elem;
					const obj = this.splitProperty(data, this.accordionColumnList);

					if (obj && obj.length > (this.accordionShowFirstRow ? 1 : 0)) {
						this.expand(data);
					}
				}
			}

			this.updateTableHeight();
		}
	}

	setActiveTab(tabLabel: string) {
		if (this._activeTab === tabLabel) {
			this._activeTab = '';
		} else {
			this._activeTab = tabLabel;
		}
	}

	onTabFilterSelected(tabLabel: string) {
		tabLabel = tabLabel === this._activeTab ? null : tabLabel;
		this.setActiveTab(tabLabel);
		this.filterGridResultsBySelectedTab(tabLabel);
	}

	filterGridResultsBySelectedTab(tabLabel: string) {
		// Perform results filtering by selected tab.
		this.searchTabFilter.emit(tabLabel);
	}

	selectAll(event, pagination) {
		this.selectAllOnPage(event, false);

		const mainCheckbox = document.querySelector('.multiple-pages-selection .mat-checkbox');
		mainCheckbox.classList.add('select-all');

		const arrayDataLength = this.arrayData.length;

		if (arrayDataLength === this.pagination.totalData && event.checked) {
			this.arrayData.filter(data => !this.disabledRow(data)).forEach(value => value.rowSelection = true);
		} else {
			this.disabledAllSelection = false;
			for (let i = 0; i < (this.pagination.totalData - arrayDataLength); i++) {
				this.arrayData.push({
					id: -1,
					rowSelection: true,
					status: {
						icon: 'status-icon-blank',
						showLabel: true,
						textContent: 'Blank'
					}
				});
			}

		}

		for (let i = 0; i < (this.pagination.totalData - this.arrayData.length); i++) {
			this._selectedRows.push({
				id: -1,
				rowSelection: true,
				status: {
					icon: 'status-icon-blank',
					showLabel: true,
					textContent: 'Blank'
				}
			});
		}
	}

	selectAllOnPage(event, selectAllOnPage = true) {
		const selected = this.arrayData.filter(data => data.rowSelection === true);

		if ((selected.length === this.pagination.totalData) && (selected.length > this.pagination.pageSize) && this.isMultiplePagesSelection) return;

		const checkbox: HTMLInputElement = document.querySelector('.multiple-positive .mat-checkbox');

		if (event && !event.source) {
			if (!checkbox.classList.contains('mat-checkbox-checked')) checkbox.classList.add('mat-checkbox-checked');
			event.checked = true;
			this._isMultipleOptionOnceChosen = true;
		} else if (this._isMultipleOptionOnceChosen) {
			event.checked = !(checkbox.classList.contains('mat-checkbox-checked'));

			setTimeout(() => {
				(event.checked)
					? checkbox.classList.add('mat-checkbox-checked')
					: checkbox.classList.remove('mat-checkbox-checked');
			}, 100);
		}

		this._selectedRows = [];
		this.arrayData.filter(data => data.id !== -1 && !this.disabledRow(data)).forEach(value => {
			value.rowSelection = event.checked;
			if (value.rowSelection) this._selectedRows.push(value);
		});
		this.isSelectAllOnPage = selectAllOnPage;
		this.onRowSelection(this._selectAll, this._selectedRows);
	}

	deselectAll(event) {
		this._isMultipleOptionOnceChosen = true;

		const mainCheckbox = document.querySelector('.multiple-pages-selection .mat-checkbox');
		mainCheckbox.classList.remove('select-all');

		const checkbox: HTMLInputElement = document.querySelector('.multiple-positive .mat-checkbox');
		if (checkbox.classList.contains('mat-checkbox-checked')) checkbox.classList.remove('mat-checkbox-checked');

		this._selectedRows = [];
		this.arrayData.forEach(value => {
			value.rowSelection = false;
			if (value.rowSelection) this._selectedRows.push(value);
		});
		this.arrayData.forEach(value => value.rowSelection = false);
		this.isSelectAllOnPage = false;
		this.onRowSelection(this._selectAll, this._selectedRows);
	}

	someComplete(): boolean {
		const checkedBoxes = this.arrayData.filter(data => data.rowSelection).length;

		return (checkedBoxes > 0) && (checkedBoxes < this.arrayData.length);
	}

	addEmit() {
		if (!this.readonly) {
			this.add.emit();
		}
	}

	exportXls() {
		this.xlsChange.emit();
	}

	changeRowSelection(event) {
		this._selectedRows = [];
		if (!event.checked) {
			for (const data of this.arrayData) {
				this._selectAll = false;
				if (data.rowSelection) {
					this._selectedRows.push(data);
				}
			}
		} else {
			let isAllSelected = true;
			for (const data of this.arrayData) {
				if (!data.rowSelection) {
					isAllSelected = false;
				} else if (data.rowSelection) {
					this._selectedRows.push(data);
				}
			}

			this._selectAll = isAllSelected;
		}

		if (this._selectedRows.length === 0 && !this.blockCheckboxClass) {
			const checkbox: HTMLInputElement = document.querySelector('.multiple-positive .mat-checkbox');
			if (checkbox.classList.contains('mat-checkbox-checked')) checkbox.classList.remove('mat-checkbox-checked');
		}
		this.isSelectAllOnPage = false;
		this.onRowSelection(this._selectAll, this._selectedRows);
	}

	expandAll() {
		if (this.arrayData && this.arrayData.length > 0) {
			let update = false;
			this._expandedElements = [];

			for (const data of this.arrayData) {
				data.showAcordeon = this._expandAll;
				const obj = this.splitProperty(data, this.accordionColumnList);

				if (obj && obj.length > (this.accordionShowFirstRow ? 1 : 0)) {
					this.expand(data);

					update = true;
				}
			}

			if (update) {
				this._expandAll = !this._expandAll;
			}

			this.updateTableHeight();
		}
	}

	expand(el) {
		el.showAcordeon = !el.showAcordeon;

		const index = this._expandedElements.indexOf(el);

		if (this.openedAccordionMap) {
			const pos = this.arrayData.indexOf(el);
			this.openedAccordionMap.set(pos, el.showAcordeon);
		}

		if (index < 0) {
			this._expandedElements.push(el);
		} else {
			this._expandedElements.splice(index, 1);
			this._expandAll = false;
		}
	}

	toogleCheckVerifier(el, key) {
		let result: boolean | string = false;
		if (this.splitProperty(el, key) != undefined) {
			result = this.splitProperty(el, key) || this.splitProperty(el, key) == 'A';
		}

		return result;
	}

	actionClick(el, action) {
		if (action.readonly) {
			if (!action.readonly(el)) {
				action.function(el);
			}
		} else {
			action.function(el);
		}
	}

	inputChangeEvent(inputType, el, colHeader, index, event) {
		let splittedPath = [];
		let property = '';
		if (colHeader.acordeonChild && this.accordionColumnList) {
			// if the input in a acordeon child, the method will iterate over the object
			// using the 'path' described on colHeader.acordeonChild. It iterates recursivelly
			// using Array().reduce(). The last part of colHeader.acordeonChild is used
			// as the property key of the object, used to set it's value.
			// const row = this.splitProperty(el, this.accordionColumnList)[index]; // Unused code found 19/03/2021
			const splittedAcordeonChild = colHeader.acordeonChild.split('.');
			splittedPath = splittedAcordeonChild.slice(0, -1);
			property = splittedAcordeonChild[splittedAcordeonChild.length - 1];

			splittedPath.reduce(
				(acc, elem) => (acc[elem]),
				el[this.accordionColumnList][index]
			)[property] = this.getInputValue(inputType, event, el, colHeader.acordeonChild);
		} else if (el && colHeader.key) {
			// if the input is in a row, the. It iterates recursivelly
			// using Array().reduce(). The last part of colHeader.acordeonChild is used
			// as the property key of the object, used to set it's value.
			const splittedKey = colHeader.key.split('.');
			splittedPath = splittedKey.slice(0, -1);
			property = splittedKey[splittedKey.length - 1];

			splittedPath.reduce(
				(acc, elem) => (acc[elem]),
				el
			)[property] = this.getInputValue(inputType, event, el, colHeader.key);
		}

		if (this.inputChanged) {
			this.inputChanged.emit({inputType, el, colHeader, index, event});
		}
	}

	getInputValue(inputType: string, event: any, obj, key): any {
		// Please update this method for each new inputType used on GridList
		switch (inputType) {
			case 'toggle':
				// return event.checked ? 'A' : 'I'; // TODO: Sprint13 - need more tests
				if (typeof this.splitProperty(obj, key) === 'string') {
					return event.checked ? 'A' : 'I';
				} else {
					return event.checked;
				}
			case 'input':
				return event.target.value;
			default:
				return event;
		}
	}

	getTooltipMask(value, pipe, params, td) {
		if (td.offsetWidth < td.scrollWidth) {
			return this.tooltipMask(value, pipe, params);
		}
		if (pipe == 'negative_and_large_pipe' || pipe == 'percent_and_large_pipe' || pipe == 'large_number_pipe' || pipe == 'extense_and_negative_pipe') {
			value = this.CostingLargeNumber(value, pipe);

			return value;
		}

		return '';
	}

	showCustomTooltipText(rowObjt, propertyWithCustomText, pipe, pipeParams) {
		return this.tooltipMask(rowObjt[propertyWithCustomText], pipe, pipeParams);
	}

	CostingLargeNumber(value, pipe) {
		if (pipe == 'negative_and_large_pipe') {
			if (value > 99999.99) {
				value = new Intl.NumberFormat('pt-BR', {currency: 'BRL', minimumFractionDigits: 2}).format(value);

				return value;
			}
			if (value < -99999.99) {
				value = new Intl.NumberFormat('pt-BR', {currency: 'BRL', minimumFractionDigits: 2}).format(value);
				value = '(' + value + ')';
				value = value.replace('-', '');

				return value;
			}
		}
		if (pipe == 'percent_and_large_pipe') {
			if (value > 9999.99) {
				value = new Intl.NumberFormat('pt-BR', {currency: 'BRL', minimumFractionDigits: 2}).format(value);

				return value + ' %';
			}
		}
		if (pipe == 'large_number_pipe') {
			if (value > 9999999) {
				return value;
			}
		}
		if (pipe == 'extense_and_negative_pipe') {
			if (value > 9999999.99) {
				value = value = new Intl.NumberFormat('pt-BR', {currency: 'BRL', minimumFractionDigits: 2}).format(value);

				return value;
			}
		}

		return '';
	}

	viewDetailsElementClick(el) {
		el.viewDetails = !el.viewDetails;
		this.showDetails = false;

		for (const data of this.arrayData) {
			if (data.viewDetails) {
				this.showDetails = true;
				break;
			}
		}

		this.showDetailsChange.emit(this.showDetails);
	}

	setWidth() {
		if (this.arrayHeader) {
			this.arrayHeader.forEach(a => a.width = 0);
		}
	}

	getImagesSrcArray(colHeader, el) {
		let splittedString = '';

		if (el[colHeader.stringName]) {
			const stringToSplit = el[colHeader.stringName];
			splittedString = stringToSplit.split(colHeader.addressStringSeparator);
		}

		return splittedString;
	}

	getToolTip(colHeader, el, td, maxStringLength?: number) {
		if (!this.disableTooltip) { // do not allow double tootlip when it is an object that has a pipe, maskFunction or displayImage
			if (colHeader.pipe || colHeader.maskFunction || colHeader.displayImages) {
				return '';
			}
		}
		if (maxStringLength) {
			const value = CoreUtil.getSplittedProperty(el, colHeader.key);
			if (value) {
				if (value.length > maxStringLength) {
					return this.tooltipMask(CoreUtil.getSplittedProperty(el, colHeader.key), colHeader.pipe, colHeader.pipeParams);
				}
			}
		}
		if (td.offsetWidth < td.scrollWidth) {
			return !colHeader.viewDetails ?
				(el.viewDetails ?
						td.innerText :
						!colHeader.displayImages ?
							this.tooltipMask(CoreUtil.getSplittedProperty(el, colHeader.key), colHeader.pipe, colHeader.pipeParams) :
							(colHeader.multiValue ?
									this.splitMultiImageNames(colHeader, el) :
									this.splitImageName(colHeader, el)
							)
				)
				: '';
		} else {
			return '';
		}
	}

	tooltipMask(value, pipe, params) {
		if (!pipe || (!value && value != 0)) {
			return value;
		}

		let ret = '';
		switch (pipe) {

			case 'taxId':
				ret = this._pipes.taxId.transform(value);
				break;
			case 'cpfCnpj':
				ret = this._pipes.cpfCnpj.transform(value);
				break;
			case 'zipCode':
				ret = this._pipes.zipCode.transform(value);
				break;
			case 'phone':
				ret = this._pipes.phone.transform(value, false);
				break;
			case 'mobile':
				ret = this._pipes.phone.transform(value, true);
				break;
			case 'boolean':
				ret = this._pipes.boolean.transform(value);
				break;
			case 'stringSplit':
				ret = this._pipes.stringSplit.transform(value, params);
				break;
			case 'stringLimit':
				ret = value;
				break;
			case 'time':
				ret = this._pipes.time.transform(value);
				break;
			case 'domainStatus':
				ret = this._pipes.domainStatus.transform(value);
				break;
			case 'date':
				ret = this._pipes.date.transform(value);
				break;
			case 'numberDecimal':
				ret = this._pipes.numberDecimal.transform(value, params[0], params[1], params[2], params[3]);
				break;
			case 'percent_and_large_pipe':
				ret = this._pipes.PercentLargePipe.transform(value);
				break;
			case 'negative_and_large_pipe':
				ret = this._pipes.negative_number_and_large_pipe.transform(value);
				break;
			case 'n_a':
				ret = this._pipes.NaPipe.transform(value);
				break;
			case 'large_number_pipe':
				ret = this._pipes.LargeNumberPipe.transform(value);
				break;
			case 'spacing_semicolon_comma':
				ret = this._pipes.spacing_semicolon_comma.transform(value);
				break;
			case 'extense_and_negative_pipe':
				ret = this._pipes.extense_and_negative_number_pipe.transform(value);
				break;
			case 'capitalizeFirstLetter':
				ret = this._pipes.capitalizeFirstLetter.transform(value);
				break;
			case 'empty_value':
				ret = this._pipes.empty_value.transform(value, params);
				break;
		}

		return ret;
	}

	viewDetailsClick(page) {
		this.showDetails = page ? false : !this.showDetails;

		for (const data of this.arrayData) {
			data.viewDetails = this.showDetails;
		}

		this.showDetailsChange.emit(this.showDetails);
	}

	splitProperty(obj: any, property: string, maxLength?: number) {
		if (maxLength) {
			const value = CoreUtil.getSplittedProperty(obj, property);
			if (value && value.length > maxLength) {
				return value.substring(0, maxLength) + '...';
			}
		}

		return CoreUtil.getSplittedProperty(obj, property);
	}

	updateTableHeight() {
		this._tableHeight = this._tableHeight != undefined ? this._tableHeight + 1 : 0;
		this._tableWidth = this._tableWidth != undefined ? this._tableWidth + 1 : 0;
	}

	splitImageName(colHeader: any, el: any) {
		let toolTipText = '';
		if (el[colHeader.key]) {
			const stringsArray = el[colHeader.key].split('/');
			toolTipText = stringsArray[stringsArray.length - 1];
		}

		return toolTipText;
	}

	splitMultiImageNames(colHeader: any, el: any): string {
		if (colHeader.getToolTipFunction) {
			return colHeader.getToolTipFunction(colHeader, el);
		} else {
			let toolTipText = '';

			if (el[colHeader.key]) {
				const stringsArray = el[colHeader.key].split(colHeader.addressStringSeparator);

				for (let i = 0; i < stringsArray.length; i++) {
					const str = stringsArray[i];
					const namesArray = str.split('/');
					const fileName = namesArray[namesArray.length - 1];
					toolTipText += fileName.split('.')[0];

					if (i < stringsArray.length - 1) toolTipText += ', ';
				}
			}

			return toolTipText;
		}
	}

	actionColumnWidth() {
		const defaultValue = 100;
		const multiplicationFactor = 35;
		if (this.arrayChildAction && this.arrayChildAction.length > 1) return this.arrayChildAction.length * multiplicationFactor;
		if (this.arrayAction && this.arrayAction.length > 1) return this.arrayAction.length * multiplicationFactor;

		return defaultValue;
	}

	buttonColummnWidth() {
		const defaultValue = 100;
		const multiplicationFactor = 25;
		if (this.arrayButton && this.arrayButton.length > 1) return this.arrayButton.length * multiplicationFactor;

		return defaultValue;
	}

	pageChanged(pagination) {
		this.pagination = pagination;

		if (this._activeTab != '' && this._activeTab != null) {
			this.paginationChange.emit({ pagination: this.pagination, activeTab: this._activeTab });
		} else {
			this.paginationChange.emit(this.pagination);

		}
		this.doSearch(true, true);

		this._expandedElements = [];
		this._expandAll = false;

		this.viewDetailsClick(true);
	}

	public doSearch(dropdownListFilter = false, isPagination = false) {
		if (!isPagination) {
			this.pagination.page = 0;
			this._actualPage = 0;
		}

		if (this._lastSearchText != this.searchText || dropdownListFilter) {
			this.globalSearch.emit(this.searchText);
			this.updateTableHeight();
			this._lastSearchText = this.searchText;
		}
	}

	isMapFilterEmpty() {
		let count = 0;
		if (this.mapFilter) {
			// tslint:disable-next-line: no-unused-variable
			for (const [key, filter] of this.mapFilter) {
				count += this.isMapSelectFilter(filter) ? filter.filter.value.length : filter.arrayValue.length;
			}
		}

		return count === 0;
	}

	isMapSelectFilter(filter) {
		return filter.filter && filter.filter.value;
	}

	isAllSelected() {
		let allSelected = false;
		if (this.mapFilter) {
			// tslint:disable-next-line: no-unused-variable
			for (const [key, filter] of this.mapFilter) {
				if (filter.allSelected) {
					allSelected = true;
				}
			}
		}

		return allSelected;
	}

	onRowSelection(isAllSelected: boolean, selectedRows: any): void {
		let selectedRowsValue: DataTableSelectedRows<any>;
		if (typeof isAllSelected != 'boolean') {
			selectedRowsValue = isAllSelected;
		} else {
			selectedRowsValue = {
				isAllSelected: isAllSelected,
				selectedRows: selectedRows,
				selectAllOnPage: this.isSelectAllOnPage,
			};
		}
		this.selectedRows.emit(selectedRowsValue);
	}

	unselectItem(comparisonValue: any, property = 'id') {
		this.arrayData.forEach(row => {
			if (row[property] == comparisonValue) {
				row.rowSelection = false;
			}
		});
	}

	isSelectAllOnPageDisabled() {
		const selected = this.arrayData.filter(data => data.rowSelection === true);

		const isSelectAll = () => document.querySelector('.multiple-pages-selection .select-all');

		return ((selected.length === this.pagination.totalData) && (selected.length > this.pagination.pageSize) && this.isMultiplePagesSelection);
	}

	displayActionTableCell(index: number, disabledRow: boolean): string {
		if (index % 2) {
			return disabledRow ? 'rgb(245, 245, 245)' : this._colors.light_gray;
		} else {
			return this._colors.white;
		}
	}

}
import { Pipe, PipeTransform } from '@angular/core';
import { CoreUtil } from '../core-util';

@Pipe({
	name: 'dropdownList',
	pure: false
})
export class DropdownListPipe implements PipeTransform {
	transform(arrayList: any, activedList: any[], property: string): any {
		if (!Array.isArray(arrayList) && arrayList != null) {
			arrayList = arrayList.arrayData;
		}
		if (property && property.length > 0 && arrayList && activedList) {
			return arrayList.filter(item => {
				return activedList.filter(actived => {
					return this.splitProperty(actived,property) == this.splitProperty(item,property);
				}).length == 0;
			});
		}

		return arrayList;
	}

	splitProperty(obj: any, property: string) {
		return CoreUtil.getSplittedProperty(obj, property);
	}
}
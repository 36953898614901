import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Icons } from 'src/icons';

@Component({
  selector: 'app-collapsable-section',
  templateUrl: './collapsable-section.component.html',
  styleUrls: ['./collapsable-section.component.scss']
})
export class CollapsableSectionComponent implements OnInit {

  @Input() isOpen: boolean = false;
  @Input() isArrowVisible: boolean = true;
  @Input() isSummaryCard: boolean = false;
  @Input() isRealTimeCard: boolean = false;

  @Output() onShow: EventEmitter<any> = new EventEmitter();
  @Output() onHide: EventEmitter<any> = new EventEmitter();
  @Output() onClose: EventEmitter<any> = new EventEmitter();

  public display = false;
  public myIcons = Icons;

  constructor() { }

  ngOnInit(): void {
  }

  closeSection(event) { //Use to emit events when closing the sidebar.
    this.onClose.emit({event});
  }

	onClick() {
		// Toggles the expanded state of the card.
		this.isOpen = !this.isOpen;
		// Decides wether to emit show or hide events.
		if (this.isOpen) {
			this.onShow.emit();
		} else {
			this.onHide.emit();
		}
	}
}

export class UserLanguage {
	locale: string;

	constructor(locale: string) {
		this.locale = locale;
	}

	getRequestData() {
		return { locale: this.locale };
	}
}
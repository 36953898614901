import { Component, OnInit, Input, Output, EventEmitter, ViewChild, SimpleChanges, OnDestroy } from '@angular/core';
import { CoreUtil } from '../../core-util';
import { ObjectFilterBaseService } from '../../service/object-filter-base.service';
import { Labels } from '../../../../internationalization/labels/labels';
import { Subscription, timer } from 'rxjs';
import { Icons } from 'src/icons';
import { Colors } from 'src/colors';
import { MatOption, MatSelect } from '@angular/material';
import { Response } from 'src/app/resource/dto/response';
import { ControlContainer } from '@angular/forms';

@Component({
	selector: 'app-port-terminals-dropdown-list',
	templateUrl: './port-terminals-dropdown-list.component.html',
	styleUrls: ['./port-terminals-dropdown-list.component.scss']
})
export class PortTerminalsDropdownListComponent implements OnInit, OnDestroy {
	@ViewChild('selectInput', { static: false }) selectInput: MatSelect;
	@ViewChild('optionAll', { static: false }) optionAll: MatOption;
	@ViewChild('hiddenInput', { static: false }) hiddenInput;
	@ViewChild('searchInput', { static: false }) searchInput;

	@Input() idProperty = 'id';
	@Input() displayProperty: string;
	@Input() auxDisplayProperty: string;
	@Input() placeholder: string;
	@Input() pipe: string;
	@Input() arrayData: any[];
	@Input() microService: string;
	@Input() endPoint: string;
	@Input() showPlaceholderAsDisplay = false;
	@Input() queryParams: string;
	@Input() disabled = false;
	@Input() allSelectionMode = false;
	@Input() allSelected = false;
	@Input() overlayAbsolute = false;
	@Input() multiple: boolean = true;
	@Input() customSearchText: string = '';
	@Input() dataTransformer: any[];
	@Input()
	set value(value: any | any[]) {
		if (value) {
			if (Array.isArray(value)) {
				this._value = [];

				for (const v of value) {
					if (v && v.id != -1) {
						if (this.arrayData && this.auxDisplayProperty) {
							const find = this.arrayData.find(a => a.id == v.id);
							if (find) {
								v[this.auxDisplayProperty] = find[this.auxDisplayProperty];
							}
						}

						this._value.push(v);
					}
				}
			} else {
				this._value = value;
			}
		}
	}

	@Output() valueChange = new EventEmitter();
	@Output() arrayDataChange = new EventEmitter();
	@Output() allSelectedChange = new EventEmitter();
	@Output() searchTextChange = new EventEmitter();
	@Output() isDropdownOpen = new EventEmitter();

	public _labels = Labels.getLabels();
	public _icons = Icons;
	public _colors = Colors.color;
	public _value: any | any[] = [];
	public _search = '';
	public _selectAll = false;
	public _showSelectAll = true;
	public _triggerError: boolean = false;
	public loadingOptionsList: boolean = false;
	public _selectAllValue = {
		id: -1,
		description: Labels.getLabels().select_all
	};

	private _unselectAll = false;
	private _selectAllClicked = false;
	private _dispatcher: Subscription;
	private _subscription: Subscription;
	private _inputEvent = new Event('input', {
		bubbles: true,
		cancelable: true,
	});

	constructor(private service: ObjectFilterBaseService) { }

	ngOnInit() { }

	ngOnChanges(changes: SimpleChanges) {
		if (changes) {
			if (changes.endPoint && changes.endPoint.currentValue) {
				this.getList(this.queryParams);
			}

			if (changes.displayProperty && changes.displayProperty.currentValue) {
				this._selectAllValue[this.displayProperty] = this._labels.component.select_all;
			}

			if (changes.auxDisplayProperty && changes.auxDisplayProperty.currentValue) {
				this._selectAllValue[this.auxDisplayProperty] = this._labels.component.select_all;
			}

			if (changes.value) {
				this.setErrorColor(false);
			}

			if (changes.customSearchText && changes.customSearchText.currentValue && !changes.customSearchText.isFirstChange) {
				this._search = changes.customSearchText.currentValue;
				this.callGetList();
			}

			if (changes.queryParams && changes.queryParams.currentValue && !changes.queryParams.isFirstChange) {
				this.callGetList();
			}

			if (changes.allSelected) {
				if (!changes.allSelected.currentValue && this.selectInput) {
					this.change([]);
					this.selectClick(this._selectAllValue);
				}
			}
		}
	}

	getList(queryParams: string) {
		if (!this.endPoint) return;

		if (this._subscription) {
			// cancel last request to get aways last response data
			this._subscription.unsubscribe();
		}

		this._subscription = this.service
			.getObjectFilterList(this.microService, this.endPoint, this._search, queryParams)
			.subscribe((resp: Response<any>) => {
				if (resp && resp.data) {
					this.arrayData = resp.data;

					if (this.dataTransformer) {
						this.arrayData.forEach(item => {
							if (this.dataTransformer.length > 1) {
								item = this.dataTransformer[0](item, ...this.dataTransformer.slice(1));
							} else {
								item = this.dataTransformer[0](item);
							}
						});
					}

					this.arrayDataChange.emit(resp.data);

					if (this.arrayData && this.auxDisplayProperty) {
						this.arrayData.forEach((value) => value[this.auxDisplayProperty] = value[this.displayProperty]);
					}

					if (!this.multiple && this._value) {
						this._value = this.arrayData.find(a => a.id == this._value.id);
					}
				}
				this.loadingOptionsList = false;
			}, err => {
				this.loadingOptionsList = false;
			}
		);
	}

	callGetList() {
		if (this._dispatcher) {
			this._dispatcher.unsubscribe();
		}

		this._dispatcher = timer(150).subscribe(() => {
			this.getList(this.queryParams);
		});
	}

	emitSearchChange() {
		if (this._search) {
			this.searchTextChange.emit(this._search);
		}
	}

	cleanSelectAll() {
		if (this.multiple) {
			this.unselectAll(this._value);
		}
	}

	splitProperty(obj: any) {
		return CoreUtil.getSplittedProperty(obj, this.auxDisplayProperty ? this.auxDisplayProperty : this.displayProperty);
	}

	unselectAll(event) {
		const all = event.find(v => v.id == -1);
		if (all) {
			event.splice(event.indexOf(all), 1);

			this._unselectAll = false;
			this._selectAll = false;
		}
	}

	triggerHiddenInput() {
		this.hiddenInput.nativeElement.dispatchEvent(this._inputEvent);
	}

	change(event) {
		if (!this.multiple) {
			this._value = event;
			this.valueChange.emit(this._value);
		} else {
			this.triggerHiddenInput();
			this.showSelectAll();
			this.changeAllSelected(false);

			setTimeout(() => {
				if (this._selectAll) {
					if (!this._unselectAll) {
						if (this._search || !this.allSelectionMode) {
							event = [{ id: -1, description: this._labels.component.select_all }];

							for (const data of this.arrayData) {
								event.push(Object.assign({}, data));
							}
						} else if (event.filter(e => e.id != -1).length > 0) {
							if (this._value.filter(v => v.id != -1).length == 0) {
								this.unselectAll(event);
							} else {
								event = [];
							}
						}
					} else {
						this.unselectAll(event);
					}
				} else if (this._selectAllClicked) {
					this._selectAllClicked = false;
					event = [];
				}

				this._value = event.filter(e => e.id != -1);

				this.valueChange.emit(this._value);
			});
		}
	}

	selectClick(value) {
		if (value && value.id == -1) {
			this._selectAll = this.optionAll && this.optionAll.selected;

			if (!this._selectAll) {
				this._selectAll = false; // avoid undefined
				const selectAll = this._value.find(v => v.id == -1);
				if (selectAll) {
					this._selectAllClicked = true;
				}

				this.changeAllSelected(false);
			} else if (!this._search) {
				this.changeAllSelected(true);
			}
		} else {
			this._unselectAll = this._selectAll;
		}
	}

	changeAllSelected(value) {
		this.allSelected = value;

		this.allSelectedChange.emit(this.allSelected);
	}

	hideSelectAll() {
		this._selectAll = false;
		this._showSelectAll = false;
	}

	showSelectAll() {
		if (!this._showSelectAll) {
			this._selectAll = true;
		}

		this._showSelectAll = true;
	}

	open(isOpened) {
		this.isDropdownOpen.emit(isOpened);
		if (isOpened && this.multiple) {
			setTimeout(() => {
				this.searchInput.nativeElement.focus();
			}, 1);
		} else if (this.multiple) {
			this._search = '';
			this.searchTextChange.emit(this._search);
			this.callGetList();
		} else if (!isOpened && !this.multiple) {
				if(this._search === "" && this._value === undefined) {
					this.valueChange.emit({ id: null, status: 'cleared' });
				}
			this.searchTextChange.emit(this._search);
		} else if (isOpened && !this.multiple) {
			this.loadingOptionsList = true;
			setTimeout(() => {
				this.searchInput.nativeElement.focus();
			});
			this.callGetList();
		}
	}

	setErrorColor(isError: boolean) {
		this._triggerError = isError;
	}

	ngOnDestroy() {
		if (this._dispatcher) {
			this._dispatcher.unsubscribe();
		}

		if (this._subscription) {
			this._subscription.unsubscribe();
		}
	}
}
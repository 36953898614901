import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'spacing_semicolon_comma'
})
export class SpacingSemicolonCommaPipe implements PipeTransform {
	transform(value: any): any {
		if (value) {
			value = value.replace(/;/g, "; ");
			value = value.replace(/,/g, ", ");			
		}

		return value;
	}
}
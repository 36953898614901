import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UrlHistoryService {

	public currentUrl$ = new BehaviorSubject<string>('')
	public previousUrl$ = new BehaviorSubject<string>('')

constructor() { }

}

import { Pipe, PipeTransform } from '@angular/core';
import { SnakeToPascalPipe } from './snake-to-pascal.pipe';

@Pipe({
	name: 'cutoffTypeDescription'
})
export class cutoffTypeDescription implements PipeTransform {
	transform(value: string, charSize: number = 3): string {
		if (value) {
			const words = new SnakeToPascalPipe().transform(value).split(' ');
			words[words.length - 1] =
				words[words.length - 1].length <= charSize
					? words[words.length - 1].toUpperCase()
					: words[words.length - 1];

			return words.join(' ');
		}

		return value;
	}
}

import { Directive, Input, Output, ElementRef, SimpleChanges, HostListener, EventEmitter, forwardRef } from '@angular/core';

@Directive({
	selector: '[thousandMaskDirective]',
})

export class ThousandMaskDirective {
	@Output() ngModelChange = new EventEmitter();
  @Input() locale;

	constructor(private element: ElementRef) {
	}

	getDecimalLocale(locale) {
		const decimal = {
			'pt-BR': ",",
			'en-US': ".",
		};
		return decimal[locale];
	}

	@HostListener('input', ['$event'])
	onInput(event: any) {
		let value = this.element.nativeElement.value;


		value = value.toString().replace(/\D/g, "").replace(/\,/g, "").replace(/\./g, "");
		if (value && value.length > 3) {
			value = value.replace(/([0-9]{3})$/, `${this.getDecimalLocale(this.locale)}$1`);
			// remove zero in first place
			if (value[0] == "0" && (value[1] && value[1] != "." && value[1] != "0")) {
				console.log(value.substring(1, value.length), 'substring');
				value = value.substring(1, value.length);
			}
		}
		if (value != "0") {
			this.element.nativeElement.value = value.replace(/^0+/, '');
			return this.element.nativeElement.value;
		} else {
			this.element.nativeElement.value = value;
			return this.element.nativeElement.value;
		}
	}
}


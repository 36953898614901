import { Component, Input } from '@angular/core';
import { TooltipPosition } from '@angular/material';
import { statusBulletEnum } from 'src/app/shared/enum/status-bullet.enum';

@Component({
  selector: 'app-status-bullet',
  templateUrl: './status-bullet.component.html',
  styleUrls: ['./status-bullet.component.scss']
})
export class StatusBulletComponent {

	_statusBullet = statusBulletEnum;

	// Input to set a color ID
	@Input() colorId: number = this._statusBullet.GRAY;
	// Input to set a text for the Label or Tooltip
	@Input() textContent: string;
	//Input to set a text for the tooltip.
	@Input() tooltipTextContent: string;
	// Input to set whether to display the tooltip
	@Input() showTooltip: boolean = false;
	// Input to set whether to display the Label
	@Input() showLabel: boolean = false;
	// Input to set a Tooltip Position
	@Input() tooltipPosition: TooltipPosition = 'above';

	getBackgroundColor(): string {
		switch(this.colorId) {
			case this._statusBullet.RED:
				return 'red';
			case this._statusBullet.GREEN:
				return 'green';
			case this._statusBullet.GRAY:
				return 'gray';
			case this._statusBullet.YELLOW:
				return 'yellow'
			case this._statusBullet.BLACK:
				return 'black'
			case this._statusBullet.HALF_RED_GREEN:
				return 'half-red-green'
			case this._statusBullet.HALF_GREEN_RED:
				return 'half-green-red'
			default:
				return 'gray';
		}
	}

	getTooltip(): string {
		if(this.tooltipTextContent){
			return this.tooltipTextContent;
		}
		return this.showTooltip ? this.textContent : null;
	}

}
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Pagination } from '../../dto/pagination';

@Component({
	selector: 'app-pagination',
	templateUrl: './pagination.component.html',
	styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit, OnChanges {
	@Input() arrayHeader: any[];
	@Input() arrayAction: any[];
	@Input() allowSelection: boolean;
	@Input() allowSelectionStart: boolean;
	@Input() tableWidth: number;
	@Input() labels;
	@Input() pagination: Pagination;
	@Input() arrayData: any[];
	@Input() displayedColumns: any;
	@Input() actualPage: number;
	@Input() showNumberPages = true;

	@Output() pageChange = new EventEmitter();

	constructor() { }

	ngOnInit() { }

	ngOnChanges(changes: SimpleChanges) {
		if (changes && changes.actualPage && changes.actualPage.currentValue) {
			this.page(changes.actualPage.currentValue);
		}
	}

	page(i) {
		if (i != this.pagination.page && i >= 0 && i < this.pagination.totalPages) {
			this.pagination.page = i;

			this.pageChange.emit(this.pagination);
		}
	}
}
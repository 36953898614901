import { Component, OnInit, Renderer2, ViewChild, ElementRef, Input, Output, EventEmitter, OnDestroy, OnChanges } from '@angular/core';
import { Labels } from 'src/internationalization/labels/labels';

@Component({
  selector: 'app-multicolumn-dropdown',
  templateUrl: './multicolumn-dropdown.component.html',
  styleUrls: ['./multicolumn-dropdown.component.scss']
})
export class MulticolumnDropdownComponent implements OnInit, OnDestroy, OnChanges {

  @ViewChild('toggleDropdown', {static: true}) toggleDropdown: ElementRef;
  @Output() valueChangeDropdown = new EventEmitter<any>();
  @Output() selectSelectHandler = new EventEmitter<any>();
  @Input() dataSource: any;
  @Input() displayedColumns: string[];
  @Input() disabled: boolean = false;
  @Input() value: any;
	@Input() hasSelect: any;
	@Input() mandatoryAsterisk: boolean = true;
  @Input() agreementClass: boolean = false;
  listenFunc: Function;
  labels = Labels.getLabels();

  constructor(private renderer: Renderer2) {
    this.listenFunc = this.renderer.listen('window', 'click',(e:Event)=> {
      try {
        if(e.target == this.toggleDropdown.nativeElement && !this.disabled){
          if(!this.isOpen) {
            this.isOpen = true;
          };
        }
        if(e.target !== this.toggleDropdown.nativeElement){
          if(this.isOpen) {
            this.isOpen = false;
          };
        }
      }
      catch {}
    });
  }

  ngOnInit(): void {
  }

	ngOnChanges (changes) {
		if (changes.dataSource) {
			if (changes.dataSource.currentValue && this.hasSelect) {
				const newArr = [{id: '0000', taxID: "", cityName: '', description: '  --SELECT--', exempt: false, state: '', stateRegistration: []}]
				this.dataSource = newArr.concat(changes.dataSource.currentValue)
			}
		}
	}

  ngOnDestroy() {
    this.listenFunc();
  }

  isOpen: boolean = false;

  openDropdown() {
    this.isOpen = true;
  }

  selectingRow(row) {
		if (row.id === "0000") {
			this.selectSelectHandler.emit()
			return;
		}
    this.value = row;
    this.valueChangeDropdown.emit(row);
  }
};


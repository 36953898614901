import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadService {
  constructor() {}

  public _loading$ = new Subject<boolean>();
  private _activeLocalLoader = new Subject<boolean>();
  private _isLoading: boolean = false;
  private _hideAll = new Subject<boolean>();

  public show(): void {
    this._nextValue(true);
  }

  public hide(): void {
    this._nextValue(false);
  }

  public choseIfHideAll(hide: boolean): void {
    this._nextValue(hide);
    this._hideAll.next(hide);
  }

  public isHideAll(): Subject<boolean> {
    return this._hideAll;
  }

  public setLocalLoader(value: boolean): void {
    this._activeLocalLoader.next(value);
 }

  public isActiveLocalLoader(): Subject<boolean> {
    return this._activeLocalLoader;
 }

  public isLoading(): boolean {
    return this._isLoading;
  }

  public isStopped(): boolean {
    return !this.isLoading();
  }

  private _nextValue(status: boolean) {
    this._isLoading = status;
    this._loading$.next(status);
  }
}
import { DOCUMENT } from '@angular/common';
import {
	Component,
	EventEmitter,
	Inject,
	Input,
	OnInit,
	Output,
	ViewEncapsulation
} from '@angular/core';
import { Icons } from 'src/icons';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
	// encapsulation: ViewEncapsulation.None
})
export class SidebarComponent implements OnInit {

  @Input() useHeader: boolean = true;
  @Input() useBody: boolean = true;
  @Input() useFooter: boolean = false;
  @Input() closeIcon: boolean = false;
  @Input() showTopCloseIcon: boolean = true;
  @Input() sidebarPosition: string = "right"; // You can define a side on your screen using: left, top & bootom.
  @Input() isOpen: boolean = false;

  @Output() onShow: EventEmitter<any> = new EventEmitter();
  @Output() onHide: EventEmitter<any> = new EventEmitter();
  @Output() onClose: EventEmitter<any> = new EventEmitter();

  public display = false;
  public myIcons = Icons;

  constructor(@Inject(DOCUMENT) private document: Document) { }

  ngOnInit(): void {
  }

	ngAfterViewInit() {
		this.addBackdrop();
	}

	ngOnDestroy(): void {
		const element = this.document.querySelector('.sidebar-custom-backdrop');
		if (element) {
			element.parentElement!.removeChild(element);
		}
	}

	clickBackdropHandler() {
		this.isOpen = false;
		this.onHide.emit(this.isOpen);
	}

	addBackdrop() {
		const divElement = this.document.querySelector('.sidebar');

		if (!divElement) return;
		const bodyElement = this.document.querySelector('body app-master');
		const bodyHeight = bodyElement.getBoundingClientRect().height;
		const element = this.document.createElement('div');
		element.addEventListener('click', this.clickBackdropHandler.bind(this));

		const customStyles = {
			width: '100vw',
			height: bodyHeight + 'px',
			left: '0px',
			top: '0px',
			background: 'rgba(0, 0, 0, 0.5)',
			position: 'absolute',
			'z-index': '102' // somewhy the z-index of the table headers is 101. don't ask me why.
		};

		element.classList.add(`sidebar-custom-backdrop`);

		(element as HTMLElement).style.cssText = Object.keys(customStyles)
			.map((key) => `${key}: ${customStyles[key]}`)
			.join(';');

		(this.document.querySelector('body app-master') as HTMLElement).appendChild(
			element
		);
	}


  closeSidebar(event) { //Use to emit events when closing the sidebar.
    this.onClose.emit({event});
  }

}

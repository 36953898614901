import { Component, Input, OnInit } from '@angular/core';
import { Icons } from 'src/icons';
import { Labels } from 'src/internationalization/labels/labels';

@Component({
  selector: 'app-vessel-utilization',
  templateUrl: './vessel-utilization.component.html',
  styleUrls: ['./vessel-utilization.component.scss']
})
export class VesselUtilizationComponent implements OnInit {

  @Input() data;
  @Input() perVvd: boolean = false;
  public _labels = Labels.getLabels();
	public _icons = Icons;
  icons = Icons.icon;

	constructor() { }

	ngOnInit(): void {
  }


	percentageGreaterTotal(value: number): string {
		return value > 100 ? 'atentionNeeded' : 'none'
	}

}

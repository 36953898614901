import {Component, Input, OnInit} from '@angular/core';
import {Icons} from '../../../../icons';
import {TooltipPosition} from '@angular/material';

@Component({
  selector: 'app-status-icon',
  templateUrl: './status-icon.component.html',
  styleUrls: ['./status-icon.component.scss']
})
export class StatusIconComponent implements OnInit {

	// Input to set icon
	@Input() icon: string;
	// Input to set icon
	@Input() scndIcon: string;
	// Input to set a text for the Label or Tooltip
	@Input() textContent: string;
	//Input to set a text for the tooltip.
	@Input() tooltipTextContent: string;
	//Input to set a text for the tooltip.
	@Input() scndTooltipTextContent: string;
	// Input to set whether to display the tooltip
	@Input() showTooltip: boolean = false;
	// Input to set whether to display the tooltip
	@Input() showScndTooltip: boolean = false;
	// Input to set whether to display the Label
	@Input() showLabel: boolean = false;
	// Input to set a Tooltip Position
	@Input() tooltipPosition: TooltipPosition = 'above';
	// Input to set a Tooltip Position to the second image tooltip
	@Input() scndTooltipPosition: TooltipPosition = 'above';
	// Input to configure the text color	
	@Input() colorText: string = '#335C92';
	// Input to configure the text size 
	@Input() fontSizeText: string = '16px';
	// Input to set second icon after text if needed.
	@Input() iconAfterText: boolean = false;

	_icons = Icons;

  constructor() { }

  ngOnInit(): void {
  }

	getTooltip(): string {
		if(this.tooltipTextContent){
			return this.tooltipTextContent;
		}
		return this.showTooltip ? this.textContent : null;
	}

	getScndTooltip(): string {
		if(this.scndTooltipTextContent){
			return this.scndTooltipTextContent;
		}
		return this.showScndTooltip ? this.scndTooltipTextContent : null;
	}
}
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ListItemsDropdownComponent} from './list-items-dropdown.component';
import {MatIconModule} from '@angular/material';



@NgModule({
  declarations: [ListItemsDropdownComponent],
	imports: [
		CommonModule,
		MatIconModule
	],
	exports: [
		ListItemsDropdownComponent
	]
})
export class ListItemsDropdownModule { }

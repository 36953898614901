import { Component, Input, OnInit, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { Icons } from 'src/icons';
import { Labels } from 'src/internationalization/labels/labels';

@Component({
  selector: 'app-report-view',
  templateUrl: './report-view.component.html',
  styleUrls: ['./report-view.component.scss']
})
export class ReportViewComponent implements OnInit {

	@Output() changeVisualization = new EventEmitter()

	@Input() showReport: boolean = true;
	@Input() reportData;
	@Input() data;
	infoReport: any
	public _labels = Labels.getLabels();
	public iconPdf = Icons.getIconPath(Icons.icon.file_pdf_btn);
	nextAllocation;

  constructor() { 
	
  }

  ngOnInit(): void {
		this.reportData = JSON.parse(window.localStorage.getItem('report-data'))
		this.infoReport = JSON.parse(this.reportData)
		this.data = JSON.parse(window.localStorage.getItem('data-header'));
  }

	callCloseFunction(){
		// this.changeVisualization.emit();
		window.close()
		window.localStorage.removeItem('report-data')
		window.localStorage.removeItem('data-header')
	}

	translateDirection(direction){
		switch(direction){
			case 'N' :
				return 'North'
			case 'S' :
				return 'South'
			case 'E' :
				return 'East'
			case 'W' :
				return 'West'
		}
	}

	percentageGreaterTotal(value: number): string {
		return value > 100 ? 'atentionNeeded' : 'none'
	}
	changeColorsBar(team: string): string {
		switch (team) {
		  case 'CABOTAGE':
			return '#1E4E8A';
			break;
		  case 'TS_CABOTAGE':
			return '#42ACDB';
			break;
		  case 'TS CABOTAGE':
			return '#42ACDB';
			break;
		  case 'IMPORT':
			return '#920101';
			break;
		  case 'EXPORT':
			return '#EC0101';
			break;
		  case 'FEEDER':
			return '#FCD34F';
			break;
		  case 'EMPTY':
			return '#CED3D5';
			break;
		}
	  }
	
	  changeColorsTable(team: string): string {
		switch (team) {
		  case 'CABOTAGE':
			return '#1e4e8a33';
			break;
		  case 'TS_CABOTAGE':
			return '#86bcff33';
			break;
		  case 'TS CABOTAGE':
			return '#86bcff33';
			break;
		  case 'IMPORT':
			return '#92010126';
			break;
		  case 'EXPORT':
			return '#ec010126';
			break;
		  case 'FEEDER':
			return '#fcd34f33';
			break;
		  case 'EMPTY':
			return '#ced3d533';
			break;
		}
	  }


}

// Used to get roles of user. Data after '=' only a-z|0-9
export enum EElementsType {
	additional_fees_port = 'additionalfeesport',
	additional_fees_ppt = 'additionalfeesppt',
	additional_fees_terminal = 'additionalfeesterminal',
	additional_fees_transport_imd = 'additionalfeestransportimd',
	associate_customer = 'associatecustomer',
	associate_plate_transport_imd_road_dedicated = 'associateplatetransportimdroaddedicated',
	associate_plate_transport_imd_road_owned = 'associateplatetransportimdroadowned',
	associate_policy = 'associatepolicy',
	associate_vendor = 'associatevendor',
	brokers = 'brokers',
	checklist = 'checklist',
	contacts = 'contacts',
	costing_ppt = 'costingppt',
	costing_transport_imd_barge_rail_dedicated = 'costingtransportimdbargeraildedicated',
	costing_transport_imd_barge_rail_spot = 'costingtransportimdbargerailspot',
	costing_transport_imd_road_dedicated = 'costingtransportimdroaddedicated',
	costing_transport_imd_road_owned = 'costingtransportimdroadowned',
	costing_transport_imd_road_spot = 'costingtransportimdroadspot',
	customer = 'customer',
	exceptions = 'exceptions',
	facility = 'facility',
	general_capacity_transport_imd_barge_rail = 'generalcapacitytransportimdbargerail',
	general_capacity_transport_imd_road_dedicated = 'generalcapacitytransportimdroaddedicated',
	general_capacity_transport_imd_road_owned = 'generalcapacitytransportimdroadowned',
	general_capacity_transport_imd_road_spot = 'generalcapacitytransportimdroadspot',
	historic = 'historic',
	main_data = 'maindata',
	operational = 'operational',
	specific_capacity_for_customer = 'specificcapacityforcustomer',
	specific_capacity_for_ddr = 'specificcapacityforddr',
	states = 'states',
	validate_checklist = 'validatechecklist',
	vessel_main_data = 'vesselmaindata'
}
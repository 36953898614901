import { Component, ViewChild, Input } from '@angular/core';
import { MatMenu } from '@angular/material';
import { AuthGuard } from 'src/app/resource/guard/auth.guard';
import { Icons } from 'src/icons';
import { Labels } from 'src/internationalization/labels/labels';

@Component({
	selector: 'app-settings-menu',
	templateUrl: './settings-menu.component.html',
	styleUrls: ['./settings-menu.component.scss']
})
export class SettingsMenuComponent {
	@ViewChild(MatMenu, { static: true }) menu: MatMenu;

	@Input() menuItemArray: any[];
	@Input() title: string = '';
	@Input() xPosition: string = 'before';
	@Input() yPosition: string = 'below';

	public _logoDir = './../../../../assets/logo/';
	public _icons = Icons;
	private _labels = Labels.getLabels();

	constructor(public authGuard: AuthGuard) { }

	hasAnyRole(pathList): boolean {
		return !!pathList.find(path => this.authGuard.userHasRole(
			path.category, path.role, path.module, path.subModule, path.element, path.action));
	}

	getLabel(label): string {
		return this._labels[label];
	}
}
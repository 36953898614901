import { Component, OnInit, Input, Output, EventEmitter, ViewChild, SimpleChanges, OnDestroy } from '@angular/core';
import { CoreUtil } from '../../core-util';
import { ObjectFilterBaseService } from '../../service/object-filter-base.service';
import { Labels } from '../../../../internationalization/labels/labels';
import { Subscription, timer } from 'rxjs';
import { Icons } from 'src/icons';
import { Colors } from 'src/colors';
import { FloatLabelType, MatOption, MatSelect } from '@angular/material';
import { Response } from 'src/app/resource/dto/response';

@Component({
	selector: 'app-dropdown-simpler-list',
	templateUrl: './dropdown-simpler-list.component.html',
	styleUrls: ['./dropdown-simpler-list.component.scss']
})
export class DropdownSimplerListComponent implements OnInit, OnDestroy {
	@ViewChild('selectInput', { static: false }) selectInput: MatSelect;
	@ViewChild('optionAll', { static: false }) optionAll: MatOption;
	@ViewChild('hiddenInput', { static: false }) hiddenInput;
	@ViewChild('searchInput', { static: false }) searchInput;

	@Input() idProperty = 'id';
	@Input() displayProperty = 'description';
	@Input() auxDisplayProperty: string;
	@Input() placeholderInside: string;
	@Input() placeholder: string;
	@Input() isCharacteristicRequired: boolean = false;
	@Input() arrayData: any[];
	@Input() endPoint: string;
	@Input() showPlaceholderAsDisplay = false;
	@Input() disabled = false;
	@Input() allSelectionMode = false;
	@Input() allSelected = false;
	@Input() showAllSelected = true;
	@Input() overlayAbsolute = false;
	@Input() multiple: boolean = true;
	@Input() customSearchText: string = '';
	@Input() dataTransformer: any[];
	@Input() hasSearch = true; // show up search bar
	@Input() callSearchOnClose = true;
	@Input() inputSearchLength: string;
	@Input() error: boolean;
	@Input() noSelect: boolean = false;
	@Input() showLabelSelectAll = false;
	@Input() selectAllLabel = Labels.getLabels().select_all;
	@Input() returnSelectAllValue = false;
	@Input() floatLabel: FloatLabelType = 'auto';
	@Input() spanBg: string
	@Input() value = {id: '', description: ''}
	@Input() updatedValue
	@Output() valueChange = new EventEmitter();
	@Output() arrayDataChange = new EventEmitter();
	@Output() allSelectedChange = new EventEmitter();
	@Output() searchTextChange = new EventEmitter();
	@Output() isDropdownOpen = new EventEmitter();
	@Output() blur = new EventEmitter();

	private _labels = Labels.getLabels();
	private _icons = Icons;
	private _colors = Colors.color;
	private _value: any | any[] = [];
	private _search = '';
	private _selectAll = false;
	private _showSelectAll = true;
	private _triggerError: boolean = false;
	private loadingOptionsList: boolean = false;
	private _selectAllValue = {
		id: -1,
		description: this.selectAllLabel
	};
	private _error = false;
	private _unselectAll = false;
	private _selectAllClicked = false;
	private _dispatcher: Subscription;
	private _subscription: Subscription;
	private _inputEvent = new Event('input', {
		bubbles: true,
		cancelable: true,
	});
	private valueTest = '';


	constructor(
		private service: ObjectFilterBaseService,
	) {}

	ngOnInit() {
		this._triggerError = this.error;
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes) {
			if (changes.updatedValue) {
				if (changes.updatedValue.currentValue) {
					this._value = changes.updatedValue.currentValue
				}
			}
			if (changes.arrayData && !this.noSelect) {
				if (changes.arrayData.currentValue) {
					const newArr = [{id: '0000', description: "  --SELECT--"}]
					this.arrayData = newArr.concat(changes.arrayData.currentValue)
				}
			}
		}
	}

	emitSearchChange() {
		this.searchTextChange.emit(this._search);
	}

	cleanSelectAll() {
		if (this.multiple) {
			this.unselectAll(this._value);
		}
	}

	splitProperty(obj: any) {
		return CoreUtil.getSplittedProperty(obj, this.auxDisplayProperty ? this.auxDisplayProperty : this.displayProperty);
	}

	unselectAll(event) {
		const all = event.find(v => v.id == -1);
		if (all) {
			event.splice(event.indexOf(all), 1);

			this._unselectAll = false;
			this._selectAll = false;
		}
	}

	triggerHiddenInput() {
		this.hiddenInput.nativeElement.dispatchEvent(this._inputEvent);
	}

	change(event) {
		if (event.id === "0000") {
			console.log('select triggered!!')
			this._search = ''
			this._value = {id: '', description: ''}
			this.valueChange.emit({
				id: '0000',
				description: 'select'
			});
		} else {
			this._value = event;
			this.valueChange.emit(this._value);
		}
	}

	selectClick(value) {
		if (value && value.id == -1) {
			this._selectAll = this.optionAll && this.optionAll.selected;

			if (!this._selectAll) {
				this._selectAll = false; // avoid undefined
				const selectAll = this._value.find(v => v.id == -1);
				if (selectAll) {
					this._selectAllClicked = true;
				}

				this.changeAllSelected(false);
			} else if (!this._search) {
				this.changeAllSelected(true);
			}
		} else {
			this._unselectAll = this._selectAll;
		}
	}

	changeAllSelected(value) {
		this.allSelected = value;

		this.allSelectedChange.emit(this.allSelected);
	}

	emitBlur () {
		console.log('emitBlur', this.valueTest)
		this.blur.emit()
	}

	hideSelectAll() {
		this._selectAll = false;
		this._showSelectAll = false;
	}

	showSelectAll() {
		if (!this._showSelectAll) {
			this._selectAll = true;
		}

		this._showSelectAll = true;
	}

	compareCategoryObjects(object1: any, object2: any) {
    return object1 && object2 && object1.id == object2.id;
	}

	open(isOpened) {
		this.isDropdownOpen.emit(isOpened);
	}

	setErrorColor(isError: boolean) {
		this._triggerError = isError;
	}

	ngOnDestroy() {
		if (this._dispatcher) {
			this._dispatcher.unsubscribe();
		}

		if (this._subscription) {
			this._subscription.unsubscribe();
		}
	}

	logStuff() {
		console.log(this)
	}

	getTooltip(): string {
		if (!this.disabled) {
			return '';
		}
		if (!this._value) {
			return '';
		}
		if (this._value.length < 2) {
			return '';
		}
		if (Array.isArray(this._value)) {
			const descriptionList: string[] = this._value.map(value => value[this.displayProperty]);
			return descriptionList.join(', ');
		}
	}
}
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'zipCode'
})
export class ZipCodePipe implements PipeTransform {
	transform(value: any): any {
		if (value && value.length === 8) {
			value = value.substring(0,8).replace(/^(\d{5})?(\d{3})?/, '$1-$2');
		}

		return value;
	}
}
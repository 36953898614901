import { Injectable } from '@angular/core';
import { Constants } from 'src/constants';
import { AbstractService } from '../abstract/abstract-service';
import { Historic } from '../dto/historic';
import { Pagination } from '../dto/pagination';
import { HttpClientService } from './http-client.service';

@Injectable({
	providedIn: 'root'
})
export class HistoryService extends AbstractService<Historic> {

	constructor(httpClientService: HttpClientService) {
		super(httpClientService);

		this._microService = Constants.constant.microServices.historic;
	}

	isValid(object: Historic): boolean {
		return true;
	}

	getRequestData(object: Historic) {
		return object;
	}
	// TODO Implement future Historic API
	// Removal requested by thiago varjão
	// getListHistory(idQuery, fromDate, toDate, userIdArray: any[], pagination: Pagination) {
	// 	return this.getHistoricList(idQuery, fromDate, toDate, userIdArray, pagination);
	// }


	updateState(object: Historic) {
		if (object) { this._trigger.next(object); }
	}

	updateHistoryState(object: Historic) {
		if (object) { this._historyTrigger.next(object); }
	}

	setEndpoint(endpoint: string): void {
		this._endPoint = endpoint;
	}
}

import { Directive, HostListener } from '@angular/core';

@Directive({
    selector: '[onlyNumber]'
})
export class OnlyNumberDirective {
    @HostListener('keypress', ['$event']) onKeyPress(event: KeyboardEvent) {
		const pattern = /^[0-9]+$/;
		let inputChar = String.fromCharCode(event.charCode);
		if (!pattern.test(inputChar)) {
			event.preventDefault();
		}
	}

	@HostListener('paste', ['$event']) onPaste(event: ClipboardEvent) {
		const clipboardData = event.clipboardData || (window as any).clipboardData;
		const pastedText = clipboardData.getData('text');
		const pattern = /^[0-9]+$/;
		if (!pattern.test(pastedText)) {
			event.preventDefault();
		}
	}


}

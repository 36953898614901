import { Injectable } from '@angular/core';
import { User } from 'src/app/resource/dto/user';
import { HttpClientService } from 'src/app/core/service/http-client.service';
import { AbstractService } from 'src/app/core/abstract/abstract-service';
import { isNullOrUndefined } from 'util';
import { CoreUtil as c } from 'src/app/core/core-util';
import { Constants } from 'src/constants';

@Injectable({
	providedIn: 'root'
})
export class UsersService extends AbstractService<User> {
	constructor(public httpclient: HttpClientService) {
		super(httpclient);

		this._microService = Constants.constant.microServices.authorizer;
		this._endPoint = Constants.constant.endPoints.user;
	}

	getRequestData(user: User, mode?: boolean) {
		if (!mode) {
			const requestData = {
				identification: user.identification,
				profileId: user.profile.id,
				managementRoleIdList: user.brandRoleList.map(brand => brand.id),
				departmentRoleIdList: user.departmentRoleList ? user.departmentRoleList.map(dep => dep.id) : undefined,
				regionRoleIdList: user.regionRoleList ? user.regionRoleList.map(dep => dep.id) : undefined,
				status: user.status && user.status === 'A' ? 'A' : 'I',
			};

			return requestData;
		} else {
			const requestData = {
				identification: user.identification,
				profileId: user.profile.id,
				brandRoleIdList: user.brandRoleList.map(brand => brand.id),
				departmentRoleIdList: user.departmentRoleList ? user.departmentRoleList.map(dep => dep.id) : undefined,
				regionRoleIdList: user.regionRoleList ? user.regionRoleList.map(dep => dep.id) : undefined,
				status: user.status && user.status === 'A' ? 'A' : 'I'
			};

			return requestData;
		}
	}

	updateState(object: User) {
		this._trigger.next(object);
	}

	updateHistoryState(object: User) {
		this._historyTrigger.next(object);
	}

	isValid(user: User) {
		return (c.isNotEmpty(user.identification)) &&
			(!isNullOrUndefined(user.profile)) &&
			(user.departmentRoleList && user.departmentRoleList.length > 0) &&
			(user.brandRoleList && user.brandRoleList.length > 0) &&
			(user.regionRoleList && user.regionRoleList.length > 0);
	}
}
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'empty_value'
})
export class EmptyValuePipe implements PipeTransform {
	transform(value: any, output: string): string {
		if (isNaN(value)) {
			return !value ? output : value;
		}
		return value == 0 || !value ? output : formatNumber(value.toString());
		
	}
}

function formatNumber(value: string): string {
	const parts = value.split(/[,\.]/);
	parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
	return parts.join(',');
}
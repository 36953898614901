import { Directive, HostListener, forwardRef, ElementRef } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';
import { Colors } from 'src/colors';

@Directive({
	selector: '[appAlphanumericValidator]',
	providers: [
		{ provide: NG_VALIDATORS, useExisting: forwardRef(() => AlphanumericValidatorDirective), multi: true }
	]
})
export class AlphanumericValidatorDirective implements Validator {
	private control: AbstractControl;
	private regex: any = /[^a-zA-Z0-9]+/;

	constructor(private element: ElementRef) { }

	validate(c: AbstractControl): { [key: string]: any } {
		this.control = c;
		this.element.nativeElement.parentElement.style.backgroundColor = '';

		return null;
	}

	@HostListener('blur', ['$event']) blur($event: any) {
		if (this.control.value) {
			if (this.regex.test(this.control.value)) {
				this.element.nativeElement.parentElement.style.backgroundColor = Colors.color.red_error_background;
				this.control.setErrors({ alphanumericValid: false });

				return;
			}
		}

		this.element.nativeElement.parentElement.style.backgroundColor = '';
		this.control.setErrors(null);

		return;
	}
}
import { Injectable } from '@angular/core';
import { Constants } from 'src/constants';
import { AbstractService } from '../abstract/abstract-service';
import { UserLanguage } from '../dto/userlanguage-dto';
import { HttpClientService } from './http-client.service';

@Injectable({
	providedIn: 'root'
})
export class UserLanguageService extends AbstractService<UserLanguage>{

	constructor(httpClientService: HttpClientService) {
		super(httpClientService);

		this._microService = Constants.constant.microServices.authorizer;
		this._endPoint = Constants.constant.endPoints.userlanguage;
	}

	isValid(object: UserLanguage): boolean {
		return true;
	}

	getRequestData(object: UserLanguage) {
		return object.getRequestData();
	}

	updateState(object: UserLanguage): void {
	}

	updateHistoryState(object: UserLanguage): void {
	}
}
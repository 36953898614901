import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Icons } from 'src/icons';

@Component({
	selector: 'app-expansion-card',
	templateUrl: './expansion-card.component.html',
	styleUrls: ['./expansion-card.component.scss']
})
export class ExpansionCardComponent implements OnInit {
	@ViewChild('expansionPanel', { static: false }) expansionPanel;

	@Input() icon: string;
	@Input() title: string;
	@Input() titleBold = false;
	@Input() closedTitle: string;
	@Input() simpleTitle = false;
	@Input() expanded = false;
	@Input() showHr = true;
	@Input() hideToggle = false;
	@Input() disabled = false;

	@Output() opened = new EventEmitter();

	public _icons = Icons;

	constructor() { }

	ngOnInit() { }
}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Icons } from 'src/icons';
import { Labels } from 'src/internationalization/labels/labels';

@Component({
  selector: 'app-content-modal',
  templateUrl: './content-modal.component.html',
  styleUrls: ['./content-modal.component.scss']
})
export class ContentModalComponent implements OnInit {

  @Input() isOpen: boolean = false;
  @Input() useHeader: boolean = true;
  @Input() useBody: boolean = true;
  @Input() useFooter: boolean = false;
  
  @Output() onShow: EventEmitter<any> = new EventEmitter();
  @Output() onHide: EventEmitter<any> = new EventEmitter();
  @Output() onClose: EventEmitter<any> = new EventEmitter();

  contentModalStyle = {
    width: 539,
    height: 473,
  }

  public display = false;
  public myIcons = Icons;

  constructor() { }

  ngOnInit(): void {
  }

  closeContentModal(event) { //Use to emit events when closing the sidebar.
    this.onClose.emit({event});
  }


}

import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { ToastService } from '../../service/toast.service';
import { Messages } from 'src/internationalization/messages/messages';
import { Constants } from 'src/constants';
import { Icons } from 'src/icons';

@Component({
	selector: 'app-text-list-input',
	templateUrl: './text-list-input.component.html',
	styleUrls: ['./text-list-input.component.scss']
})
export class TextListInputComponent implements OnInit {
	@ViewChild('inputField', { static: false }) inputField;

	@Input() pipe: string;
	@Input() placeholder: string;
	@Input() disabled = false;
	@Input() maxlength = 1024;
	@Input() value: string[];
	@Input() showAll = true;
	@Input() allowDuplicate = false;
	@Input() duplicateLabelMessage: string;
	@Input() removeDisabled = false;
	@Output() valueChange = new EventEmitter();

	private _messages = Messages.getMessages();
	public _icons = Icons;
	public _text: string;

	private _constants = Constants.constant;

	constructor(private toastService: ToastService) { }

	ngOnInit() { }

	change() {
		if (this._text) {
			if (!this.allowDuplicate && this.value.find(v => v == this._text)) {
				this.displayError();

				this.toastService.show(this._constants.messageType.error, this.duplicateLabelMessage + ' ' + this._text + ' ' +
						this._messages.duplicate_input);

			} else {
				this.value.push(this._text);
				this.valueChange.emit(this.value);
				this._text = undefined;
			}
		}
	}

	displayError() {
		const parent = this.inputField.element.nativeElement.parentElement;

		if (parent.className.indexOf(' input-error') < 0) {
			parent.className += ' input-error';
		}
	}

	removeError() {
		this.inputField.element.nativeElement.parentElement.className =
			this.inputField.element.nativeElement.parentElement.className.replace(' input-error', '');
	}

	remove(val) {
		this.value.splice(this.value.indexOf(val), 1);
		this.valueChange.emit(this.value);
	}
}
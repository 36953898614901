import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Labels } from '../../../../internationalization/labels/labels';
import { Icons } from '../../../../icons';
import { EButtonDirective } from '../../../resource/enums/e-button-directive.enum';

@Component({
	selector: 'app-search-input',
	templateUrl: './search-input.component.html',
	styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent implements OnInit, OnChanges {

	_labels = Labels.getLabels();
	_icons = Icons;
	searchText = '';
	_eButtonDirective = EButtonDirective;

	@Input() visibleFilter = true;
	@Input() searchFinished = false;
	@Input() showClearAll = false;
	@Input() clear = false;
	@Input() searchDisabled = false;
	@Input() showFilter = false;
	@Input() disableFilter = true;
	@Output() showFilterEvent = new EventEmitter();
	@Output() searchEvent = new EventEmitter();
	@Output() clearAll = new EventEmitter();


	constructor() { }

	ngOnInit(): void {

	}

	ngOnChanges() {
		if (!this.disableFilter) {
			this.searchText = '';
			this.searchDisabled = true;
		}
		if (this.disableFilter) {
			this.searchDisabled = false;
		}
		if(this.clear == true){
			this.clearAllEvent();
		}
	}

	doSearch() {
		this.searchEvent.emit(this.searchText);
	}

	displayFilter() {
		this.showFilter = !this.showFilter;
		this.showFilterEvent.emit(this.showFilter);
	}

	clearAllEvent() {
		this.searchText = '';
		this.clearAll.emit();
		this.doSearch();
	}

}

import { Component, Input, OnInit } from '@angular/core';
import { Labels } from 'src/internationalization/labels/labels';

@Component({
  selector: 'app-status-with-label',
  templateUrl: './status-with-label.component.html',
  styleUrls: ['./status-with-label.component.scss']
})
export class StatusWithLabelComponent implements OnInit {

	@Input() status:any;
	@Input() haveText: boolean;
	@Input() columns:boolean;
	@Input() statusTooltip: string;
	@Input() justifyContent: string;
	@Input() withExtraCall: boolean = false;
	@Input() marginRight: string = '';
	_labels = Labels.getLabels();

  constructor() { }

  ngOnInit() {
  }

	getText(status){
		switch (status) {
			case 'ONGOING':
				return this._labels.on_going;
			case 'ON_GOING':
				return this._labels.on_going;
			case 'Ongoing':
				return this._labels.on_going;
			case 'TO_BE_INITIATED':
				return this._labels.to_be_initiated;
			case 'TO BE INITIATED':
				return this._labels.to_be_initiated;
			case 'To be initiated':
				return this._labels.to_be_initiated;
			case 'FINISHED':
				return this._labels.title.finished;
			case 'Finished':
				return this._labels.title.finished;
			case 'ON_TIME':
				return this._labels.on_time;
			case 'On Time':
				return this._labels.on_time;
			case 'DELAYED':
				return this._labels.delayed;
			case 'Delayed':
				return this._labels.delayed;
			case 'EXTRA CALL':
				return this._labels.extra_call;
			case 'OMITTED':
				return this._labels.omitted;
			case 'PENDING':
				return '';
			case 'ACTIVE':
				return this._labels.active;
			case 'Active':
				return this._labels.active;
			case 'Expired':
				return '';
			case 'Inactive':
				return '';
			case 'OMIT':
				return this._labels.omitted;
			case 'Omitted':
				return this._labels.omitted;
			case 'Cancelled':
				return this._labels.cancelled;
			case 'Suspended':
				return this._labels.suspended;
			default:
				return '';
		}
	}
	getDefaultStatusText() {
		switch (this.status) {
			case 'PENDING':
				return this._labels.pending;
			case 'ACTIVE':
				return this._labels.active;
			case 'INACTIVE':
				return this._labels.inactive;
			case 'Active':
				return this._labels.active_2;
			case 'Expired':
				return this._labels.expired;
			case 'Inactive':
				return this._labels.inactive;
			case 'Cancelled':
				return this._labels.cancelled;
			case 'Suspended':
				return this._labels.suspended;
			default:
				return '';
		}
	}

}
